import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Pagination from "../../components/pagination";
import HttpService from "../../services/HttpService";
import { GRADIENTIMAGECOLOR, ActiveTabTypes } from "../../utils/constants";
import KeycloakService from "../../services/KeycloakService";
import { VERSION } from "../../constants";
import editIcon from '../../assets/images/edit.svg';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, TextField, Snackbar } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Paper from '@mui/material/Paper';
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { PatientDataManager, user_comments, patientsInfoPayload, admitArrivePayload } from "../../types/Module_Type";
import commentIcon from '../../assets/images/comment.svg';
import editCommentIcon from '../../assets/images/edit_comment.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import closeicon from '../../assets/images/Vector.svg';
import "./../pages.css";

 const useStyles = makeStyles(() => ({
  snackbarStyleViaContentProps: {
    backgroundImage: GRADIENTIMAGECOLOR,
  },
}));

const _axios = HttpService.getAxiosClient();

const PatientInfoAdmitted = ({ 
  selectedVisitType,
  filteredData,
  filterApplied
} : {
  selectedVisitType: string;
  filteredData: PatientDataManager;
  filterApplied: boolean
}) => {
  const classes = useStyles();
    const [patientArrayM, setPatientArrayM] = React.useState<PatientDataManager[]>([]);
    const [page, setPage] = React.useState(1);
    const [rowPageSize, setRowPageSize] = React.useState(30);
    const [totalPages, setTotalPages] = React.useState(Number);
    const [offsetVal, setOffsetVal] = React.useState(0);

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [selPatient, setSelPatient] = React.useState(0);
    const [selfkPatient, setfkSelPatient] = React.useState(0);

    const [patientUhid, setPatientUhid] = React.useState('');


    const [dischargeDate, setDischargeDate] = React.useState<Date | null>(null);
    const [patientStatus, setPatientStatus] = React.useState('');
    const [dischargedOptions, setDischargedOptions] = React.useState(false);

    const [dialogTitleStr, setDialogTitleStr] = React.useState('');

    const [openDialog, setOpenDialog] = React.useState(false);

    const [openCommentDialog, setOpenCommentDialog] = React.useState(false);
    const [commentValue, setCommentValue] = useState<string>('');
    const [commentTxtFldDisabled, setCommentTxtFldDisabled] = useState(true);
    const [toggleAddCommentBlock, setToggleAddCommentBlock] = useState(false);
    const [toggleCommentsTableBlock, setToggleCommentsTableBlock] = useState(true);

    const [commentsArray, setCommentsArray] = React.useState<user_comments[]>([]);

    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
    const [selectDate, setSelectDate] = React.useState('');
    const [totalDataCount, setTotalDataCount] = React.useState(0);
    const startingIndex = page === 1 ? 1 : (page - 1) * rowPageSize +1;

    const currentUser = useSelector((state: RootState) => {
        return state.user;
    });

    useEffect(() => {
        getInitialPaitentList();
    }, [page, rowPageSize, selectedVisitType, filteredData, filterApplied]);

    const getInitialPaitentList = async () => {
        try {
            setPatientArrayM([]);
            if(filterApplied){
                if(filteredData && filteredData?.patient_thop_map?.length > 0){
                    setPatientArrayM(filteredData.patient_thop_map);
                    setTotalPages(Math.ceil(filteredData?.patient_thop_map_aggregate?.aggregate.count / rowPageSize));
                    setOffsetVal((rowPageSize * page) - rowPageSize);
                    setTotalDataCount(filteredData?.patient_thop_map_aggregate?.aggregate?.count)
                } else {
                    // Render message indicating no data found for the applied filter
                    setPatientArrayM([]); 
                    setTotalPages(1);
                    setTotalDataCount(0);
                }
            } else {
                // const today = moment();
                let intentStr = currentUser.userRole === 'doctorReferralExecutive' ? 'getPatientDetailsFromThopDataForDre'
                  : currentUser.userRole === 'clusterManager' ? 'getPatientDetailsFromThopForClusterManager'
                  : currentUser.userRole === 'manager' ? 'getPatientThopDetailsForManager'
                  : currentUser.userRole === 'assistantGeneralManager' ? 'getPatientDetailsFromThopForAssistantGeneralManager'
                  : currentUser.userRole === 'salesHead' ? 'getPatientDetailsForSalesHeadBasedFromThopData'
                  : '';

                const admittedPatientsPayload:patientsInfoPayload = {
                    intent: intentStr,
                    state: {
                        patientStatus: selectedVisitType == "opd" ? ActiveTabTypes.Arrived : ActiveTabTypes.Admitted,
                        visitType: selectedVisitType,
                        isConvertedToIp: "no",
                        limit: rowPageSize,
                        offset: offsetVal,
                        /* need to fetch records based on date, for last 30 days */
                        // startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                        /* displaying start date as 1st january of current year i.e. example 2023-01-01 */
                        startDate: moment().startOf('year').format('YYYY-MM-DD'),
                        endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
                    },
                }

                if (currentUser.userRole === 'doctorReferralExecutive') {
                    admittedPatientsPayload.state.dreId = KeycloakService.getSub();
                } else if (currentUser.userRole === 'clusterManager') {
                    admittedPatientsPayload.state.clusterManagerId = KeycloakService.getSub();
                } else if (currentUser.userRole === 'manager') {
                    admittedPatientsPayload.state.managerId = KeycloakService.getSub();
                } else if (currentUser.userRole === 'assistantGeneralManager') {
                    admittedPatientsPayload.state.agmId = KeycloakService.getSub();
                } else if (currentUser.userRole === 'salesHead') {
                    admittedPatientsPayload.state.salesHeadId = KeycloakService.getSub();
                }

                const admittedPatientsResp = await _axios.post(
                    `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
                    admittedPatientsPayload,
                    {
                    headers: {
                        version: VERSION,
                    },
                    }
                );

              const admittedPatientsRespData = admittedPatientsResp.data;

              if (
                admittedPatientsRespData &&
                admittedPatientsRespData?.patient_thop_map.length != 0
              ) {
                let sortedAdmittedPatientsArray: any = '';
                if(selectedVisitType == "opd"){
                    sortedAdmittedPatientsArray = admittedPatientsRespData?.patient_thop_map.sort((a: { arrived_on: string }, b: { arrived_on: string }) => {
                        const dateA = new Date(a.arrived_on);
                        const dateB = new Date(b.arrived_on);
                      
                        return dateB.getTime() - dateA.getTime();
                    });
                } else if(selectedVisitType == "ipd"){
                    sortedAdmittedPatientsArray = admittedPatientsRespData?.patient_thop_map.sort((a: { admitted_on: string }, b: { admitted_on: string }) => {
                        const dateA = new Date(a.admitted_on);
                        const dateB = new Date(b.admitted_on);
                      
                        return dateB.getTime() - dateA.getTime();
                    });
                }
                setPatientArrayM(sortedAdmittedPatientsArray)
                setTotalPages(
                    Math.ceil(admittedPatientsRespData?.patient_thop_map_aggregate?.aggregate?.count / rowPageSize)
                );
                setTotalDataCount(admittedPatientsRespData?.patient_thop_map_aggregate?.aggregate?.count);
                setOffsetVal((rowPageSize * page) - rowPageSize);
              } else {
                setPatientArrayM([]);
                setTotalPages(1);
              }
            }
        }
        catch (e: any) {
            setMessage('getInitialPaitentList' + e.message);
            setOpen(true);
        }
    }

    const previousPage = () => {
        let previousPageNo = page - 1;
        setPage(previousPageNo);
        setOffsetVal(offsetVal - rowPageSize);
    }
    
    const nextPage = () => {
        let nextPageNo = page + 1;
        setPage(nextPageNo);
        setOffsetVal(offsetVal + rowPageSize);
    }
    
    const rowPerPageChange = (e: any) => {
        let val = e.target.value;
        setRowPageSize(val);
        setPage(1);
        // reset to 0 when rows per page changes if page no might be more than 1
        setOffsetVal(0);
    }

    const setDateFormat = (date:String) => {
        if(date != null && date != ""){
            let dateWithOutString = date.replace(/"/gi, "");
            dateWithOutString = moment(dateWithOutString).format('DD-MMM-YY , hh:mm')
            return dateWithOutString;
        } else {
            return '-';
        }
    }

    const setPatientStatusChange = (event: SelectChangeEvent<string>) => {
        setPatientStatus(event.target.value);

        setDischargeDate(null);
        if(selectedVisitType == "opd" ? event.target.value === ActiveTabTypes.Consulted : event.target.value === ActiveTabTypes.Discharged) {
          setDischargedOptions(true);
        } else {
            setDischargedOptions(false);
        }
    };

    const handleClickCommentDialogOpen = () => {
        setCommentTxtFldDisabled(true);

        setToggleAddCommentBlock(false);
        setToggleCommentsTableBlock(true);
        setOpenCommentDialog(true);
    };

    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCommentValue(event.target.value);
    };

    const handleCommentDialogClose = () => {
        setOpenCommentDialog(false);
    };

    const handleCommentDialogSubmit = async () => {
        try {
            if(toggleAddCommentBlock){
                if(commentValue == ""){
                    setMessage('Please enter something to save');
                    setOpen(true);
                    return false;
                }

                const saveCommentPayloadObj:any = {
                    intent: "insertPatientComments",
                    state: {
                        fkThopId : selPatient,
                        comments : commentValue
                    }
                }

                const saveCommentResp = await _axios.post(
                    `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
                    saveCommentPayloadObj,
                    {
                        headers: {
                            version: VERSION,
                        },
                    }
                )

                if(saveCommentResp?.status == 200 && saveCommentResp?.data?.insert_user_comments_one?.length != 0){
                    getInitialPaitentList();
                    setMessage('Comment for Patient "'+dialogTitleStr+'" has been saved successfully');
                    setOpen(true);
                    setOpenCommentDialog(false);
                } else {
                    setOpenCommentDialog(false);
                    setMessage('Unable to save comment for patient "'+dialogTitleStr+'" .');
                    setOpen(true);
                    return false;
                }
            }
        }
        catch (e: any) {
            setOpenCommentDialog(false);
            setMessage('Unable to save comment for patient '+dialogTitleStr+' status. Reason : ' + e.response.data.message);
            setOpen(true);
            return false;
        }
    }    

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        boxShadow: 'none'
    }));

    const admittedPatientsTableContainerRef = useRef<HTMLDivElement | null>(null)
    
    useEffect(() => {
        const resizeHandler = () => {
            const container = admittedPatientsTableContainerRef.current
            if (container) {
            const availableHeight: any =
                window.innerHeight - container.getBoundingClientRect().top
            container.style.maxHeight = `${availableHeight - 90}px`
            }
        }

        window.addEventListener('resize', resizeHandler)
        resizeHandler()

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    const handleClickDialogOpen = () => {
        setPatientStatus('');
        setDischargedOptions(false);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleDialogSubmit = async () => {
        try{
            if(patientStatus == ""){
                setMessage('Please select status for the patient');
                setOpen(true);
                return false;
            }

            let validationStr = selectedVisitType == "opd" ? ActiveTabTypes.Consulted : ActiveTabTypes.Discharged

            if(dischargeDate == null){
                setMessage('Please enter ' + validationStr + ' date for the patient');
                setOpen(true);
                return false;
            }
            
            let intentStrVal = "";

            if(selectedVisitType === "opd"){
                intentStrVal = "opDischargePatient";
            } else if(selectedVisitType === "ipd"){
                intentStrVal = "ipDischargeApi";
            }

            let iPDoPDpayloadObj:admitArrivePayload = {
                "intent" : intentStrVal,
                "state": {
                    "patientThopId": selPatient,
                    "fkPatientId": selfkPatient,
                    "patientStatus": patientStatus,
                    "visitType": selectedVisitType,
                    "userRole": currentUser.userRole,
                    "updatedBy": localStorage.getItem("userName"),
                }
            }

            if(selectedVisitType === "opd"){
                iPDoPDpayloadObj.state.consultationCompletedOn = dischargeDate;
            } else if(selectedVisitType === "ipd"){
                iPDoPDpayloadObj.state.dischargedOn = dischargeDate;
            }
            
            const refferedStatusChangeResp = await _axios.post(
                `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`, iPDoPDpayloadObj,
                {
                    headers: {
                        version: VERSION,
                    },
                }
            )

            if(refferedStatusChangeResp?.status == 200 && refferedStatusChangeResp?.data?.insert_referred_patient_audit_one?.length != 0){
                getInitialPaitentList();
                commentAfterRefStatusChng();
                setMessage('Patient status for '+dialogTitleStr+' has been updated successfully');
                setOpen(true);
                setOpenDialog(false);
            } else {
                setMessage('Unable to update the patient '+dialogTitleStr+' status.');
                setOpen(true);
                return false;
            }
        }
        catch (e: any) {
            setOpenDialog(false);
            setMessage('Unable to update the patient '+dialogTitleStr+' status. Reason : ' + e.response.data.message);
            setOpen(true);
            return false;
        }
    };

    const handleAddCommentsClick = () => {
        setCommentTxtFldDisabled(false);
        setToggleCommentsTableBlock(true);
        setToggleAddCommentBlock(true);
        setCommentValue("");
    };
    const [showPhoneNumber, setShowPhoneNumber] = useState(false);
    const [clickedPhoneNumber, setClickedPhoneNumber] = useState<string | null>(null);
    const maskPhoneNumber = (phoneNumber: string) => {
        const lastThreeDigits = phoneNumber.slice(-3);
        const maskedNumber = '*'.repeat(Math.max(0, phoneNumber.length - 3)) + lastThreeDigits;
        return maskedNumber;
    };
    const handleTogglePhoneNumber = (phoneNumber: string) => {
        setClickedPhoneNumber(showPhoneNumber ? null : phoneNumber);
    };

    const commentAfterRefStatusChng = async () => {
        try {
          let commentPayLoadObj = {
            intent: "insertPatientComments",
            "state":{
                fkThopId: selPatient,
                comments: dialogTitleStr+' is '+patientStatus+''
            }
        }
    
          await _axios.post(
            `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
            commentPayLoadObj,
            {
              headers: {
                version: VERSION,
              },
            }
          );

        } catch (e: any) {
            setMessage('insertPatientComments' + e.message);
            setOpen(true);
        }
      }

    return (
        <>
            <div className='tableWithHadle' ref={admittedPatientsTableContainerRef}>
                <Snackbar
                    ContentProps={{
                        "aria-describedby": "message-id",
                        className: classes.snackbarStyleViaContentProps
                    }}
                    open={open}
                    autoHideDuration={3000}
                    message={message}
                    onClose={() => setOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                />

                <Dialog  className="commentbox" open={openCommentDialog} onClose={handleCommentDialogClose} fullWidth={ true } maxWidth={"md"} >
                    <DialogTitle className="commentbox_header" >
                        {dialogTitleStr + "("+patientUhid+") - Comments"}
                        <Button 
                            aria-label="close"
                            className="closeButton"
                            onClick={handleCommentDialogClose}
                        >
                        <img className="close_icon_Img" src={closeicon} title="Add Comment"></img>
                        </Button>
                    </DialogTitle> 
                    <DialogContent className="commentbox_content" dividers={scroll === 'paper'}>
                        {toggleCommentsTableBlock ?
                            <Grid container className="mt2" spacing={3}>
                                {commentsArray.length > 0 ?
                                    <div className="comments_list_block">
                                        {commentsArray.map((c, i) => (
                                            <div className="comment_row" key={i} >
                                                <span className="comment_row_content">{c.comments ? c.comments : "-"}</span>
                                                <span className="comment_createdat">{c.created_at ? setDateFormat(c.created_at) : "-"}</span>
                                            </div>
                                        ))}
                                    </div>
                                :
                                    <div className="no_comments_block">
                                        <div className="no_comments_text">No Comments available</div>
                                    </div>
                                }
                            </Grid>
                        : null} 
                    </DialogContent>
                        {toggleAddCommentBlock ?
                            <Grid container spacing={1} className="Comment_Grid">
                                <Grid item xs={10}  className="Comment_text-center" >
                                    <TextField 
                                        multiline
                                        fullWidth
                                        rows={10}
                                        value={commentValue}
                                        placeholder="add comments"
                                        disabled={commentTxtFldDisabled}
                                        onChange={handleCommentChange}
                                        className="disabledTextField"
                                        // className={commentTxtFldDisabled ? "disabledTextField" : "disabledTextField"}
                                        />
                                </Grid>
                            </Grid>
                        : null}
                    <DialogActions className="commentbox_footer" >
                        {toggleAddCommentBlock ? (
                            <>
                                <Button className="commentbox_cancel_btn"  onClick={handleCommentDialogClose}>Cancel</Button>
                                <Button className="commentbox_button" onClick={handleCommentDialogSubmit}>Add comment</Button>
                            </>
                        ) : (
                            <Button className="commentbox_button" onClick={handleAddCommentsClick}>
                                Add Comments
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>

                <Dialog open={openDialog} onClose={handleDialogClose}>
                    <DialogTitle>{dialogTitleStr + " - Status"}</DialogTitle>
                    <DialogContent>      

                    <Grid container className="mt2" spacing={3}>
                        <Grid item xs={5} className="text-right">
                            <div className="onhold_dialog_item">Patient Status</div>
                        </Grid>
                        <Grid item xs={7} className="text-left">
                            <FormControl>
                                <Select
                                    className="onhold_dialog_item_text_left_patient_status"
                                    size="small"
                                    displayEmpty
                                    value={patientStatus}
                                    onChange={setPatientStatusChange}
                                >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                {selectedVisitType == "opd" ?
                                    <MenuItem value={ActiveTabTypes.Consulted}>Consulted</MenuItem>
                                :   <MenuItem value={ActiveTabTypes.Discharged}>Discharged</MenuItem>
                                }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {dischargedOptions ?

                        <Grid container className="mt2" spacing={3}>
                            <Grid item xs={5} className="text-right">
                                <Item>{selectedVisitType == "opd" ? "Consulted date" : "Discharge date"}</Item>
                            </Grid>
                            <Grid item xs={7} className="text-left">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    minDate={new Date(selectDate)}
                                    value={dischargeDate}
                                    disableFuture
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(newValue) => {
                                        setDischargeDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField className="datepicker_textfield" size="small" {...params} />} />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                    : '' }

                    </DialogContent>
                    <DialogActions>
                        <div className="cancel-submit-btn">
                            <Button onClick={handleDialogClose}>Cancel</Button>
                            <Button onClick={handleDialogSubmit}>Submit</Button>
                        </div>
                    </DialogActions>
                </Dialog>

                <table className='data-grid-table'>
                    <thead className="tableList_stickyHeader">
                        <tr> {['Sl No','Patient UHID','Referrer Name','Referrer Mob','Patient Name','Patient.Num','Status', 'Referred.Dt', selectedVisitType == "opd" ? 'Arrived on' : 'Admitted On', 'Comments'].map((d, i) => <th key={i}>{d}</th>)} </tr>
                    </thead>
                    <tbody>
                        {patientArrayM.length > 0 ?
                            patientArrayM.map((d, i) => (
                                <tr key={i}>
                                    <td>{startingIndex +i }</td>
                                    <td>{d.patient_uhid ? d.patient_uhid : "-"}</td>
                                    <td>
                                    {d?.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.first_name +
                                        " " +
                                        d?.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.last_name}
                                    </td>
                                    <td>{d.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.mobile_number}</td>
                                    <td>{d.referred_patient_detail?.patient_name}</td>
                                    <td>
                                        <span onClick={() => handleTogglePhoneNumber(d?.referred_patient_detail?.mobile_number)}>
                                        {clickedPhoneNumber === d?.referred_patient_detail?.mobile_number? d.referred_patient_detail?.mobile_number: maskPhoneNumber(d?.referred_patient_detail?.mobile_number)}
                                        </span>
                                    </td>
                                    <td>{d.patient_status}&nbsp;
                                        {currentUser.userRole === 'doctorReferralExecutive' ?
                                        <>
                                            <img className="" src={editIcon} style={{cursor: "pointer"}}
                                                onClick={() => {
                                                    setSelPatient(d?.id);
                                                    setSelectDate((selectedVisitType == "opd" ? d?.arrived_on : d?.admitted_on));
                                                    setfkSelPatient(d?.fk_patient_id);
                                                    setPatientUhid(d?.patient_uhid);
                                                    setDialogTitleStr(d?.referred_patient_detail?.patient_name);
                                                    handleClickDialogOpen();
                                            }} />
                                        </>
                                        : ''}    
                                    </td>
                                    <td>{setDateFormat(d?.referred_patient_detail?.created_at)}</td>
                                    <td>{setDateFormat(selectedVisitType == "opd" ? d?.arrived_on : d?.admitted_on)}</td>
                                    <td className='text-center-notimportant'>
                                        <>
                                            <img title="View Comments" className="" src={commentIcon} style={{cursor: "pointer"}}
                                                onClick={() => {
                                                    let sortedCommentsArray: any = '';
                                                    sortedCommentsArray = d?.patient_thop_map_user_comments?.sort((p: { created_at: string }, q: { created_at: string }) => {
                                                        const dateA = new Date(p.created_at);
                                                        const dateB = new Date(q.created_at);
                                                    
                                                        return dateB.getTime() - dateA.getTime();
                                                    });
                                                    setCommentsArray(sortedCommentsArray);
                                                    setSelPatient(d?.id);
                                                    setPatientUhid(d?.patient_uhid);
                                                    setDialogTitleStr(d?.referred_patient_detail?.patient_name);
                                                    handleClickCommentDialogOpen();
                                                }}
                                            />
                                        </>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td className='text-center' colSpan={8}>No Patient Details available</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <Pagination
                pageNo={page}
                totalPages={totalPages}
                previousPage={previousPage}
                nextPage={nextPage}
                rowPageSize={rowPageSize}
                totalDataCount={totalDataCount}
                rowPerPageChange={rowPerPageChange}
            />
        </>
    );
}
export default PatientInfoAdmitted;


