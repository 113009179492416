import React, { useRef, useEffect, useState } from "react";
import Sidemenu from '../components/Sidemenu';
import { Snackbar, Grid, TextField, Button } from "@mui/material";
import Pagination from "../components/pagination";
import HttpService from "../services/HttpService";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import {GRADIENTIMAGECOLOR,date,downlaod,rupee,selectDate, tableHead_Finance, tabsName_Finance } from "../utils/constants";
import './conflicts/conflicts.css'
import { VERSION } from "../constants";
import KeycloakService from "../services/KeycloakService";
import editIcon from '../assets/images/edit.svg';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Paper from '@mui/material/Paper';

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { docsData, paymentStatusSubmitPayload, paymentDetailsPayload, patientInvoice, PatientDataManager } from "../types/Module_Type";
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import FilterIcon from ".././assets/images/filter.svg";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/lab";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import closeIcon from './../assets/images/close.svg';
import invoiceIcon from '.././assets/images/invoice.svg';
import InvoiceListTable from "../components/FinanceInvoiceTable";
import FinanceInvoiceListTable from "../components/FinanceInvoiceTable";

const _axios = HttpService.getAxiosClient();

const useStyles = makeStyles(() => ({
  snackbarStyleViaContentProps: {
    backgroundImage: GRADIENTIMAGECOLOR,
  },
}));



const PaymentDetailsF: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [activeTab, setActiveTab] = React.useState(0);
  const [activeTabName, setActiveTabName] = React.useState('payments approved');
  const [docArray, setDocArray] = useState<docsData[]>([]);
  const [page, setPage] = React.useState(1);
  const [rowPageSize, setRowPageSize] = React.useState(30);
  const [totalPages, setTotalPages] = React.useState(Number);
  const [offsetVal, setOffsetVal] = React.useState(0);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [paymentStatus, setPaymentStatus] = React.useState('');
  const [selPatient, setSelPatient] = React.useState(Number);
  const [selPatientVisitId, setSelPatientVisitId] = React.useState('');
  const [reasonValue, setReasonValue] = useState<string>('');
  const [paymentRejectedOptions, setPaymentRejectedOptions] = useState(false);
  const [totalDataCount,setTotalDataCount]=React.useState(0);
  const [filterApplied,setFilterApplied]=useState(false);

  const startingIndex = page === 1 ? 1 : (page - 1) * rowPageSize +1;

  const PaymentsApprovedTableContainerRef = useRef<HTMLDivElement | null>(null);

  const currentUser = useSelector((state: RootState) => {
    return state.user;
  });

  useEffect(() => {
    getInitialPaymentDetails(activeTab);
  }, [offsetVal]);
  useEffect(() => {
    if(filterStateApplied){
    getFilterPaymentDetails(activeTab);
    }
  }, [rowPageSize]);
  useEffect(() => {
    const resizeHandler = () => {
      const container = PaymentsApprovedTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 90}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    boxShadow: 'none'
  }));

  const previousPage = () => {
    let previousPageNo = page - 1;
    setPage(previousPageNo);
    setOffsetVal(offsetVal - rowPageSize);
  };
  const nextPage = () => {
    let nextPageNo = page + 1;
    setPage(nextPageNo);
    setOffsetVal(offsetVal + rowPageSize);
  };
  const rowPerPageChange = (e: any) => {
    let val = e.target.value;
    setRowPageSize(val);
    setPage(1);
    // reset to 0 when rows per page changes if page no might be more than 1
    setOffsetVal(0);
  };

  const commonParams = {
    limit: rowPageSize,
    offset: offsetVal,
  };

  function generatePaymentDetailsPayload<T extends { [key: string]: string | number | Date }>(
    intent: string,
    params: T
  ): paymentDetailsPayload {
    return {
      intent,
      state: {
        ...commonParams,
        ...params,
      },
    };
  }

  const excelArray = docArray.map(
    ({
      manager_approval,
      total_amount,
      net_amount,
      updated_at,
      patient_thop_map: {
        visit_type,
        patient_uhid,
        admitted_on,
        arrived_on,
        discharged_on,
        consultation_completed_on,
        referred_patient_detail: {
          patient_name,
          dre_trade_partner_map: { medunited_user },
        },
      },
    }) => {
      const trade_partner_bank_account_details =
        medunited_user?.trade_partner_bank_account_details || [];
      const firstBankDetails = trade_partner_bank_account_details[0] || {};
      const {
        account_holder_name = "",
        bank_account_number = "",
        bank_ifsc_code = "",
        bank_name = "",
      } = firstBankDetails;
      const formattedTotalAmount = total_amount ?? 0;
      const formattedNetAmount = net_amount ?? 0;
      const formattedUpdatedDate = updated_at
        ? new Date(updated_at).toISOString().split("T")[0]
        : "";
      const formattedBankDetails =
        trade_partner_bank_account_details.length > 0
          ? {
              account_holder_name,
              bank_account_number,
              bank_ifsc_code,
              bank_name,
            }
          : {
              account_holder_name: "-",
              bank_account_number: "-",
              bank_ifsc_code: "-",
              bank_name: "-",
            };
  
      const dateForVisitType = visit_type === "ipd" ? (admitted_on || arrived_on) : arrived_on;
      const dateForDischarge = visit_type === "ipd" ? (discharged_on || consultation_completed_on) : consultation_completed_on;
  
      const concatenatedAdmittedDates = dateForVisitType ? new Date(dateForVisitType).toISOString().split("T")[0] : "";
      const concatenatedDischargedDates = dateForDischarge ? new Date(dateForDischarge).toISOString().split("T")[0] : "";
  
      return {
        patient_uhid,
        first_name: medunited_user?.first_name || "",
        mobile_number: medunited_user?.mobile_number || "",
        patient_name,
        visit_type,
        AdmittedDate_Column: concatenatedAdmittedDates,
        DischargedDate_Column: concatenatedDischargedDates,
        updated_at: formattedUpdatedDate,
        total_amount: formattedTotalAmount,
        net_amount: formattedNetAmount,
        manager_approval,
        ...formattedBankDetails,
      };
    }
  );
  const dateHeader =
  activeTab === 2
    ? "Processed_Date"
    : activeTab === 3
    ? "Rejected_Date"
    : activeTab === 4
    ? "OnHold_Date"
    : "Approved_Date";

const headers = [
  "Patient ID",
  "Referrer Name",
  "Referrer Mobile",
  "Patient Name",
  "Visit Type",
  "Admission Date",
  "Date of Discharge",
  dateHeader,
  "Total Amount",
  "Net Amount",
  "Status",
  "Account Holder Name",
  "Bank Account Number",
  "Bank IFSC Code",
  "Bank Name",
];

function handleDownload() {
  const csvContent = [headers.join(",")].concat(excelArray.map(item => Object.values(item).join(','))).join('\n');

  const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8," });

  const blobURL = window.URL.createObjectURL(blob);
  const anchor = document.createElement("a");
  anchor.download = `${tabsName_Finance[activeTab]}.csv`;
  anchor.href = blobURL;
  anchor.click();
  setTimeout(() => {
    URL.revokeObjectURL(blobURL);
  }, 100);
}
const CheckhandleDownload = () => {
  if (!selectedStartDate || !selectedEndDate) {
    setOpen(true);
    setMessage(selectDate);
  } else {
    setRowPageSize(totalDataCount);
    handleDownload(); 
  }
};

const [isFilterBarVisible, setFilterBarVisibility] = useState(false);
const [selectedStartDate, setselectedStartDate] = React.useState<Date | null |undefined>(null)
const [selectedEndDate, setselectedEndDate] = React.useState<Date| null | undefined >(null);
const [appliedFilters, setAppliedFilters] = useState<string[]>([]);
const [filterStateApplied, setFilterStateApplied] = useState<boolean>(false);
  
  const toggleFilterBar = () => {
    setFilterBarVisibility(!isFilterBarVisible);
    setselectedStartDate(null);
    setselectedEndDate(null);
  };
  const handleStartDateChange = (newValue: Date | null | undefined) => {
    const newDate = newValue !== null ? newValue : undefined;
    setselectedStartDate(newDate);
  };
  const handleEndDateChange = (newValue: Date | null | undefined) => {
    if (newValue !== undefined && newValue !== null) {
      let selectedDate: Date = new Date(newValue);
  
      const isToday = moment(selectedDate).isSame(moment(), 'day');
  
      if (isToday) {
        selectedDate.setHours(new Date().getHours(), new Date().getMinutes(), new Date().getSeconds());
      } else {
        selectedDate.setHours(23, 59, 0, 0);
      }
  
      setselectedEndDate(selectedDate);
    } else {
    }
  };
  const [filterParams, setFilterParams] = useState({
    index: 0,
    startDate:selectedStartDate,
    endDate:selectedEndDate
  });
  const [operationCompleted, setOperationCompleted] = useState(false);

  const handleSubmitFilter = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
  
    if (!selectedStartDate || !selectedEndDate) {
      setMessage("Please select both start and end dates");
      setOpen(true);
      return; 
    }
    try {
      await getFilterPaymentDetails(filterParams.index);
      setOperationCompleted(true);
    } catch (error) {
      setMessage(`Error fetching initial payment details: ${error}`);
    }
    setFilterBarVisibility(false);
  }
  const handleFilterCancel = () => {      
    setFilterBarVisibility(false);
  };  
  const DEFAULT_FILTER_DATE = {
    startDate: null,
    endDate: null,
  };
  const [clearfilterCall,setClearFilterCall]=useState(false);

  useEffect(() => {
    if (clearfilterCall) {
        getInitialPaymentDetails(filterParams.index);
    }
}, [filterParams, clearfilterCall]);
  const clearAllFilters = async () => {
    try {
      setselectedEndDate(DEFAULT_FILTER_DATE.endDate);
      setselectedStartDate(DEFAULT_FILTER_DATE.startDate);
      setFilterApplied(false); 
      setRowPageSize(30);
      setFilterParams({
        index: activeTab,
        startDate: DEFAULT_FILTER_DATE.startDate,
        endDate: DEFAULT_FILTER_DATE.endDate,
      });
      setOperationCompleted(false);
      setClearFilterCall(true);
    } catch (error) {
      console.error("Error clearing filters:", error);
    }
  };
  const handleTabClick = async (index: number) => {
    setselectedStartDate(null);
    setselectedEndDate(null);
    setFilterBarVisibility(false);
    setOperationCompleted(false);
    setFilterApplied(false); 
    if(tabsName_Finance[index].toLowerCase() !== activeTabName){
      setActiveTab(index);
      setDocArray([]);
      setPage(1);
      setOffsetVal(0);
      // setRowPageSize(10);
      setFilterParams({
        index,
        startDate: DEFAULT_FILTER_DATE.startDate,
        endDate: DEFAULT_FILTER_DATE.endDate,
      });
        getInitialPaymentDetails(index);
    }
  }; 

  const getInitialPaymentDetails = async (index: number) => {
    try {
      let paymentDetailsPayload: paymentDetailsPayload = {
        intent: "getPaymentDetailsForSalesHead",
        state: {
          ...commonParams,
          financeId: KeycloakService.getSub(),
        },
      };

      let params: { [key: string]: string | number | Date } = {};
      
      let intent = 'getPaymentDetailsForSalesHead'
      const today = new Date(); 
      const formattedToday = today.toISOString().split('T')[0];
      if(index == 0){
        /* Payments approved */
        params = {
          ...commonParams,
          managerApproval: "approved",
          salesHeadApproval: "approved",
          transactionStatus: "assigned",
          startDate:'2023-01-01',
          endDate:formattedToday,
        }
      } else if(index == 1){
        /* Processed Payments */
        params = {
          ...commonParams,
          managerApproval: "approved",
          salesHeadApproval: "approved",
          transactionStatus: "completed",
          startDate: '2023-01-01',
          endDate: formattedToday,
        }
      }

      paymentDetailsPayload = generatePaymentDetailsPayload(intent, params);

      const paymentDetailsResp = await _axios.post(
          `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
          paymentDetailsPayload,
          {
              headers: {
                  version: VERSION,
          },
          }
      );

        if (paymentDetailsResp.status === 200) {
            const paymentDetailsRespData = paymentDetailsResp.data;

            if (
                paymentDetailsRespData &&
                paymentDetailsRespData.trade_partner_payment_details.length != 0
            ) {
                setDocArray(paymentDetailsRespData.trade_partner_payment_details.sort((a: { discharged_on: number; }, b: { discharged_on: number; }) => a.discharged_on - b.discharged_on));
                setTotalPages(
                Math.ceil(paymentDetailsRespData.trade_partner_payment_details_aggregate.aggregate
                .count / rowPageSize)
                );
                setTotalDataCount(paymentDetailsRespData.trade_partner_payment_details_aggregate.aggregate.count);
                setOffsetVal((rowPageSize * page) - rowPageSize);
            } else {
                setDocArray([]);
                setTotalPages(1);
            }
        }
    } catch (error) {
      setMessage('getInitialPaymentDetails' + error);
    }
  }  

  //filter
  const commonFilterParams = {
    limit: totalDataCount,
    offset: offsetVal,
    startDate: selectedStartDate?.toISOString(), 
    endDate: selectedEndDate?.toISOString(),
  };
  const getFilterPaymentDetails = async (index: number) => {
    
    try {
      let paymentDetailsPayload: paymentDetailsPayload = {
        intent: "getPaymentDetailsForSalesHead",
        state: {
          ...commonFilterParams,
          financeId: KeycloakService.getSub(),
        },
      };

      let params: { [key: string]: string | number | Date } = {};
      
      let intent = 'getUpdatedPaymentDetailsForSalesHeadOrFinance'
      const today = new Date(); 
      const formattedToday = today.toISOString().split('T')[0];
      if(index == 0){
        /* Payments approved */
        params = {
          ...commonFilterParams,
          managerApproval: "approved",
          salesHeadApproval: "approved",
          transactionStatus: "assigned",
          startDate: selectedStartDate || '2023-01-01',
          endDate: selectedEndDate || formattedToday,
        }
      } else if(index == 1){
        /* Processed Payments */
        params = {
          ...commonFilterParams,
          managerApproval: "approved",
          salesHeadApproval: "approved",
          transactionStatus: "completed",
          startDate: selectedStartDate || '2023-01-01',
          endDate: selectedEndDate || formattedToday,
        }
      }

      paymentDetailsPayload = generatePaymentDetailsPayload(intent, params);

      const paymentDetailsResp = await _axios.post(
          `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
          paymentDetailsPayload,
          {
              headers: {
                  version: VERSION,
          },
          }
      );

        if (paymentDetailsResp.status === 200) {
            const paymentDetailsRespData = paymentDetailsResp.data;

            if (
                paymentDetailsRespData &&
                paymentDetailsRespData.trade_partner_payment_details.length != 0
            ) {
                setDocArray(paymentDetailsRespData.trade_partner_payment_details.sort((a: { discharged_on: number; }, b: { discharged_on: number; }) => a.discharged_on - b.discharged_on));
                setTotalPages(
                Math.ceil(paymentDetailsRespData.trade_partner_payment_details_aggregate.aggregate
                .count / rowPageSize)
                );
                setTotalDataCount(paymentDetailsRespData.trade_partner_payment_details_aggregate.aggregate.count);
                setOffsetVal((rowPageSize * page) - rowPageSize);
                setRowPageSize(totalDataCount);  
                setFilterStateApplied(true);
            } else {
                setDocArray([]);
                setTotalPages(1);
            }
        }
    } catch (error) {
      setMessage('getInitialPaymentDetails' + error);
    }
  }  
      

  const setDateFormat = (date: String) => {
      if(date != null && date != ""){
          let dateWithOutString = date.replace(/"/gi, "");
          dateWithOutString = moment(dateWithOutString).format('DD-MMM-YY , hh:mm')
          return dateWithOutString;
      } else {
          return '-';
      }    
  }

  const handleClickDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const setPatientStatusChange = (event: SelectChangeEvent<string>) => {
    setPaymentStatus(event.target.value);
    if(event.target.value == "rejected" || event.target.value == "onhold"){
      setReasonValue("");
      setPaymentRejectedOptions(true);
    } else {
      setPaymentRejectedOptions(false);
    }
  };

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReasonValue(event.target.value);
  };

  const setPaymentStatusValue = (activeTabIndex: number) => {
    let statusStr = '';
    if(activeTabIndex == 0 && activeTabName == 'payments approved'){
      statusStr = 'Approved';
    } else if(activeTabIndex == 1 && activeTabName == 'processed payments'){
      statusStr = 'Completed';
    }

    return statusStr;
  };

  const handlePaymentStatusDialogSubmit = async () => {
    if(paymentStatus == ""){
      setMessage('Please select any payment status');
      setOpen(true);
      return false;
    }

    let submitPaymentStatuspayloadObj:paymentStatusSubmitPayload = {
      intent: "updatePaymentDetailsByVisitId",
      state: {
          userType: currentUser.userRole,
          id: selPatient,
          financeApproval: paymentStatus,
          financeActionOn: moment().format('YYYY-MM-DD'),
          financeUserName: currentUser.userFullName
      }
    }
    try {
      const submitPaymentStatusResp = await _axios.post(
        `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
        submitPaymentStatuspayloadObj,
        {
          headers: {
            version: VERSION,
          },
        }
      );
  
      if (
        submitPaymentStatusResp?.status === 200 &&
        submitPaymentStatusResp?.data?.update_trade_partner_payment_details?.returning?.length !== 0
      ) {
        getInitialPaymentDetails(activeTab);
        setMessage('Payment status has been updated successfully');
        setOpen(true);
        setOpenDialog(false);
      } else {
        setMessage('Unable to update the Payment status.');
        setOpen(true);
        return false;
      }
    } catch (error) {
      console.error('Error submitting payment status:', error);
      setMessage('An error occurred while updating the Payment status.');
      setOpen(true);
      return false;
    }
  };
  

  const tabMenus = [];
  if (activeTab === 0) {
    tabMenus.push(
    [
      // { value: "inprocess", label: "InProcess" },
      { value: "completed", label: "Completed" },
    ]
    );
  } else if (activeTab === 1) {
    tabMenus.push([]);
  }
  const activeTabMenus = tabMenus[0];
  const formatNumber = (number :number) => {
    return number.toLocaleString();
  };

  const [openInvoiceDialog, setOpenInvoiceDialog] = React.useState(false);
  const [dialogTitleStr, setDialogTitleStr] = React.useState('');
  const [invoicesArray, setInvoicesArray] = React.useState<patientInvoice[]>([]);
  const [selectedInvoices, setSelectedInvoices] = React.useState<readonly string[]>([]);
  const [patientArrayM, setPatientArrayM] = React.useState<PatientDataManager[]>([]);
  const [patientUhid, setPatientUhid] = React.useState('');
  const [invoiceIds, setInvoiceIds] = useState<string[]>([]);

  const fetchInvoices = async (selPatientObj: docsData) => {
    setSelectedInvoices([]);
    try {
      setPatientUhid(selPatientObj?.patient_thop_map?.patient_uhid);
      const visitType = selPatientObj?.patient_thop_map?.visit_type;
      let invoiceStartDate = null;
      let invoiceEndDate = null;
      setInvoiceIds(selPatientObj.invoice_name);
      if (visitType === 'ipd') {
        invoiceStartDate = selPatientObj?.patient_thop_map?.admitted_on ? selPatientObj.patient_thop_map.admitted_on.split("T")[0] : null;
        invoiceEndDate = selPatientObj?.patient_thop_map?.updated_at ? selPatientObj.patient_thop_map.updated_at.split("T")[0] : null;
        if (invoiceStartDate && selPatientObj?.patient_thop_map?.updated_on) {
            const updatedOnDate = new Date(selPatientObj.patient_thop_map.updated_on.split("T")[0]);
            const invoiceStartDateObj = new Date(invoiceStartDate);
            if (invoiceStartDateObj < updatedOnDate) {
                invoiceStartDate = selPatientObj.patient_thop_map.updated_on.split("T")[0];
            }
        }
    } else {
        invoiceStartDate = selPatientObj?.patient_thop_map?.arrived_on ? selPatientObj.patient_thop_map.arrived_on.split("T")[0] : null;
        invoiceEndDate = selPatientObj?.patient_thop_map?.updated_at ? selPatientObj.patient_thop_map.updated_at.split("T")[0] : null;
        if (invoiceStartDate && selPatientObj?.patient_thop_map?.updated_on) {
            const updatedOnDate = new Date(selPatientObj.patient_thop_map.updated_on.split("T")[0]);
            const invoiceStartDateObj = new Date(invoiceStartDate);
            if (invoiceStartDateObj < updatedOnDate) {
                invoiceStartDate = selPatientObj.patient_thop_map.updated_on.split("T")[0];
            }
        }
    }
      let payloadObj = {
          "visitType": visitType,
          "patientUhid": selPatientObj?.patient_thop_map?.patient_uhid,
          "invoiceStartDate": invoiceStartDate,
          "invoiceEndDate": invoiceEndDate
      };
      const fetchInvoicesResp = await _axios.post(
          `${process.env.REACT_APP_INVOICE_SERVER_URL}/medunited-rms/invoices`, 
          payloadObj,
          {
              headers: {
                  version: VERSION,
              },
          }
      )

      if(fetchInvoicesResp.status == 200 && fetchInvoicesResp.data.length != 0){
        setInvoicesArray(fetchInvoicesResp.data);
        setOpenInvoiceDialog(true);
        const invoiceIdsArray = fetchInvoicesResp.data.map((invoice: any) => invoice.invoice_id);
        return invoiceIdsArray;
        setOpenInvoiceDialog(true);
      } else {
        setMessage('Unable to fetch the invoice for '+selPatientObj?.referred_patient_detail?.patient_name+'.');
        setOpen(true);
        return false;
      }

    } catch (e: any) {
        setMessage('Unable to fetch the invoice for '+selPatientObj?.referred_patient_detail?.patient_name+'. Reason : '+e.response?.data?.message);
        setOpen(true);
    }
  }
  const handleInvoiceDialogClose = () => {
    setOpenInvoiceDialog(false);
    // setSelectedInvoices([]);
};

const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  setSelectedInvoices([]);
};

const handleSelectedInvoicesChange = (selectedInvoices: readonly string[]) => {
  setSelectedInvoices(selectedInvoices);
};
  return (
    <>
      <Sidemenu />
      <div className="layoutParent">
        <p className="layoutParentP">Payment Details</p>
        <Snackbar
          ContentProps={{
            "aria-describedby": "message-id",
            className: classes.snackbarStyleViaContentProps
          }}
          open={open}
          autoHideDuration={3000}
          message={message}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
        
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Status</DialogTitle>
          <DialogContent>

            <Grid container className="mt2" spacing={3}>
                <Grid item xs={5} className="text-right">
                  <Item style={{ paddingTop: '1.1rem' }}>Payment Status</Item>
                </Grid>
                <Grid item xs={7} className="text-left">
                  <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={paymentStatus}
                      displayEmpty
                      onChange={setPatientStatusChange}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {activeTabMenus.map((menuitem)=>(
                        <MenuItem key={menuitem.value} value={menuitem.value}>{menuitem.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              
            {/* {activeTab === 0 && paymentStatus == "reject" ? ( */}
            {paymentRejectedOptions ? (
                <Grid container className="mt2" spacing={3}>
                  <Grid item xs={5} className="text-right">
                    <Item style={{ paddingTop: '1.1rem' }}>Reason</Item>
                  </Grid>
                  <Grid item xs={7} className="text-left">
                    <TextField
                        label="Reason"
                        multiline
                        rows={4}
                        value={reasonValue}
                        onChange={handleReasonChange}
                      />
                  </Grid>
                </Grid>
              ) : null}  
          </DialogContent>
          <DialogActions>
          <div className="cancel-submit-btn">
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handlePaymentStatusDialogSubmit}>Submit</Button>
          </div>
          </DialogActions>
        </Dialog>

        <div
          className="layoutContent pagelayoutPadding">
          <div className='conflicts_tabs'>
            <ul>
              {tabsName_Finance.map((d, i) => (
                <li
                  className={`${activeTab === i ? 'activeTab' : ''}`}
                  onClick={() => {
                    handleTabClick(i);
                    setActiveTabName(tabsName_Finance[i].toLowerCase());
                  }}
                >
                  {d}
                </li>
                ))
              }
            </ul>
            <div className="filter_download" >
                 <div className="filter_download_buttons">
                 <div className="filter_icon_block">
                  <div  onClick={toggleFilterBar}>
                      <img
                        className="filterIcon"
                        src={FilterIcon}
                        alt="Filter Icon"
                      />
                      <label className="filter_label">{date}</label>
                      </div>
                      </div>
                      <div className="filter_list">
                      {isFilterBarVisible && (
                            <>
                <div className="filter_status_block" >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                          label="Start Date"
                          value={selectedStartDate || null}
                          disableFuture
                          inputFormat="dd/MM/yyyy"
                          onChange={(newValue) => {
                              handleStartDateChange(newValue);
                          }}
                          renderInput={(params) => (
                              <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />
                          )}
                      />
                  </LocalizationProvider>
              </div>

                              <div className="filter_status_block" >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DatePicker
                                    label="To Date"
                                    value={selectedEndDate || null}
                                    disableFuture
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(newValue) => {
                                      handleEndDateChange(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />
                                    )}
                                  />
                                </LocalizationProvider>
                              </div>
                              <div  className="filter_submit_cancel_buttons">
                              <Button className="download_button" style={{ marginRight: '10px' }}  onClick={handleSubmitFilter}>Submit</Button>
                              <Button  className="download_button" onClick={handleFilterCancel}>Cancel</Button>
                              </div>
                            </>
                          )}
                    </div>
                    
                  </div>

                
                <div className="downloadButtondiv" style={{left:"9rem"}}>
                    <Button
                      className="download_button"
                      onClick={CheckhandleDownload}
                    >
                     {downlaod}
                    </Button>
                </div>
                </div>
                   
        </div>
        {operationCompleted && selectedStartDate && (
  <div className="applied_filter_item" style={{ backgroundColor: "white" }}>
    <div>
      <button className="applied_filter_item" onClick={clearAllFilters}>
        <img className="close_svg_icon" src={closeIcon} style={{ cursor: "pointer" }} />
        Start Date: {selectedStartDate?.toLocaleDateString() ?? 'N/A'},
        End Date: {selectedEndDate ? selectedEndDate.toLocaleDateString() : new Date().toLocaleDateString()}
      </button>
    </div>
  </div>
)}
        <div className="tableWithHadle" ref={PaymentsApprovedTableContainerRef}>
            <table className="data-grid-table">
              <thead style={{ position: 'sticky', top: '0px' }}>
                  <tr>
                      <th>Sl No</th>
                      {tableHead_Finance.map(d => <th>{d}</th>)}
                      {activeTab == 0 ? <><th>Approved by</th><th>Invoice View</th></> : null}
                  </tr>
                </thead>
                <tbody>
                  {docArray.length > 0 ? 
                    docArray.map((d, i) => (
                      <tr key={i}>
                        <td>{startingIndex +i }</td>
                        <td>{d?.patient_thop_map?.patient_uhid ? d?.patient_thop_map?.patient_uhid : "-"}</td>
                        <td>
                          {d?.patient_thop_map?.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.first_name +
                            " " +
                            d?.patient_thop_map?.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.last_name}
                        </td>
                        <td>
                          {d?.patient_thop_map?.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.mobile_number}
                        </td>
                        <td>{d?.patient_thop_map?.referred_patient_detail?.patient_name}</td>
                        <td>{d?.patient_thop_map?.visit_type}</td>
                        <td> {setDateFormat(d?.patient_thop_map?.visit_type === 'ipd'? d?.patient_thop_map?.admitted_on: d?.patient_thop_map?.arrived_on)}</td>
                        <td> {setDateFormat(d?.patient_thop_map?.visit_type === 'ipd'? d?.patient_thop_map?.discharged_on: d?.patient_thop_map?.consultation_completed_on)}</td>
                        <td> {setDateFormat(d?.updated_at)}</td>
                        <td>{rupee}&nbsp;{d?.total_amount ? formatNumber(d?.total_amount) : "0"}</td>
                        <td>{rupee}&nbsp;{d?.net_amount ? formatNumber(d?.net_amount) : "0"}</td>                      
                        <td>
                          {setPaymentStatusValue(activeTab)}&nbsp;
                          {activeTab === 0 ?
                          <>
                          <img className="" src={editIcon} style={{cursor: "pointer"}}
                              onClick={() => {
                              setSelPatient(+d.id)
                              setSelPatientVisitId(d.fk_visit_id)
                              handleClickDialogOpen();
                              
                              setPaymentStatus(""); // Set the payment status to an empty string
                              setReasonValue(""); // Optionally reset the reason value as well
                              setPaymentRejectedOptions(false);
                            }} />
                          </>
                          : null}
                          
                        </td>
                        {activeTab == 0 ?
                          <>
                            <td>{d?.manager_name ? d?.manager_name : "-"}</td>
                            {/* invoice */}
                            <td>
                              <img title="View Invoices" className="" src={invoiceIcon} style={{cursor: "pointer"}}
                              onClick={() => {
                              fetchInvoices(d);
                              }}
                              />
                              </td>
                          </>
                        : null
                        }
                    </tr>
                  )):
                <tr>
                  <td className="text-center" colSpan={9}>No Data</td>
                </tr>
            }
            </tbody>
            </table>
            <Dialog open={openInvoiceDialog} onClose={handleInvoiceDialogClose} fullWidth={ true } maxWidth={"md"}>
                  <DialogTitle>{dialogTitleStr + "("+patientUhid +") - Invoice"}</DialogTitle>
                  <DialogContent style={{height:'80vh'}}>
                    <InvoiceListTable
                      invoices={invoicesArray}
                      invoiceIds={invoiceIds} 
                      handleSelectAllClick={handleSelectAllClick}
                      setDateFormat={setDateFormat}
                      selectedInvoices={selectedInvoices}
                      handleInvoiceListSelectAllClick={handleSelectAllClick}
                      handleSelectedInvoicesChange={handleSelectedInvoicesChange} // Pass the handleSelectedInvoicesChange function as a prop
                    />
                  </DialogContent>
                  {currentUser.userRole === 'doctorReferralExecutive' &&(
                    <DialogActions>
                        {/* <Button onClick={handleInvoiceCalculate}>Calculate</Button> */}
                    </DialogActions>
                  )}
                </Dialog>

          </div>
          <Pagination
            pageNo={page}
            totalPages={totalPages}
            previousPage={previousPage}
            nextPage={nextPage}
            rowPageSize={rowPageSize}
            totalDataCount={totalDataCount}
            rowPerPageChange={rowPerPageChange} />
        </div>
      </div>
    </>
  );
}

export default PaymentDetailsF;
