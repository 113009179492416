import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  userRole: string;
  userFullName: string;
}

const initialState: UserState = {
  userRole: '',
  userFullName: '',
};

const useSlice = createSlice({
  name: 'userRole',
  initialState,
  reducers: {
    setUserRole: (state, action: PayloadAction<string>) => {
      state.userRole = action.payload; // Set the user role from the action payload
    },
    setUserFullName: (state, action: PayloadAction<string>) => {
      state.userFullName = action.payload; // Set the userFullName from the action payload
    },
  },
});

export const { setUserRole, setUserFullName } = useSlice.actions;
export default useSlice.reducer;
