import React from "react";
import arrowLeft from "../assets/images/chevron_left.png";
import arrowRight from "../assets/images/chevron_right.png";
import './pagination.css';

const Pagination = (props: any) => {
    const selectedRowPerPage = Math.min(props.rowPageSize, props.totalDataCount);
    const startingIndex = props.pageNo === 1 ? 1 : (props.pageNo - 1) * props.rowPageSize + 1;
    const endingIndex = Math.min(props.pageNo * props.rowPageSize, props.totalDataCount);
    return (
        <div className="pagination text-right">
            <span>
                Showing: {startingIndex} to {endingIndex} of {props.totalDataCount}
            </span>
            <span>
                <select
                    className="rowsperpage"
                    onChange={props.rowPerPageChange}
                    defaultValue={30}
                >
                    {[10, 20, 30, 40, 50,props.totalDataCount].map((pageSize) => (
                        <option
                            key={pageSize}
                            value={pageSize}
                        >
                            {pageSize}
                        </option>
                    ))}
                </select>
            </span>
            
            <button
                id="previous_page_btn"
                className={props.pageNo > 1 ? "ebable" : "disablePagBtn"}
                onClick={() => props.previousPage()}
            >
                <img src={arrowLeft} alt="arrow-left" />
            </button>
            <button
                id="next_page_btn"
                className={
                    props.pageNo < props.totalPages
                        ? "ebable"
                        : "disablePagBtn"
                }
                onClick={() => props.nextPage()}
            >
                <img src={arrowRight} alt="arrow-right" />
            </button>
        </div>
    );
}

export default Pagination;