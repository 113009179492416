import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Drawer from '@mui/material/Drawer';
import { routes } from "../routes";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import KeycloakService from '../services/KeycloakService';
import Tooltip from '@mui/material/Tooltip';
import './../pages/pages.css';
import Logo from './../assets/images/Logo_medunited.svg';
import paymentAdvice from './../assets/images/paymentAdvice.svg';
import UserBlack from './../assets/images/user_black.svg';
import HttpService from '../services/HttpService';
import './component.css';
import { MENULISTONROLEDATA } from '../utils/constants';
import { VERSION }  from '../constants'
import { useDispatch } from 'react-redux';
import { setUserRole, setUserFullName } from '../userSlice';
import patientInfo from './../assets/images/PatientsInfo.svg';
import adduser from './../assets/images/AddUsers.svg';

const drawerWidth = 108;

type menuType = {
  executive: string[];
  managingDirector: string[];


  finance: string[];
  manager: string[];
  doctorReferralExecutive: string[];
  clusterManager: string[];
  assistantGeneralManager: string[];
  salesHead: string[];
}

let menuList: menuType[] = MENULISTONROLEDATA;

type menuArray = {
  key: string,
  title: string,
  path: string,
  enabled: boolean,
  component: FC<{}>,
  hideIt: boolean,
}

const _axios = HttpService.getAxiosClient();

export default function PermanentDrawerLeft() {
  const location = useLocation();
  const current = location.pathname;
  const [menus, setMenus] = useState<menuArray[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getLogedInUser();
  }, [])

  const getLogedInUser = async () => {
    try {

      const payload = {
        "intent": "getUserByUserId",
        "state": {
            userId : KeycloakService.getSub(),
        }
      }
      
      const userResponse = await _axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`, payload, {
        headers: {
          version: VERSION,
          }
      },)

      if(userResponse?.data) {
        dispatch(setUserRole(userResponse.data.medunited_users[0].user_type));
        dispatch(setUserFullName(userResponse.data.medunited_users[0].first_name + " " + userResponse.data.medunited_users[0].last_name));
        updateMenus(userResponse.data.medunited_users[0].user_type);
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  const updateMenus = (userTypess: string) => {
    let allMenus = [...routes];
    let filteredMenus = allMenus.filter(d => menuList[0][userTypess as keyof menuType].includes(d.title));
    // filteredMenus[0].path="/";
    setMenus(filteredMenus)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            textAlign: 'center',
            overflowY: 'unset'
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Link component={NavLink} to="/" className='mainLink'>
          <img src={Logo} className="logo_img"/>
        </Link>
        {menus.map((page, index) => (
          <Tooltip title={page.title} key={index} placement="right">
            <Link key={page.key} component={NavLink} to={page.path} className={`menuListitem ${page.hideIt ? 'hideIt' : ''}`} >
              {page.title === 'Payment Advise' && <div> <img src={paymentAdvice} /></div>}
              {page.title === 'Profile Settings' && <div> <img src={paymentAdvice} /></div>}
              {page.title === 'Procedure Update' && <div> <img src={paymentAdvice} /></div>}
              {page.title === 'Patients Info' && <div> <img src={patientInfo} /></div>}
              {page.title === 'Referrer Patient Conflicts' && <div> <img src={paymentAdvice} /></div>}
              {page.title === 'Payment Approval' && <div> <img src={paymentAdvice} /></div>}
              {page.title === 'Payment Approval By Director' && <div> <img src={paymentAdvice} /></div>}
              {page.title === 'Payment Details' && <div> <img src={paymentAdvice} /></div>}
              {page.title === 'Payment Details for Finance' && <div> <img src={paymentAdvice} /></div>}
              {page.title === 'User Screen' && <div> <img src={adduser} /></div>}
            </Link>
          </Tooltip>
        ))}
        <div className='lgoutBtn'>
          <img src={UserBlack}  />
          <div className='sideBarPopUp'>
            <ul>
              <li><Link component={NavLink} to="/profile-settings" > Profile</Link></li>
              <li onClick={KeycloakService.doLogout}>Logout</li>
            </ul>
          </div>
        </div>
      </Drawer>
    </Box>
  );
}
