import Signup from "./components/Signup";
import PaymentAdvise from "./pages/PaymentAdvise";
import ProfileSettings from "./pages/ProfileSettings";

import {FC} from "react";
import DuplicateRecords from "./pages/duplicateRecords";
import ProcedureUpdate from "./pages/procedureUpdate";
import ReferrerPatientConflicts from "./pages/conflicts/conflicts";
import PaymentApproval from "./pages/paymentApproval";
import PaymentApprovalbyDirector from "./pages/paymentApprovalD";
import RedirectPage from "./pages/redirection";
import PaymentDispute from "./pages/paymentDispute";
import PatientInfoByManager from "./pages/patientInfo/procedureUpdateM";
import PaymentDetails from "./pages/paymentDetails";
import PaymentDetailsF from "./pages/paymentDetailsF";
import UserScreen from "./pages/userScreen/userScreen";
import UserProfileForm from "./pages/userScreen/addOthers";
import Upload from "./pages/userScreen/uploadUsers";

interface Route {
    key: string,
    title: string,
    path: string,
    enabled: boolean,
    component: FC<{}>,
    hideIt:boolean,
}

export const routes: Array<Route> = [
    {
        key: 'home-route',
        title: 'payment',
        path: '/',
        enabled: true,
        // component: localStorage.getItem('userRole') === 'executive' ? ProcedureUpdate : PaymentAdvise,
        component: RedirectPage,
        hideIt:true,
    },
    {
        key: 'payment-advise',
        title: 'Payment Advise',
        path: '/payment-advise/',
        enabled: true,
        component: PaymentAdvise,
        // hideIt:localStorage.getItem('userRole') === 'accountant' ? false : true
        hideIt:false
    },
    {
        key: 'profile-settings',
        title: 'Profile Settings',
        path: '/profile-settings',
        enabled: true,
        component: ProfileSettings,
        hideIt:true,
    },
    {
        key:'procedure-update',
        title:'Procedure Update',
        path:'/procedure-update/',
        enabled:false,
        component: ProcedureUpdate,
        // hideIt:localStorage.getItem('userRole') === 'executive' ? false : true,
        hideIt: false
    },
    {
        key:'patients-info',
        title:'Patients Info',
        path:'/patients-info/',
        enabled:false,
        component: PatientInfoByManager,
        // hideIt:localStorage.getItem('userRole') === 'executive' ? false : true,
        hideIt: false
    },
    {
        key:'duplicate-records',
        title:'Duplicate Records',
        path:'/duplicate-records/',
        enabled:false,
        component: DuplicateRecords,
        hideIt:true,
    },
    // {
    //     key:'referrer-patient-conflicts',
    //     title:'Referrer Patient Conflicts',
    //     path:'/referrer-patient-conflicts/',
    //     enabled:false,
    //     component: ReferrerPatientConflicts,
    //     // hideIt:localStorage.getItem('userRole') === 'executive' ? false : true,
    //     hideIt:false,
    // },
    {
        key:'payment-approval',
        title:'Payment Approval',
        path:'/payment-approval/',
        enabled:false,
        component: PaymentApproval,
        // hideIt:localStorage.getItem('userRole') === 'managingDirector' ? false : true,
        hideIt:false,
    },
    {
        key:'payment-approval-by-director',
        title:'Payment Approval By Director',
        path:'/payment-approval-by-director/',
        enabled:false,
        component: PaymentApprovalbyDirector,
        // hideIt:localStorage.getItem('userRole') === 'managingDirector' ? false : true,
        hideIt:false,
    },
    {
        key:'payment-details',
        title:'Payment Details',
        path:'/payment-details/',
        enabled:false,
        component: PaymentDetails,
        // hideIt:localStorage.getItem('userRole') === 'managingDirector' ? false : true,
        hideIt:false,
    },{
        key:'payment-details-finance',
        title:'Payment Details for Finance',
        path:'/payment-details-finance/',
        enabled:false,
        component: PaymentDetailsF,
        // hideIt:localStorage.getItem('userRole') === 'managingDirector' ? false : true,
        hideIt:false,
    },
    {
        key:'user-screen',
        title:'User Screen',
        path:'/User-Screen/',
        enabled:false,
        component: UserScreen,
        // hideIt:localStorage.getItem('userRole') === 'manager' ? false : false,
        hideIt:false,
    },
    
    
]