import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import Pagination from "../../components/pagination";
import HttpService from "../../services/HttpService";
import { GRADIENTIMAGECOLOR, ActiveTabTypes } from "../../utils/constants";
import KeycloakService from "../../services/KeycloakService";
import { VERSION } from "../../constants";
import { PatientDataManager, user_comments, patientsInfoPayload, patientInvoice } from "../../types/Module_Type";
import { Grid, Snackbar } from '@mui/material';
import commentIcon from '../../assets/images/comment.svg';
import invoiceIcon from '../../assets/images/invoice.svg';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import InvoiceListTable from '../../components/InvoiceListTable';

const useStyles = makeStyles(() => ({
    snackbarStyleViaContentProps: {
      backgroundImage: GRADIENTIMAGECOLOR,
    },
}));

const _axios = HttpService.getAxiosClient();

const ConsultationCompleted = ({
    selectedVisitType,
    filteredData,
    filterApplied
  } : {
    selectedVisitType: string;
    filteredData: PatientDataManager;
    filterApplied: boolean
  }) => {
    const classes = useStyles();
    const [patientArrayM, setPatientArrayM] = React.useState<PatientDataManager[]>([]);
    const [page, setPage] = React.useState(1);
    const [rowPageSize, setRowPageSize] = React.useState(30);
    const [totalPages, setTotalPages] = React.useState(Number);
    const [offsetVal, setOffsetVal] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');

    const [commentsArray, setCommentsArray] = React.useState<user_comments[]>([]);
    const [patientUhid, setPatientUhid] = React.useState('');
    const [fkVisitId, setfkVisitId] = React.useState('');
    const [isMasked, setIsMasked] = React.useState(true);

    const startingIndex = page === 1 ? 1 : (page - 1) * rowPageSize +1;

    const [dialogTitleStr, setDialogTitleStr] = React.useState('');
    const [openCommentDialog, setOpenCommentDialog] = React.useState(false);

    const [invoicesArray, setInvoicesArray] = React.useState<patientInvoice[]>([]);

    const [openInvoiceDialog, setOpenInvoiceDialog] = React.useState(false);
    const [selectedInvoices, setSelectedInvoices] = React.useState<readonly string[]>([]);

    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
    const [totalDataCount, setTotalDataCount]= React.useState(0);
    const currentUser = useSelector((state: RootState) => {
        return state.user;
    });

    useEffect(() => {
        getInitialPaitentList();
    }, [page, rowPageSize, selectedVisitType, filteredData, filterApplied]);

    const getInitialPaitentList = async () => {
        try {
            setPatientArrayM([]);
            if(filterApplied){
                if(filteredData && filteredData?.patient_thop_map?.length > 0){
                    setPatientArrayM(filteredData.patient_thop_map);
                    setTotalPages(Math.ceil(filteredData?.patient_thop_map_aggregate?.aggregate.count / rowPageSize));
                    setTotalDataCount(filteredData?.patient_thop_map_aggregate?.aggregate.count)
                    setOffsetVal((rowPageSize * page) - rowPageSize);
                } else {
                    // Render message indicating no data found for the applied filter
                    setPatientArrayM([]);
                    setTotalPages(1);
                    setTotalDataCount(0);
                }
            } else {
                let intentStr = currentUser.userRole === 'doctorReferralExecutive' ? 'getPatientDetailsFromThopDataForDre'
                  : currentUser.userRole === 'clusterManager' ? 'getPatientDetailsFromThopForClusterManager'
                  : currentUser.userRole === 'manager' ? 'getPatientThopDetailsForManager'
                  : currentUser.userRole === 'assistantGeneralManager' ? 'getPatientDetailsFromThopForAssistantGeneralManager'
                  : currentUser.userRole === 'salesHead' ? 'getPatientDetailsForSalesHeadBasedFromThopData'
                  : '';

                const consultCmpltdPatientsPayload:patientsInfoPayload = {
                    intent: intentStr,
                    state: {
                        patientStatus: ActiveTabTypes.Consultation_Completed,
                        visitType: "opd",
                        isConvertedToIp: "no",
                        limit: rowPageSize,
                        offset: offsetVal,
                        /* need to fetch records based on date, for last 30 days */
                        // startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                        startDate: '2023-01-01',
                        endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
                    },
                }
                
                if (currentUser.userRole === 'doctorReferralExecutive') {
                    consultCmpltdPatientsPayload.state.dreId = KeycloakService.getSub();
                } else if (currentUser.userRole === 'clusterManager') {
                    consultCmpltdPatientsPayload.state.clusterManagerId = KeycloakService.getSub();
                } else if (currentUser.userRole === 'manager') {
                    consultCmpltdPatientsPayload.state.managerId = KeycloakService.getSub();
                } else if (currentUser.userRole === 'assistantGeneralManager') {
                    consultCmpltdPatientsPayload.state.agmId = KeycloakService.getSub();
                } else if (currentUser.userRole === 'salesHead') {
                    consultCmpltdPatientsPayload.state.salesHeadId = KeycloakService.getSub();
                }

                const  consultCmpltdPatientsResp = await _axios.post(
                `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,consultCmpltdPatientsPayload,
                    {
                        headers: {
                          version: VERSION,
                        },
                    }
                );
                
                const consultCmpltdPatientsRespData = consultCmpltdPatientsResp.data;
                
                // if(Object.entries(dishchargedPatientsRespData).patient_thop_map)
                if (
                consultCmpltdPatientsRespData &&
                consultCmpltdPatientsRespData.patient_thop_map.length != 0
                ) {
                    let sortedDishchargedPatientsArray: any = '';
                    if(selectedVisitType == 'opd'){
                        sortedDishchargedPatientsArray= consultCmpltdPatientsRespData.patient_thop_map.sort((a: { consultation_completed_on: string }, b: { consultation_completed_on: string }) => {
                            const dateA = new Date(a.consultation_completed_on);
                            const dateB = new Date(b.consultation_completed_on);
                          
                            return dateB.getTime() - dateA.getTime();
                        });
                    } else if(selectedVisitType == 'ipd'){
                        sortedDishchargedPatientsArray= consultCmpltdPatientsRespData.patient_thop_map.sort((a: { discharged_on: string }, b: { discharged_on: string }) => {
                            const dateA = new Date(a.discharged_on);
                            const dateB = new Date(b.discharged_on);
                          
                            return dateB.getTime() - dateA.getTime();
                        });
                    }
                    setPatientArrayM(sortedDishchargedPatientsArray)
                    setTotalPages(
                        Math.ceil(consultCmpltdPatientsRespData.patient_thop_map_aggregate.aggregate
                        .count / rowPageSize)
                    );
                    setTotalDataCount(consultCmpltdPatientsRespData.patient_thop_map_aggregate.aggregate.count);
                    setOffsetVal((rowPageSize * page) - rowPageSize);
                } else {
                    setPatientArrayM([]);
                    setTotalPages(1);
                }
                
                if (consultCmpltdPatientsRespData.patient_thop_map_aggregate.aggregate
                    .count === 0) {
                    setPatientArrayM([]);
                    setTotalPages(1);
                }
            }
        }
        catch (e: any) {
            setMessage('getInitialPaitentList' + e.message);
            setOpen(true);
        }
    }

    const previousPage = () => {
        let previousPageNo = page - 1;
        setPage(previousPageNo);
        setOffsetVal(offsetVal - rowPageSize);
    }
    const nextPage = () => {
        let nextPageNo = page + 1;
        setPage(nextPageNo);
        setOffsetVal(offsetVal + rowPageSize);
    }
    const rowPerPageChange = (e: any) => {
        let val = e.target.value;
        setRowPageSize(val);
        setPage(1);
        // reset to 0 when rows per page changes if page no might be more than 1
        setOffsetVal(0);
    }

    const setDateFormat = (date:String) => {
        if(date != null && date != ""){
            let dateWithOutString = date.replace(/"/gi, "");
            dateWithOutString = moment(dateWithOutString).format('DD-MMM-YY , hh:mm')
            return dateWithOutString;
        } else {
            return '-';
        }
    }

    const consultCmpltdPatientsTableContainerRef = useRef<HTMLDivElement | null>(null)
        useEffect(() => {
        const resizeHandler = () => {
            const container = consultCmpltdPatientsTableContainerRef?.current
            if (container) {
            const availableHeight: any =
                window.innerHeight - container.getBoundingClientRect().top
            container.style.maxHeight = `${availableHeight - 90}px`
            }
        }

        window.addEventListener('resize', resizeHandler)
        resizeHandler()

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    const handleClickCommentDialogOpen = () => {
        setOpenCommentDialog(true);
    };

    const handleCommentDialogClose = () => {
        setOpenCommentDialog(false);
    };

    const fetchInvoices = async (selPatientObj: PatientDataManager) => {
      setSelectedInvoices([]);
      try {
        // setting the values to use it when user clicks on calculate for selected invoices
        setPatientUhid(selPatientObj?.patient_uhid);
        setDialogTitleStr(selPatientObj?.referred_patient_detail?.patient_name);
  
        setfkVisitId(selPatientObj?.visit_id);

        let payloadObj = {
            "visitType": selectedVisitType,
            "patientUhid": selPatientObj.patient_uhid,
            "invoiceStartDate" : selPatientObj.arrived_on.split("T")[0],
            "invoiceEndDate": selPatientObj.consultation_completed_on.split("T")[0]
        }


        const fetchInvoicesResp = await _axios.post(
            `${process.env.REACT_APP_INVOICE_SERVER_URL}/medunited-rms/invoices`, payloadObj,
            {
                headers: {
                    version: VERSION,
                },
            }
        )

        if(fetchInvoicesResp.status == 200 && fetchInvoicesResp.data.length != 0){
          setInvoicesArray(fetchInvoicesResp.data);
          if (! fetchInvoicesResp.data.some((invoice: { flag: boolean; }) => !invoice.flag)){
            setMessage('Bill is generated for all invoices for '+selPatientObj?.referred_patient_detail?.patient_name+'.');
            setOpen(true);
            return false;
          } else {
            setOpenInvoiceDialog(true);
          }
        } else {
          setMessage('Unable to fetch the invoice for '+selPatientObj?.referred_patient_detail?.patient_name+'.');
          setOpen(true);
          return false;
        }

      } catch (e: any) {
          setMessage('Unable to fetch the invoice for '+selPatientObj?.referred_patient_detail?.patient_name+'. Reason : '+e.response?.data?.message);
          setOpen(true);
      }
    }
      
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelected = invoicesArray.filter((n) => !n.flag).map((n) => n.invoice_name);
          setSelectedInvoices(newSelected);
          return;
      }
      setSelectedInvoices([]);
    };

    const handleInvoiceDialogClose = () => {
        setOpenInvoiceDialog(false);
        setSelectedInvoices([]);
    };

    const handleInvoiceCalculate = async () => {
      try {
        if(invoicesArray.every(invoice => invoice.products.every(product => product?.product_category?.toLowerCase() === 'pharmacy'))){
            setMessage('No Payments to calculate.');
            setOpen(true);
            return false;
        }
        
        if(selectedInvoices.length === 0){
          setMessage('Please select the invoice to be calculated');
          setOpen(true);
          return false;
        }

        const selectedInvoicesData = invoicesArray.filter(invoice => selectedInvoices.includes(invoice.invoice_name));
        const selectedInvoicesObject = selectedInvoicesData.map(invoice => ({
            invoice_name: invoice.invoice_name,
            products: invoice.products,
        }));

        let calInvoicesPayloadObj = {
            visitType: selectedVisitType,
            fkVisitId: fkVisitId,
            invoiceDtos: selectedInvoicesObject,
        }

        const calInvoicesResp = await _axios.post(
          `${process.env.REACT_APP_INVOICE_SERVER_URL}/medunited-rms/patient/discharge/payment`,
          calInvoicesPayloadObj,
          {
            headers: {
              version: VERSION,
            },
          }
        );

        if(calInvoicesResp.status == 200 && calInvoicesResp.data.length != 0){
          getInitialPaitentList();
          setMessage('Payment for '+dialogTitleStr+' has been calculated successfully');
          setOpen(true);
          setOpenInvoiceDialog(false);
        } else {
          setMessage('Unable to calculate the payment for '+dialogTitleStr+' status.');
          setOpen(true);
          return false;
        }
      } catch (e: any) {
        setMessage('Unable to calculate the payment for '+dialogTitleStr+' status. Reason : ' + e.message);
        setOpen(true);
      }
    }

    const handleSelectedInvoicesChange = (selectedInvoices: readonly string[]) => {
      setSelectedInvoices(selectedInvoices);
    };
    const [showPhoneNumber, setShowPhoneNumber] = React.useState(false);
    const [clickedPhoneNumber, setClickedPhoneNumber] = React.useState<string | null>(null);
      const maskPhoneNumber = (phoneNumber: string) => {
          const lastThreeDigits = phoneNumber.slice(-3);
          const maskedNumber = '*'.repeat(Math.max(0, phoneNumber.length - 3)) + lastThreeDigits;
          return maskedNumber;
        };
        const handleTogglePhoneNumber = (phoneNumber: string) => {
          setClickedPhoneNumber(showPhoneNumber ? null : phoneNumber);
        };

    return (
        <>
            <div className='tableWithHadle' ref={consultCmpltdPatientsTableContainerRef}>
                <Snackbar
                    ContentProps={{
                        "aria-describedby": "message-id",
                        className: classes.snackbarStyleViaContentProps
                    }}
                    open={open}
                    autoHideDuration={3000}
                    message={message}
                    onClose={() => setOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                />

                <Dialog open={openCommentDialog} onClose={handleCommentDialogClose} fullWidth={ true } maxWidth={"md"} className="commentbox">
                  <DialogTitle className="commentbox_header">{dialogTitleStr + "("+patientUhid+") - Comment"}</DialogTitle>
                    <DialogContent dividers={scroll === 'paper'} style={{height:'80vh'}}>
                        <Grid container className="mt2" spacing={3}>
                        <Grid container className="mt2" spacing={3}>
                                {commentsArray.length > 0 ?
                                    <div className="comments_list_block">
                                        {commentsArray.map((c, i) => (
                                            <div className="comment_row" key={i}>
                                                <span className="comment_row_content">{c.comments ? c.comments : "-"}</span>
                                                <span className="comment_createdat">{c.created_at ? setDateFormat(c.created_at) : "-"}</span>
                                            </div>
                                        ))}
                                    </div>
                                :
                                    <div className="no_comments_block">
                                        <div className="no_comments_text">No Comments available</div>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className="commentbox_footer">
                      <Button className="commentbox_button" onClick={handleCommentDialogClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openInvoiceDialog} onClose={handleInvoiceDialogClose} fullWidth={ true } maxWidth={"md"}>
                  <DialogTitle>{dialogTitleStr + "("+patientUhid+") - Invoice"}</DialogTitle>
                  <DialogContent dividers={scroll === 'paper'} style={{height:'80vh'}}>
                    <InvoiceListTable
                      invoices={invoicesArray}
                      handleSelectAllClick={handleSelectAllClick}
                      setDateFormat={setDateFormat}
                      selectedInvoices={selectedInvoices}
                      handleInvoiceListSelectAllClick={handleSelectAllClick}
                      handleSelectedInvoicesChange={handleSelectedInvoicesChange} // Pass the handleSelectedInvoicesChange function as a prop
                    />
                  </DialogContent>
                  {currentUser.userRole === 'doctorReferralExecutive' &&(
                    <DialogActions>
                        <Button onClick={handleInvoiceCalculate}>Calculate</Button>
                    </DialogActions>
                  )}
                </Dialog>

                <table className='data-grid-table'>
                    <thead className='tableList_stickyHeader'>
                        <tr> {['Sl No','Patient UHID','Referrer Name','Referrer Mob','Patient Name','Patient.Num','Status', 'Arrived.Dt', 'Reg.Dt', 'Consulted.Dt'].map((d, i) => <th key={i}>{d}</th>)} 
                          <th className='text-center-notimportant'>Comments</th>
                          <th className='text-center-notimportant'>Invoices</th>
                        </tr>
                    </thead>
                    <tbody>
                        {patientArrayM.length > 0 ?
                            patientArrayM.map((d, i) => (
                                <tr key={i}>
                                    <td>{startingIndex +i }</td>
                                    <td>{d.patient_uhid ? d.patient_uhid : "-"}</td>
                                    <td>
                                    {d?.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.first_name +
                                        " " +
                                        d?.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.last_name}
                                    </td>
                                    <td>{d.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.mobile_number}</td>
                                    <td>{d.referred_patient_detail?.patient_name}</td>
                                    <td>
                                        <span onClick={() => handleTogglePhoneNumber(d?.referred_patient_detail?.mobile_number)}>
                                        {clickedPhoneNumber === d?.referred_patient_detail?.mobile_number? d?.referred_patient_detail?.mobile_number: maskPhoneNumber(d?.referred_patient_detail?.mobile_number)}
                                        </span>
                                    </td>                                    
                                    <td>{d.patient_status}</td>
                                    <td>{setDateFormat(d.arrived_on)}</td>
                                    <td>{setDateFormat(d.referred_patient_detail?.created_at)}</td>
                                    <td>{setDateFormat(d.consultation_completed_on)}</td>
                                    <td className='text-center-notimportant'>
                                        <>
                                            <img title="View Comments" className="" src={commentIcon} style={{cursor: "pointer"}}
                                                onClick={() => {
                                                    let sortedCommentsArray: any = '';
                                                    sortedCommentsArray = d.patient_thop_map_user_comments.sort((p: { created_at: string }, q: { created_at: string }) => {
                                                        const dateA = new Date(p.created_at);
                                                        const dateB = new Date(q.created_at);
                                                    
                                                        return dateB.getTime() - dateA.getTime();
                                                    });
                                                    setCommentsArray(sortedCommentsArray);
                                                    handleClickCommentDialogOpen();
                                                    setPatientUhid(d?.patient_uhid);
                                                    setDialogTitleStr(d?.referred_patient_detail?.patient_name);
                                                }}
                                            />
                                        </>
                                    </td>
                                    <td className='text-center-notimportant'>
                                        <>
                                            <img title="View Invoices" className="" src={invoiceIcon} style={{cursor: "pointer"}}
                                                onClick={() => {
                                                    fetchInvoices(d);
                                                }}
                                            />
                                        </>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td className='text-center' colSpan={10}>No Patient Details available</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <Pagination
                pageNo={page}
                totalPages={totalPages}
                previousPage={previousPage}
                nextPage={nextPage}
                rowPageSize={rowPageSize}
                totalDataCount={totalDataCount}
                rowPerPageChange={rowPerPageChange}
            />
        </>
    );
}
export default ConsultationCompleted;


