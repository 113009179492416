import React, { useEffect, useRef, useState } from "react";
import { Snackbar } from "@mui/material";
import Sidemenu from "../../components/Sidemenu";
import { makeStyles } from "@mui/styles";
import "./../pages.css";
import HttpService from "../../services/HttpService";
import { GRADIENTIMAGECOLOR, ActiveTabTypes } from "../../utils/constants";
import { VERSION } from "../../constants";
import KeycloakService from "../../services/KeycloakService";
import PatientInfoReferred from "./patientReferred";
import PatientInfoAdmitted from "./patientAdmitted";
import PatientInfoDischarged from "./patientDischarged";
import PatientInfoOnHold from "./patientOnHold";
import PatientInfoReferralLost from "./patientReferralLost";
import FilterIcon from "../../assets/images/filter.svg";
import PositionedMenu from "../../components/PositionedMenu";
import RenderMenuItems from "./RenderMenuItems";
import closeIcon from '../../assets/images/close.svg';
import { referrerListPayload } from "../../types/Module_Type";
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ConsultationCompleted from "./patientConsultationCompleted";
const useStyles = makeStyles({
  snackbarStyleViaContentProps: {
    backgroundImage: GRADIENTIMAGECOLOR,
  },
});

type dataArray = {
  medunited_user: any;
  first_name: string;
  last_name: string;
  label: string;
  value: string;
  year: string;
};

const _axios = HttpService.getAxiosClient();

const PatientInfoByManager = () => {
  const classes = useStyles();

  const [filteredData, setFilteredData] = useState<any>();
  const [selectedVisitType, setSelectedVisitType] = React.useState('opd');
  const [activeTab, setActiveTab] = React.useState(0);
  const [activeTabType, setActiveTabType] = React.useState(ActiveTabTypes.Referred);

  const [toastOpen, setToastOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const [isShown, setIsShown] = useState<boolean>(false);

  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<string[]>([]);
  const documentClickHandler = useRef<(e: MouseEvent) => void>();

  const currentUser = useSelector((state: RootState) => {
    return state.user;
  });

  var activeTabTypeVal = ActiveTabTypes.Referred;

  const tabsName = selectedVisitType == "opd" ? [ 'Referred', 'Arrived', 'Consulted', 'Consultation Completed', 'Onhold', 'Referral lost'] : [ 'Referred', 'Admitted', 'Discharged', 'Onhold', 'Referral lost'];
  
  const handleVisitTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedVisitType(event.target.value);
    setTabNameBasedOnActiveTab(activeTab, event.target.value);
    clearAllFilters();
  };

  const removeDocumentClickHandler = () => {
    document.removeEventListener(
      "click",
      documentClickHandler.current as EventListener
    );
  };

  const handleToggleButtonClick = () => {
    if (isShown) return;
    setIsShown(true);
    document.addEventListener(
      "click",
      documentClickHandler.current as EventListener
    );
  };

  const handleCloseButtonClick = () => {
    setIsShown(false);
    removeDocumentClickHandler();
  };

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    /* remove applied filters when active tab changes */
    if(appliedFilters.length >= 1){
      setFilterApplied(false);
      setFilteredData([]);
      setAppliedFilters([]);
      setSelectedVisitType(selectedVisitType);
    }
  };

  const setTabNameBasedOnActiveTab = (i: number, selVisitType: string) => {     
    if (i === 1) {
      activeTabTypeVal = selVisitType === "opd" ? ActiveTabTypes.Arrived : ActiveTabTypes.Admitted;
    } else if (i === 2) {
      activeTabTypeVal = selVisitType === "opd" ? ActiveTabTypes.Consulted : ActiveTabTypes.Discharged;
    } else if(i === 3 && selVisitType === "opd"){
      // setting string for consulation completed screen
      activeTabTypeVal = ActiveTabTypes.Consultation_Completed;
    } else {
      activeTabTypeVal = tabsName[i].replace(/\s/g, '').toLowerCase();
    }
    setActiveTabType(activeTabTypeVal);
  }

  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const removeAppliedFilter = (indexToRemove: number) => {
    const updatedFilters = [...appliedFilters];
    updatedFilters.splice(indexToRemove, 1);
    setAppliedFilters(updatedFilters);
    if(updatedFilters.length == 0){
      setFilterApplied(false);
      setFilteredData([]);
    }
  };
  const clearAllFilters = ()=>{
    setAppliedFilters([]);
    setFilterApplied(false);  
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const [referrerList, setReferrerList] = React.useState<dataArray[]>([]);

  const fetchReferrers = async () => {
    try {
      let fetchReferrersIntentStr = currentUser.userRole === 'doctorReferralExecutive' ? 'getAllTradePartnersUnderParticularDre'
            : currentUser.userRole === 'clusterManager' ? 'getAllDresAndTradePartnersUnderClusterManager'
            : currentUser.userRole === 'manager' ? 'getAllDresAndClusterManagersUnderManager'
            : currentUser.userRole === 'assistantGeneralManager' ? 'getAllManagersAndClusterManagersUnderParticularAgm'
            : currentUser.userRole === 'salesHead' ? 'getAllManagersAndAgmsUnderSalesHead'
            : '';
      const fetchReferrersPayload:referrerListPayload = {
        intent: fetchReferrersIntentStr,
        state: { 
        },
      }

      if (currentUser.userRole === 'doctorReferralExecutive') {
        fetchReferrersPayload.state.dreId = KeycloakService.getSub();
      } else if (currentUser.userRole === 'clusterManager') {
        fetchReferrersPayload.state.clusterManagerId = KeycloakService.getSub();
      } else if (currentUser.userRole === 'manager') {
        fetchReferrersPayload.state.managerId = KeycloakService.getSub();
      } else if (currentUser.userRole === 'assistantGeneralManager') {
        fetchReferrersPayload.state.assistantGeneralManagerId = KeycloakService.getSub();
      } else if (currentUser.userRole === 'salesHead') {
        fetchReferrersPayload.state.salesHeadId = KeycloakService.getSub();
      }

      const renderReferrerValuesResp = await _axios.post(
        `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
        fetchReferrersPayload,
        {
          headers: {
            version: VERSION,
          },
        }
      );

      if (renderReferrerValuesResp.status === 200) {
        if (currentUser.userRole === 'doctorReferralExecutive') {
          setReferrerList(renderReferrerValuesResp.data.dre_trade_partner_map);
        } else if (currentUser.userRole === 'clusterManager') {
          setReferrerList(renderReferrerValuesResp.data.manager_cluster_manager_map[0].medunited_user.manager_cluster_manager_map.dre_cluster_manager_maps[0].dre_trade_partner_maps);
        } else if (currentUser.userRole === 'manager') {
          setReferrerList(renderReferrerValuesResp.data.manager_cluster_manager_map[0].medunited_user.manager_cluster_manager_map.dre_cluster_manager_maps[0].dre_trade_partner_maps);
        } else if (currentUser.userRole === 'assistantGeneralManager') {
          setReferrerList(renderReferrerValuesResp.data.agm_manager_map);
        } else if (currentUser.userRole === 'salesHead') {
          setReferrerList(renderReferrerValuesResp.data.sales_head_agm_map);
        }
      } else {
        // Handle error
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchReferrers();
  }, []);


  return (
    <>
      <Sidemenu />
      <div className="layoutParent">
        <p className="layoutParentP">Patients Info</p>
        <Snackbar
          ContentProps={{
            "aria-describedby": "message-id",
            className: classes.snackbarStyleViaContentProps,
          }}
          open={toastOpen}
          autoHideDuration={3000}
          message={message}
          onClose={() => setToastOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        />
        <div className="layoutContent pagelayoutPadding">
          <div className="conflicts_tabs">
            <ul>
              {tabsName.map((d, i) => (
                <li
                  className={`${activeTab === i ? "activeTab" : ""}`}
                  key={d}
                  onClick={() => {
                    handleTabClick(i);
                    setTabNameBasedOnActiveTab(i, selectedVisitType);
                  }}
                >
                  {d}
                </li>
              ))}
            </ul>

            <div className="referred_options_block">
              <div className="visit_type_block">
                <select
                  id="visit_type_change"
                  className="visit_type_change"
                  value={selectedVisitType}
                  onChange={handleVisitTypeChange}
                >
                  <option value="opd">OPD</option>
                  <option value="ipd">IPD</option>
                </select>
              </div>
              <PositionedMenu
                open={open}
                anchorRef={anchorRef}
                handleClose={handleClose}
                // handleListKeyDown={handleListKeyDown}
                handleToggle={handleToggle}
                button={
                  <div
                    className="filter_block"
                    onClick={handleToggleButtonClick}
                  >
                    <img className="filterIcon" src={FilterIcon} />
                    <label className="filter_label">Filter</label>
                  </div>
                }
                menuItems={
                <RenderMenuItems
                  selectedVisitType={selectedVisitType}
                  activeTabVal={activeTab}
                  activeTabType={activeTabType}
                  handleClose={handleClose}
                  setFilteredData={setFilteredData}
                  setFilterApplied={setFilterApplied}
                  setAppliedFilters={setAppliedFilters} // Pass setAppliedFilter as a prop
                  setMessage={setMessage}
                  referrerList={referrerList}
                />}
              />
            </div>
          </div>

          <div className="applied_filters_block">
              <div style={{marginLeft: "10px"}}>
              {appliedFilters.map((filter, index) => (
                <button className="applied_filter_item">
                   {appliedFilters.length == 1 && (
                  <img className="close_svg_icon" src={closeIcon} style={{ cursor: "pointer" }}
                  onClick={() => removeAppliedFilter(index)}/>
                )}
                  <span className="applied_filter_label">{filter}</span>
                </button>
                // {/* <button onClick={() => removeAppliedFilter(index)}>Remove</button> */}
              ))}
              {appliedFilters.length > 1 && (
                <button className="applied_filter_item clear-all-button" onClick={clearAllFilters}>
                  Clear All
                </button>
              )}
              </div>
          </div>

          {/* {activeTab === 0 && <PatientInfoAll />} */}
          {activeTab === 0 && <PatientInfoReferred selectedVisitType={selectedVisitType} filteredData={filteredData} filterApplied={filterApplied} />}
          {activeTab === 1 && <PatientInfoAdmitted selectedVisitType={selectedVisitType} filteredData={filteredData} filterApplied={filterApplied} />}
          {activeTab === 2 && <PatientInfoDischarged selectedVisitType={selectedVisitType} filteredData={filteredData} filterApplied={filterApplied} />}
          {/* consultation completed should be visible only for OPD */}
          {selectedVisitType === "opd" ? 
            activeTab === 3 && <ConsultationCompleted selectedVisitType={selectedVisitType} filteredData={filteredData} filterApplied={filterApplied} /> 
            : null}

          { selectedVisitType === "opd" ? 
              activeTab === 4 && <PatientInfoOnHold selectedVisitType={selectedVisitType} filteredData={filteredData} filterApplied={filterApplied} />
            : selectedVisitType === "ipd" ? 
              activeTab === 3 && <PatientInfoOnHold selectedVisitType={selectedVisitType} filteredData={filteredData} filterApplied={filterApplied} />
            : null  
          }

          { selectedVisitType === "opd" ? 
            activeTab === 5 && <PatientInfoReferralLost selectedVisitType={selectedVisitType} filteredData={filteredData} filterApplied={filterApplied} />
            : selectedVisitType === "ipd" ? 
            activeTab === 4 && <PatientInfoReferralLost selectedVisitType={selectedVisitType} filteredData={filteredData} filterApplied={filterApplied} />
            : null  
          }
        </div>
      </div>
    </>
  );
};

export default PatientInfoByManager;

