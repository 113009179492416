import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import Pagination from "../../components/pagination";
import HttpService from "../../services/HttpService";
import {
  GRADIENTIMAGECOLOR,
  DRE_PATIENT_DATA_HEADER_REFFERED,
  ActiveTabTypes
} from "../../utils/constants";
import { VERSION } from "../../constants";
import KeycloakService from "../../services/KeycloakService";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, TextField, Snackbar } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Paper from '@mui/material/Paper';
import editIcon from '../../assets/images/edit.svg';
import { PatientDataManager, patientsInfoPayload } from "../../types/Module_Type";
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import "./../pages.css";
import CircularProgress from '@material-ui/core/CircularProgress';

 const useStyles = makeStyles(() => ({
  snackbarStyleViaContentProps: {
    backgroundImage: GRADIENTIMAGECOLOR,
  },
}));

const _axios = HttpService.getAxiosClient();

const PatientInfoReferred = ({
  selectedVisitType,
  filteredData,
  filterApplied
} : {
  selectedVisitType: string;
  filteredData: PatientDataManager;
  filterApplied: boolean
}) => {
  const classes = useStyles();
  const [patientArrayM, setPatientArrayM] = React.useState<
    PatientDataManager[]
  >([]);
  const [page, setPage] = React.useState(1);
  const [rowPageSize, setRowPageSize] = React.useState(30);
  const [totalPages, setTotalPages] = React.useState(Number);
  const [open, setOpen] = React.useState(false);
  const [offsetVal, setOffsetVal] = React.useState(0);
  const currentUser = useSelector((state: RootState) => {
    return state.user;
  });

  const [openDialog, setOpenDialog] = React.useState(false);

  const [admittedDate, setAdmittedDate] = React.useState<Date | null>(null);
  const [arrivedDate, setArrivedDate] = React.useState<Date | null>(null);
  const [ackAdmisnDate, setAckAdmisnDate] = React.useState<Date | null>(null);

  const handleClickDialogOpen = () => {
    setPatientStatus("");
    setAdmittedOptions(false);
    setPatientUHIDValue('');
    setOpenDialog(true);
    setVisitType(selectedVisitType);
  };

  const handleDialogClose = () => {
    setPatientUHIDValue('');
    setOpenDialog(false);
  };

  const [visitType, setVisitType] = React.useState('');
  const [patientStatus, setPatientStatus] = React.useState('');
  const [admittedOptions, setAdmittedOptions] = React.useState(false);
  const [onHoldOptions, setOnHoldOptions] = React.useState(false);
  const [refLostOptions, setRefLostOptions] = React.useState(false);
  const [validationMessage, setValidationMessage] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [patientUHIDValue, setPatientUHIDValue] = useState<string>('');
  const [remarksValue, setRemarksValue] = useState<string>('');
  const [reasonValue, setReasonValue] = useState<string>('');
  const [selPatient, setSelPatient] = React.useState('');
  const [dialogTitleStr, setDialogTitleStr] = React.useState('');
  const [selectDate, setSelectDate] = React.useState('');
  const [totalDataCount,setTotalDataCount]=React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const startingIndex = page === 1 ? 1 : (page - 1) * rowPageSize +1;



  const handleVisitTypeChange = (event: SelectChangeEvent) => {
    setVisitType(event.target.value);
    // setAdmittedOptions(false);
    setOnHoldOptions(false);
    setRefLostOptions(false);
    setPatientStatus('');
  };

  const setPatientStatusChange = (event: SelectChangeEvent<string>) => {
    setPatientStatus(event.target.value);

    setAdmittedOptions(false);
    setOnHoldOptions(false);
    setRefLostOptions(false);

    setAdmittedDate(null);
    setArrivedDate(null);
    setAckAdmisnDate(null);
    // setPatientUHIDValue('');
    setRemarksValue('');
    setReasonValue('');
    if (event.target.value === ActiveTabTypes.Admitted || event.target.value === ActiveTabTypes.Arrived) {
      setAdmittedOptions(true);
    } else if(event.target.value === ActiveTabTypes.Onhold){
      setOnHoldOptions(true);
    } else if(event.target.value === ActiveTabTypes.Referral_Lost){
      setRefLostOptions(true);
    }
  };

  useEffect(() => {
    if(currentUser.userRole){
      getInitialPaitentList();
    }  
  }, [page, rowPageSize, selectedVisitType, filteredData, filterApplied, currentUser.userRole]);

  const handlePatientUHIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPatientUHIDValue(event.target.value);
  };

  const handleRemarksChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRemarksValue(event.target.value);
  };

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReasonValue(event.target.value);
  };

  const getInitialPaitentList = async () => {
    try {
      setPatientArrayM([]);
      if(filterApplied){
          if(filteredData && filteredData?.referred_patient_details?.length > 0){
              setPatientArrayM(filteredData.referred_patient_details);
              setTotalPages(Math.ceil(filteredData.referred_patient_details_aggregate.aggregate.count / rowPageSize));
              setTotalDataCount(filteredData?.referred_patient_details_aggregate?.aggregate?.count);
              setOffsetVal((rowPageSize * page) - rowPageSize);
          } else {
              // Render message indicating no data found for the applied filter
              setPatientArrayM([]); 
              setTotalPages(1);            
              setTotalDataCount(0);          
          }
      } else {
        let intentStr = currentUser.userRole === 'doctorReferralExecutive' ? 'getRefferedAndReferralLostPatientsForDreByDate'
          : currentUser.userRole === 'clusterManager' ? 'getAllReferredAndReferralLostPatientForClusterManager'
          : currentUser.userRole === 'manager' ? 'getAllReferredAndReferralLostPatientsForManager'
          : currentUser.userRole === 'assistantGeneralManager' ? 'getAllReferredAndReferralLostPatientsForAssistantGeneralManager'
          : currentUser.userRole === 'salesHead' ? 'getAllReferredAndReferralLostPatientForSalesHead'
          : '';

        const refferedPatientsPayload:patientsInfoPayload = {
        intent: intentStr,
          state: {
            patientStatus: ActiveTabTypes.Referred,
            limit: rowPageSize,
            offset: offsetVal,
            visitType: selectedVisitType,
            /* need to fetch records based on date, for last 30 days */
            // startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            /* displaying start date as 1st january of current year i.e. example 2023-01-01 */
            startDate: moment().startOf('year').format('YYYY-MM-DD')+"T00:00:00.0000",
            endDate: moment().format('YYYY-MM-DD')+"T23:59:59.9999",
          },
        }

        if (currentUser.userRole === 'doctorReferralExecutive') {
          refferedPatientsPayload.state.dreId = KeycloakService.getSub();
        } else if (currentUser.userRole === 'clusterManager') {
          refferedPatientsPayload.state.clusterManagerId = KeycloakService.getSub();
        } else if (currentUser.userRole === 'manager') {
          refferedPatientsPayload.state.managerId = KeycloakService.getSub();
        } else if (currentUser.userRole === 'assistantGeneralManager') {
          refferedPatientsPayload.state.agmId = KeycloakService.getSub();
        } else if (currentUser.userRole === 'salesHead') {
          refferedPatientsPayload.state.salesHeadId = KeycloakService.getSub();
        }

        const refferedPatientsResp = await _axios.post(
          `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
          refferedPatientsPayload,
          {
            headers: {
              version: VERSION,
            },
          }
        );

        const refferedPatientsRespData = refferedPatientsResp.data;

        if (
          refferedPatientsRespData &&
          refferedPatientsRespData.referred_patient_details.length != 0
        ) {
          const sortedRefferedPatientsArray = refferedPatientsRespData.referred_patient_details.sort((a: { created_at: string }, b: { created_at: string }) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
          
            return dateB.getTime() - dateA.getTime();
          });
          
          setPatientArrayM(sortedRefferedPatientsArray);
          setTotalPages(
            Math.ceil(refferedPatientsRespData.referred_patient_details_aggregate.aggregate
              .count / rowPageSize)
          );
          setTotalDataCount(refferedPatientsRespData?.referred_patient_details_aggregate?.aggregate?.count);
          setOffsetVal((rowPageSize * page) - rowPageSize);
        } else {
          setPatientArrayM([]);
          setTotalPages(1);
        }
      }
    } catch (e: any) {
      console.log(e);
      setMessage("getInitialPaitentList" + e.message);
      setOpen(true);
    }
  };

  const previousPage = () => {
    let previousPageNo = page - 1;
    setPage(previousPageNo);
    setOffsetVal(offsetVal - rowPageSize);
  };
  const nextPage = () => {
    let nextPageNo = page + 1;
    setPage(nextPageNo);
    setOffsetVal(offsetVal + rowPageSize);
    
  };
  const rowPerPageChange = (e: any) => {
    let val = e.target.value;
    setRowPageSize(val);
    setPage(1);
    // reset to 0 when rows per page changes if page no might be more than 1
    setOffsetVal(0);
  };

  const setDateFormat = (date: String) => {
    if(date != null && date != ""){
      let dateWithOutString = date.replace(/"/gi, "");
      dateWithOutString = moment(dateWithOutString).format("DD-MMM-YY , hh:mm");
      return dateWithOutString;
    } else {
      return '-';
    }
  };

  const referredPatientsTableContainerRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
      const resizeHandler = () => {
        const container = referredPatientsTableContainerRef.current
        if (container) {
          const availableHeight: any =
            window.innerHeight - container.getBoundingClientRect().top
          container.style.maxHeight = `${availableHeight - 90}px`
        }
      }

      window.addEventListener('resize', resizeHandler)
      resizeHandler()

      return () => {
        window.removeEventListener('resize', resizeHandler)
      }
  }, [])

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    boxShadow: 'none'
  }));



  const handleDialogSubmit = async () => {
    setIsLoading(true);
    try {
      if(patientStatus == ""){
        setMessage('Please select any status for the patient');
        setOpen(true);
        return false;
      }

      let intentStr = "";
      let patientStatusStr = "";
      let resourceStr = "";
      let selDateVal = "";

      let payloadObj = {};

      if(patientStatus === ActiveTabTypes.Admitted || patientStatus === ActiveTabTypes.Arrived) {
        const isValidInput = /^MU[A-Za-z]\d{8}$/;

        if (patientUHIDValue === "") {
          setMessage("Please enter Patient UHID");
          setOpen(true);
          return false;
        } else if (!isValidInput.test(patientUHIDValue)) {
          setMessage("Entered UHID Number is invalid");
          setOpen(true);
          return false;
        }

        if(visitType === ''){
          setMessage('Please select any Visit Type');
          setOpen(true);
          return false;
        }
        resourceStr = visitType;
        let dateKeyStr = "";

        if(visitType == "ipd"){
          intentStr = "ipAdmitApi";
          patientStatusStr = ActiveTabTypes.Admitted;
          dateKeyStr = "admittedOn";
          if(admittedDate == null){
            setMessage('Please select Admitted Date for the patient');
            setOpen(true);
            return false;
          } else {
            selDateVal = admittedDate ? admittedDate.toISOString() : '';
          }
        } else if(visitType == "opd"){
          intentStr = "createPatientOpArrivedRecord";
          patientStatusStr = ActiveTabTypes.Arrived;
          dateKeyStr = "arrivedOn";
          if(arrivedDate == null){
            setMessage('Please select Arrived Date for the patient');
            setOpen(true);
            return false;
          } else {
            selDateVal = arrivedDate ? arrivedDate.toISOString() : '';
          }
        }

        if(visitType == "ipd"){

          payloadObj = {
            intent: intentStr,
            "state":{
              "updatedBy": localStorage.getItem("userName"),
              "patientUhid": patientUHIDValue,
              "visitType": visitType,
              "patientStatus": patientStatusStr,
              "admittedOn" : selDateVal,
              "patientId": selPatient,
              "resource": resourceStr,
              "userRole": currentUser.userRole
            }
          };
        } else if(visitType == "opd"){
          payloadObj = {
            intent: intentStr,
            "state":{
              "updatedBy": localStorage.getItem("userName"),
              "patientUhid": patientUHIDValue,
              "visitType": visitType,
              "patientStatus": patientStatusStr,
              "arrivedOn" : selDateVal,
              "patientId": selPatient,
              "resource": resourceStr,
              "userRole": currentUser.userRole
            }
          };
        }

      } else if(patientStatus === ActiveTabTypes.Onhold){
        if(ackAdmisnDate == null){
          setMessage('Please select Admitted Date for the patient');
          setOpen(true);
          return false;
        } 
        if (remarksValue == ""){
          setMessage('Please enter the remarks');
          setOpen(true);
          return false;
        }
        intentStr = "updatePatientStatusToOnhold";
        selDateVal = ackAdmisnDate ? ackAdmisnDate.toISOString() : '';


        payloadObj = {
          intent: intentStr,
          "state":{
            "patientId": selPatient,
            "updatedBy": localStorage.getItem("userName"),
            "userRole": currentUser.userRole,
            "patientStatus": patientStatus,
            "patientAdmitAcknowledgedDate": selDateVal,
            "remarks": remarksValue,
          }
        };
      } else if(patientStatus === ActiveTabTypes.Referral_Lost){
        if (reasonValue == ""){
          setMessage('Please enter the reason');
          setOpen(true);
          return false;
        }
        intentStr = "updatePatientReferralLostStatus";
        

        payloadObj = {
          intent: intentStr,
          "state":{
            "patientId": selPatient,
            "updatedBy": localStorage.getItem("userName"),
            "userRole": currentUser.userRole,
            "patientStatus": patientStatus,
            "remarks": reasonValue,
          }
        };
      }

      const refferedStatusChangeResp:any = await _axios.post(
        `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
        payloadObj,
        {
          headers: {
            version: VERSION,
          },
        }
      );

      if(refferedStatusChangeResp.status == 200 && refferedStatusChangeResp.data.update_referred_patient_details_by_pk.length != 0){
        getInitialPaitentList();
        if(patientStatus != ActiveTabTypes.Onhold && patientStatus != ActiveTabTypes.Referral_Lost) {
          commentAfterRefStatusChng(refferedStatusChangeResp.data.insert_patient_thop_map_one.id);
        }
        setMessage('Patient status for '+dialogTitleStr+' has been updated successfully');
        setOpen(true);
        setOpenDialog(false);
      } else {
        setMessage('Unable to update the patient '+dialogTitleStr+' status.');
        setOpen(true);
        return false;
      }
    } catch (e: any) {
      console.log(e);
      setMessage(e.message);
      setOpen(true);
    }  
    finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
  }
  }

  const commentAfterRefStatusChng = async (idVal:number) => {
    try {
      let commentPayLoadObj = {
        intent: "insertPatientComments",
        "state":{
            fkThopId: idVal,
            comments: dialogTitleStr+' is '+patientStatus+''
        }
    }

      const commentAfterRefStatusChngResp:any = await _axios.post(
        `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
        commentPayLoadObj,
        {
          headers: {
            version: VERSION,
          },
        }
      );
      
      if(commentAfterRefStatusChngResp.status == 200){
      } else {
      }


    } catch (e: any) {
        console.log(e);
        setMessage('insertPatientComments' + e.message);
        setOpen(true);
    }
  }
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [clickedPhoneNumber, setClickedPhoneNumber] = useState<string | null>(null);
    const maskPhoneNumber = (phoneNumber: string) => {
        const lastThreeDigits = phoneNumber.slice(-3);
        const maskedNumber = '*'.repeat(Math.max(0, phoneNumber.length - 3)) + lastThreeDigits;
        return maskedNumber;
      };
      const handleTogglePhoneNumber = (phoneNumber: string) => {
        setClickedPhoneNumber(showPhoneNumber ? null : phoneNumber);
      };

  return (
    <>
      <div className="tableWithHadle" ref={referredPatientsTableContainerRef}>
        <Snackbar
            ContentProps={{
              "aria-describedby": "message-id",
              className: classes.snackbarStyleViaContentProps
            }}
            open={open}
            autoHideDuration={3000}
            message={message}
            onClose={() => setOpen(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />

          <Dialog open={openDialog} onClose={handleDialogClose}>
              <DialogTitle>{dialogTitleStr + " - Status"}</DialogTitle>
              <DialogContent>

              <Grid container className="mt2" spacing={3}>
                <Grid item xs={5} className="text-right"> 
                  <div className="onhold_dialog_item">Visit Type</div>
                </Grid>
                <Grid item xs={7} className="text-left">
                  <FormControl  >
                    <Select
                    className="onhold_dialog_item_text_left_visit_type"
                    size="small"
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={visitType}
                    displayEmpty
                    onChange={handleVisitTypeChange}
                    >
                    <MenuItem value="">
                    <em>Select</em>
                    </MenuItem>
                    <MenuItem value="ipd">IPD</MenuItem>
                    <MenuItem value="opd">OPD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container className="mt2" spacing={3}>
                  <Grid item xs={5} className="text-right">
                    <div className="onhold_dialog_item">Patient Status</div>
                  </Grid>
                  <Grid item xs={7} className="text-left">
                    <FormControl>
                      <Select
                        className="onhold_dialog_item_text_left_patient_status"
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={patientStatus}
                        displayEmpty
                        onChange={setPatientStatusChange}
                        size="small"
                      >
                        <MenuItem value="">
                          <em>Select</em>
                        </MenuItem>
                        {visitType === "ipd" && <MenuItem value={ActiveTabTypes.Admitted}>Admitted</MenuItem>}
                        {visitType === "opd" && <MenuItem value={ActiveTabTypes.Arrived}>Arrived</MenuItem>}
                        <MenuItem value={ActiveTabTypes.Onhold}>onHold</MenuItem>
                        <MenuItem value={ActiveTabTypes.Referral_Lost}>Referral Lost</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
              </Grid>
              {admittedOptions ? 
                <>
                <Grid container spacing={3} className="mb10">
                    <Grid item xs={5} className="text-right">
                      <div className="onhold_dialog_item">Patient UHID</div>
                    </Grid>
                    <Grid item xs={7} className="text-left" style={{ paddingLeft: "33px" }}>
                      <TextField
                        autoFocus
                        required
                        margin="dense"
                        label="Patient UHID"
                        type="text"
                        variant="standard"
                        value={patientUHIDValue}
                        onChange={handlePatientUHIDChange}
                      />
                    </Grid>
                </Grid>
                  {visitType == "ipd" ?
                    <Grid container className="mt2" spacing={3}>
                      <Grid item xs={5} className="text-right">
                        <Item>Admitted Date</Item>
                      </Grid>
                      <Grid item xs={7} className="text-left">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Choose a Date"
                            value={admittedDate}
                            disableFuture
                            minDate={new Date(selectDate)}
                            inputFormat="dd/MM/yyyy"
                            onChange={(newValue) => {
                              setAdmittedDate(newValue);
                            } }
                            renderInput={(params) => <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />} />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    : 
                    <Grid container className="mt2" spacing={3}>
                      <Grid item xs={5} className="text-right">
                        <Item>Arrived Date</Item>
                      </Grid>
                      <Grid item xs={7} className="text-left">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Choose a Date"
                            value={arrivedDate}
                            disableFuture
                            inputFormat="dd/MM/yyyy"
                            minDate={new Date(selectDate)}
                            onChange={(newValue) => {
                              setArrivedDate(newValue);
                            } }
                            renderInput={(params) => <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />} />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  }
                    </>
              : '' }

              {onHoldOptions ?
                <>
                  <Grid container className="mt2" spacing={3}>
                    <Grid item xs={5} className="text-right">
                      <Item>Acknowledged Admission date</Item>
                    </Grid>
                    <Grid item xs={7} className="text-left" >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={ackAdmisnDate}
                          disableFuture
                          inputFormat="dd/MM/yyyy"
                          minDate={new Date(selectDate)}
                          onChange={(newValue) => {
                            setAckAdmisnDate(newValue);
                          } }
                          renderInput={(params) => <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />} />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  
                  <Grid container className="mt2" spacing={5}>
                      <Grid item xs={5} className="text-right">
                        <Item>Remarks</Item>
                      </Grid>
                      <Grid item xs={7} className="text-left">
                        <TextField
                          label="Remarks"
                          multiline
                          rows={4}
                          value={remarksValue}
                          onChange={handleRemarksChange}
                        />
                      </Grid>
                  </Grid>
                </>
                
              : '' }
              
              {refLostOptions ?
                <Grid container className="mt2" spacing={5}>
                  <Grid item xs={5} className="text-right">
                    <div className="onhold_dialog_item">Reason</div>
                  </Grid>
                  <Grid item xs={7} className="text-left">
                    <TextField
                        label="Reason"
                        multiline
                        rows={4}
                        value={reasonValue}
                        onChange={handleReasonChange}
                      />
                </Grid>
              </Grid>
              : '' }
              </DialogContent>
              <DialogActions>
                <div className="cancel-submit-btn">
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button onClick={handleDialogSubmit} disabled={isLoading}>
              {isLoading ? <CircularProgress color="inherit" size={24} /> : 'Submit'}</Button>                </div>
              </DialogActions>
        </Dialog>

        <table className="data-grid-table">
          <thead className='tableList_stickyHeader'>
            <tr>
              {DRE_PATIENT_DATA_HEADER_REFFERED.map((d, i) => (
                <th key={i}>
                  <span></span>
                  {d}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {patientArrayM.length > 0 ? (
              patientArrayM.map((d, i) => {
                return (
                  <tr key={i}>
                    <td>{startingIndex +i }</td>
                    {/* <td>{d.dr_etrade_partner_map ?? (d.dre_trade_partner_map.medunited_user.first_name + "" + d.dre_trade_partner_map.medunited_user.last_name)}</td> */}
                    <td>
                      {d?.dre_trade_partner_map?.medunited_user?.first_name +
                        " " +
                        d?.dre_trade_partner_map?.medunited_user?.last_name}
                    </td>
                    <td>{d?.dre_trade_partner_map?.medunited_user?.mobile_number}</td>
                    <td>{d.patient_name}</td>
                    <td>
                    <span onClick={() => handleTogglePhoneNumber(d?.mobile_number)}>
                      {clickedPhoneNumber === d?.mobile_number? d.mobile_number: maskPhoneNumber(d?.mobile_number)}
                    </span>
                    </td>
                    <td>
                      {d.patient_status}&nbsp;
                      {currentUser.userRole === 'doctorReferralExecutive' ?
                      <>
                        <img className="" src={editIcon} style={{cursor: "pointer"}}
                            onClick={() => {
                            setSelPatient(d.id.toString());
                            setSelectDate(d?.created_at);
                            setAdmittedOptions(false);
                            setOnHoldOptions(false);
                            setRefLostOptions(false);
                            handleClickDialogOpen();
                            setDialogTitleStr(d.patient_name);
                          }}
                        />
                      </>
                    : ''}
                    </td>
                    <td>{setDateFormat(d.created_at)}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center" colSpan={7}>
                  No Patient Details available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        pageNo={page}
        totalPages={totalPages}
        previousPage={previousPage}
        nextPage={nextPage}
        rowPageSize={rowPageSize}
        totalDataCount={totalDataCount}
        rowPerPageChange={rowPerPageChange} />
    </>
  );
};
export default PatientInfoReferred;