import React, { useEffect, useState } from "react";
import "./UserProfileForm.css";
import HttpService from "../../services/HttpService";
import { Autocomplete, Button, Snackbar, TextField } from "@mui/material";
import {
  GRADIENTIMAGECOLOR,
  noData,
  errorMessage,
  finance,
  salesHead,
  select,
  success,
  userExists,
  submit,
  addRole,
  allFields,
  userTypeList,
} from "../../utils/constants";
import { makeStyles } from "@mui/styles";
import {
  MedunitedUsersResponse_others,
  UserData,
  UserData_Others,
} from "../../types/Module_Type";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const useStyles = makeStyles(() => ({
  snackbarStyleViaContentProps: {
    backgroundImage: GRADIENTIMAGECOLOR,
  },
}));
const UserProfileForm: React.FC = () => {
  const initialUserData: UserData_Others = {
    firstName: "",
    lastName: "",
    email: "",
    userName: "91",
    reportingManagerUserName: "",
    userType: "",
    mobileNumber: "",
    operatingUnitLocation: "",
    operatingUnitType: "",
    doctorType: "",
    state: "",
    district: "",
    mandal: "",
    village: "",
    pincode: "",
    hospitalName: "",
    bankName: "",
    bankAccountNUmber: "",
    accountHolderName: "",
    bankIfscCode: "",
  };

  const [userData, setUserData] = useState<UserData_Others>(initialUserData);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<UserData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState("");
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [userdataArray, setUserDataArray] =
    useState<MedunitedUsersResponse_others | null>(null);
  const [userrequiredData, setUserRequiredData] = useState({});
  const [salesHeadUserNames, setSalesHeadUserNames] = useState<string[]>([]);
  const [showTPFields, setshowTPFields] = useState<boolean>(false);
  const _axios = HttpService.getAxiosClient();
  const [aciveTab, setActiveTab] = React.useState(0);

  const fetchData_getAllMedunitedUsersData = async () => {
    const intentStr = "getAllMedunitedUsersData";
    const payloadObj = {
      intent: intentStr,
    };

    try {
      setLoading(true);

      const response = await _axios.post<MedunitedUsersResponse_others>(
        `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
        payloadObj,
        {
          headers: {
            "Content-Type": "application/json",
            version: "WORKING",
          },
        }
      );
      setUserDataArray(response.data);
    } catch (error: any) {
      setError("Error fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData_getAllMedunitedUsersData();
  }, []);
  const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const emptyUserData: UserData_Others = {
      firstName: "",
      lastName: "",
      email: "",
      userName: "91",
      reportingManagerUserName: "",
      userType: "",
      mobileNumber: "",
      operatingUnitLocation: "",
      operatingUnitType: "",
      doctorType: "",
      state: "",
      district: "",
      mandal: "",
      village: "",
      pincode: "",
      hospitalName: "",
      bankName: "",
      bankAccountNUmber: "",
      accountHolderName: "",
      bankIfscCode: "",
    };

    setUserData(emptyUserData);
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setshowTPFields(value === "tradePartner");
    if (value === "assistantGeneralManager") {
      const salesHeads = userdataArray?.medunited_users.filter(
        (user) => user.user_type === "salesHead"
      );
      if (salesHeads) {
        const salesHeadUserNames = salesHeads.map(
          (salesHead) => `${salesHead.user_name} - ${salesHead.first_name}`
        );
        setSalesHeadUserNames(salesHeadUserNames);
      }
    } else if (value === "manager") {
      const assistantGeneralManagers = userdataArray?.medunited_users.filter(
        (user) => user.user_type === "assistantGeneralManager"
      );
      if (assistantGeneralManagers) {
        const assistantGeneralManagerUserNames = assistantGeneralManagers.map(
          (manager) => `${manager.user_name} - ${manager.first_name}`
        );
        setSalesHeadUserNames(assistantGeneralManagerUserNames);
      }
    } else if (value === "clusterManager") {
      const assistantGeneralManagers = userdataArray?.medunited_users.filter(
        (user) => user.user_type === "manager"
      );
      if (assistantGeneralManagers) {
        const assistantGeneralManagerUserNames = assistantGeneralManagers.map(
          (manager) => `${manager.user_name} - ${manager.first_name}`
        );
        setSalesHeadUserNames(assistantGeneralManagerUserNames);
      }
    } else if (value === "doctorReferralExecutive") {
      const assistantGeneralManagers = userdataArray?.medunited_users.filter(
        (user) => user.user_type === "clusterManager"
      );
      if (assistantGeneralManagers) {
        const assistantGeneralManagerUserNames = assistantGeneralManagers.map(
          (manager) => `${manager.user_name} - ${manager.first_name}`
        );
        setSalesHeadUserNames(assistantGeneralManagerUserNames);
      }
    } else if (value === "tradePartner") {
      const assistantGeneralManagers = userdataArray?.medunited_users.filter(
        (user) => user.user_type === "doctorReferralExecutive"
      );
      if (assistantGeneralManagers) {
        const assistantGeneralManagerUserNames = assistantGeneralManagers.map(
          (manager) => `${manager.user_name} - ${manager.first_name}`
        );
        setSalesHeadUserNames(assistantGeneralManagerUserNames);
      }
    } else if (value == salesHead || value == finance) {
      setUserData((prevData) => ({
        ...prevData,
        reportingManagerUserName: "empty",
      }));
    } else {
      setSalesHeadUserNames([]);
    }
  };
  const navigate = useNavigate();
  const fetchData = async () => {
    let payloadObj: any = {
      intent: "createUserInKeycloak",
      state: {
        ...userData,
        reportingManagerUserName:
          userData.reportingManagerUserName.split(" - ")[0],
      },
    };
    if (userData.userType === "tradePartner") {
      payloadObj = {
        ...payloadObj,
        state: {
          ...payloadObj.state,
          state: userData.state,
          district: userData.district,
          mandal: userData.mandal,
          village: userData.village,
          pincode: userData.pincode,
          hospitalName: userData.hospitalName,
        },
      };
    }
    try {
      setLoading(true);

      const response = await _axios.post<any>(
        `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
        payloadObj,
        {
          headers: {
            "Content-Type": "application/json",
            version: "WORKING",
          },
        }
      );

      if (response.status === 200) {
        setMessage(success);
        setOpen(true);
        navigate("/User-Screen/");
        window.location.reload();
      } else if (response.status >= 400 && response.status < 500) {
        if (response.data && response.data.message) {
          setMessage(response.data.message);
        } else {
          setMessage(errorMessage);
        }
        setOpen(true);
      } else if (response.status >= 500 && response.status < 600) {
        setMessage(errorMessage);
        setOpen(true);
      }
    } catch (e: any) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors.length !== 0
      ) {
        setMessage(e.response.data.errors);
      } else {
        setMessage(userExists);
      }
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        if (
          userData.userType === "finance" ||
          userData.userType === "salesHead"
        ) {
          setUserData((prevData) => ({
            ...prevData,
          }));
        }
        await fetchData();
      } catch (error) {
        setMessage(errorMessage);
        setOpen(true);
      }
    } else {
      setMessage(allFields);
      setOpen(true);
    }
  };

  const validateForm = (): boolean => {
    const {
      firstName,
      lastName,
      email,
      userName,
      reportingManagerUserName,
      userType,
      mobileNumber,
      operatingUnitLocation,
      operatingUnitType,
    } = userData;
    const isReportingManagerOptional =
      userType === finance || userType === salesHead;
    return (
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      email.trim() !== "" &&
      userName.trim() !== "" &&
      (!isReportingManagerOptional || reportingManagerUserName.trim() !== "") &&
      userType.trim() !== "" &&
      mobileNumber.trim() !== "" &&
      operatingUnitLocation.trim() !== "" &&
      operatingUnitType.trim() !== ""
    );
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOtherInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleReporterManagerChange = (newValue:any) => {
    setUserData((prevState) => ({
      ...prevState,
      reportingManagerUserName: newValue,
    }));
  };
  const handleReporterChange = (e: any) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOperatingUnitTypeChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleUserNameChange = (event: { target: { value: any } }) => {
    const { value } = event.target;
    const userNameCondition = value.replace(/\D/g, "");
    const firstDigit = parseInt(userNameCondition.charAt(2));

    if (firstDigit < 5) {
      setMessage("Invalid");
      setOpen(true);
      return;
    }
    if (userNameCondition.length <= 12) {
      setUserData({ ...userData, userName: userNameCondition });
    }
  };

  const handleMobileNumberChange = (event: { target: { value: any } }) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, "");
    const firstDigit = parseInt(numericValue.charAt(0));
    if (firstDigit >= 1 && firstDigit <= 5) {
      setMessage("Invalid");
      setOpen(true);
      return;
    }
    if (numericValue.length <= 10) {
      setUserData({ ...userData, mobileNumber: numericValue });
    }
  };
  const currentUser = useSelector((state: RootState) => {
    return state.user;
  });
  return (
    <>
      <Snackbar
        ContentProps={{
          "aria-describedby": "message-id",
          className: classes.snackbarStyleViaContentProps,
        }}
        open={open}
        autoHideDuration={3000}
        message={message}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <div className="conflicts_tabs"></div>
      <div className="addOthersBody_content">
        {currentUser.userRole !== "salesHead" ? (
          <td className="text-center" colSpan={7}>
            {noData}
          </td>
        ) : (
          <>
            <form className="user-profile-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label>{addRole[0]}:</label>
                <select
                  name="userType"
                  value={userData.userType}
                  onChange={handleInputChange}
                >
                  <option value="">{select}</option>
                  <option value="salesHead">{userTypeList[0]}</option>
                  <option value="assistantGeneralManager">
                  {userTypeList[1]}
                  </option>
                  <option value="manager">{userTypeList[2]}</option>
                  <option value="clusterManager">{userTypeList[3]}</option>
                  <option value="doctorReferralExecutive">
                  {userTypeList[4]}
                  </option>
                  <option value="finance">{userTypeList[5]}</option>
                  <option value="tradePartner">{userTypeList[6]}</option>
                </select>
              </div>

              <div className="form-groups" style={{display :"flex",marginLeft:"6rem "}}>
                <label htmlFor="reportingManagerUserNames" style={{ fontSize:"13px",color:"#333" }}>{addRole[1]}:</label>
                <Autocomplete className="reportingMAnagerUserName"
                value={userData.reportingManagerUserName}
                onChange={(event, newValue) => handleReporterManagerChange(newValue)}
                sx={{ width: 200, padding: "1.5px 4px 7.5px 6px" }}
                disabled={userData.userType === "finance" || userData.userType === "salesHead"}
                options={salesHeadUserNames}
                renderInput={(params) => <TextField {...params} className="reportingManagerUserNames" placeholder="Please Select" />}
              />
              </div>              
              <div className="form-group">
                <label>{addRole[2]}:</label>
                <input
                  type="text"
                  name="userName"
                  value={userData.userName}
                  onChange={handleUserNameChange}
                />
              </div>

              <div className="form-group">
                <label>{addRole[3]}:</label>
                <input
                  type="text"
                  name="firstName"
                  value={userData.firstName}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label>{addRole[4]}:</label>
                <input
                  type="text"
                  name="lastName"
                  value={userData.lastName}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label>{addRole[5]}:</label>
                <input
                  type="text"
                  name="email"
                  value={userData.email}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label>{addRole[6]}:</label>
                <input
                  type="text"
                  value={userData.mobileNumber}
                  maxLength={10}
                  onChange={handleMobileNumberChange}
                />
              </div>

              <div className="form-group">
                <label>{addRole[7]}:</label>
                <select
                  name="operatingUnitLocation"
                  value={userData.operatingUnitLocation}
                  onChange={handleSelectChange}
                  style={{ width: "219px" }}
                >
                  <option value="">Please select</option>
                  <option value="Amalapuram">Amalapuram</option>
                  <option value="Tatipaka">Tatipaka</option>
                  <option value="Ragolu">Ragolu</option>
                </select>
              </div>

              <div className="form-group">
                <label>{addRole[8]}:</label>
                <select
                  name="operatingUnitType"
                  value={userData.operatingUnitType}
                  onChange={handleOperatingUnitTypeChange}
                  style={{ width: "219px" }}
                >
                  <option value="">Please select</option>
                  <option value="Hospital">Hospital</option>
                  <option value="Clinic">Clinic</option>
                </select>
              </div>

              {showTPFields && (
                <>
                  <div className="form-group">
                    <label>{addRole[9]}:</label>
                    <input
                      type="text"
                      name="doctorType"
                      value={userData.doctorType}
                      onChange={handleOtherInputChange}
                    />
                  </div>

                  <div className="form-group">
                    <label>{addRole[10]}:</label>
                    <input
                      type="text"
                      name="mandal"
                      value={userData.mandal}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>{addRole[11]}:</label>
                    <input
                      type="text"
                      name="district"
                      value={userData.district}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>{addRole[12]}:</label>
                    <input
                      type="text"
                      name="village"
                      value={userData.village}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label style={{ fontSize: "15px" }}>{addRole[13]}:</label>
                    <input
                      type="text"
                      name="state"
                      value={userData.state}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>{addRole[14]}:</label>
                    <input
                      type="text"
                      name="pincode"
                      value={userData.pincode}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>{addRole[15]}:</label>
                    <input
                      type="text"
                      name="bankName"
                      value={userData.bankName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>{addRole[16]}:</label>
                    <input
                      type="text"
                      name="bankAccountNUmber"
                      value={userData.bankAccountNUmber}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>{addRole[17]}:</label>
                    <input
                      type="text"
                      name="bankIfscCode"
                      value={userData.bankIfscCode}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>{addRole[18]}:</label>
                    <input
                      type="text"
                      name="accountHolderName"
                      value={userData.accountHolderName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>{addRole[19]}:</label>
                    <input
                      type="text"
                      name="hospitalName"
                      value={userData.hospitalName}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}
            </form>
            <div className="cancel-submit-btn">
              <Button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                {submit}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserProfileForm;
