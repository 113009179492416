import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import Pagination from "../../components/pagination";
import HttpService from "../../services/HttpService";
import { GRADIENTIMAGECOLOR, PATIENT_DATA_HEADER_ONHOLD, ActiveTabTypes } from "../../utils/constants";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, TextField, Snackbar } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Paper from '@mui/material/Paper';
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import KeycloakService from "../../services/KeycloakService";
import { VERSION } from "../../constants";
import editIcon from '../../assets/images/edit.svg';
import { PatientDataManager, patientsInfoPayload } from "../../types/Module_Type";
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import "./../pages.css";

 const useStyles = makeStyles(() => ({
    snackbarStyleViaContentProps: {
      backgroundImage: GRADIENTIMAGECOLOR,
    },
  }));
const _axios = HttpService.getAxiosClient();

const PatientInfoOnHold = ({ 
    selectedVisitType,
    filteredData,
    filterApplied
  } : {
    selectedVisitType: string;
    filteredData: PatientDataManager;
    filterApplied: boolean
  }) => {    
    const classes = useStyles();
    const [patientArrayM, setPatientArrayM] = React.useState<PatientDataManager[]>([]);
    const [page, setPage] = React.useState(1);
    const [rowPageSize, setRowPageSize] = React.useState(30);
    const [totalPages, setTotalPages] = React.useState(Number);
    const [offsetVal, setOffsetVal] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [openDialog, setOpenDialog] = React.useState(false);

    const [patientStatus, setPatientStatus] = React.useState('');
    const [admittedOptions, setAdmittedOptions] = React.useState(false);
    const [refLostOptions, setRefLostOptions] = React.useState(false);

    const [patientUHIDValue, setPatientUHIDValue] = useState<string>('');
    const [reasonValue, setReasonValue] = useState<string>('');
    const [selPatient, setSelPatient] = React.useState('');

    const [visitType, setVisitType] = React.useState('');

    const [admittedDate, setAdmittedDate] = React.useState<Date | null>(null);
    const [arrivedDate, setArrivedDate] = React.useState<Date | null>(null);
    const startingIndex = page === 1 ? 1 : (page - 1) * rowPageSize +1;

    const [dialogTitleStr, setDialogTitleStr] = React.useState('');
    const [totalDataCount,setTotalDataCount]=React.useState(0);
    const [selectDate, setSelectDate] = React.useState('');
    const currentUser = useSelector((state: RootState) => {
        return state.user;
    });

    useEffect(() => {
        getInitialPaitentList();
    }, [page, rowPageSize, selectedVisitType, filteredData, filterApplied]);

    const getInitialPaitentList = async () => {
        try {
            setPatientArrayM([]);
            if(filterApplied){
                if(filteredData && filteredData?.referred_patient_details?.length > 0){
                    setPatientArrayM(filteredData?.referred_patient_details);
                    setTotalPages(Math.ceil(filteredData?.referred_patient_details_aggregate?.aggregate.count / rowPageSize));
                    setTotalDataCount(filteredData?.referred_patient_details_aggregate?.aggregate?.count);
                    setOffsetVal((rowPageSize * page) - rowPageSize);
                } else {
                    // Render message indicating no data found for the applied filter
                    setPatientArrayM([]);
                    setTotalPages(1);
                    setTotalDataCount(0);
                }
            } else {
                let intentStr = currentUser.userRole === 'doctorReferralExecutive' ? 'getRefferedAndReferralLostPatientsForDreByDate'
                  : currentUser.userRole === 'clusterManager' ? 'getAllReferredAndReferralLostPatientForClusterManager'
                  : currentUser.userRole === 'manager' ? 'getAllReferredAndReferralLostPatientsForManager'
                  : currentUser.userRole === 'assistantGeneralManager' ? 'getAllReferredAndReferralLostPatientsForAssistantGeneralManager'
                  : currentUser.userRole === 'salesHead' ? 'getAllReferredAndReferralLostPatientForSalesHead'
                  : '';

                const onHoldPatientsPayload:patientsInfoPayload = {
                    intent: intentStr,
                    state: {
                        patientStatus: ActiveTabTypes.Onhold,
                        limit: rowPageSize,
                        offset: offsetVal,
                        visitType: selectedVisitType,
                        // startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                        /* displaying start date as 1st january of current year i.e. example 2023-01-01 */
                        startDate: moment().startOf('year').format('YYYY-MM-DD')+"T00:00:00.0000",
                        endDate: moment().format('YYYY-MM-DD')+"T23:59:59.9999",
                    },
                }

                if (currentUser.userRole === 'doctorReferralExecutive') {
                    onHoldPatientsPayload.state.dreId = KeycloakService.getSub();
                } else if (currentUser.userRole === 'clusterManager') {
                    onHoldPatientsPayload.state.clusterManagerId = KeycloakService.getSub();
                } else if (currentUser.userRole === 'manager') {
                    onHoldPatientsPayload.state.managerId = KeycloakService.getSub();
                } else if (currentUser.userRole === 'assistantGeneralManager') {
                    onHoldPatientsPayload.state.agmId = KeycloakService.getSub();
                } else if (currentUser.userRole === 'salesHead') {
                    onHoldPatientsPayload.state.salesHeadId = KeycloakService.getSub();
                }

                const onHoldPatientsResp = await _axios.post(
                `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,onHoldPatientsPayload,
                    {
                        headers: {
                        version: VERSION,
                        },
                    }
                );
                
                const onHoldPatientsRespData = onHoldPatientsResp.data;

                if (
                    onHoldPatientsRespData &&
                    onHoldPatientsRespData.referred_patient_details.length != 0
                ) {
                    const sortedOnHoldPatientsArray = onHoldPatientsRespData.referred_patient_details.sort((a: { patient_admission_date: string }, b: { patient_admission_date: string }) => {
                        const dateA = new Date(a.patient_admission_date);
                        const dateB = new Date(b.patient_admission_date);
                      
                        return dateB.getTime() - dateA.getTime();
                    });
                      
                    setPatientArrayM(sortedOnHoldPatientsArray);
                    setTotalPages(
                        Math.ceil(onHoldPatientsRespData?.referred_patient_details_aggregate?.aggregate?.count / rowPageSize)
                    );
                    setTotalDataCount(onHoldPatientsRespData?.referred_patient_details_aggregate?.aggregate?.count);
                    setOffsetVal((rowPageSize * page) - rowPageSize);
                } else {
                    setPatientArrayM([]);
                    setTotalPages(1);
                }
                
                if (onHoldPatientsRespData.referred_patient_details_aggregate?.aggregate?.count === 0) {
                    setPatientArrayM([]);
                    setTotalPages(1);
                }
            }
        }
        catch (e: any) {
            setMessage('getInitialPaitentList' + e.message);
            setOpen(true);
        }
    }

    const setPatientStatusChange = (event: SelectChangeEvent<string>) => {
        setPatientStatus(event.target.value);
    
        setAdmittedOptions(false);
        setRefLostOptions(false);
        setAdmittedDate(null);
        setArrivedDate(null);
        // setPatientUHIDValue('');
        if (event.target.value === ActiveTabTypes.Admitted || event.target.value === ActiveTabTypes.Arrived) {
          setAdmittedOptions(true);
        } else if(event.target.value === "referralLost"){
          setRefLostOptions(true);
        }
    };

    const handlePatientUHIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPatientUHIDValue(event.target.value);
    };

    const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReasonValue(event.target.value);
    };

    const previousPage = () => {
        let previousPageNo = page - 1;
        setPage(previousPageNo);
        setOffsetVal(offsetVal - rowPageSize);
    }
    const nextPage = () => {
        let nextPageNo = page + 1;
        setPage(nextPageNo);
        setOffsetVal(offsetVal + rowPageSize);
    }
    const rowPerPageChange = (e: any) => {
        let val = e.target.value;
        setRowPageSize(val);
        setPage(1);
        // reset to 0 when rows per page changes if page no might be more than 1
        setOffsetVal(0);
    }

    const setDateFormat = (date:String) => {
        if(date != null && date != ""){
            let dateWithOutString = date.replace(/"/gi, "");
            dateWithOutString = moment(dateWithOutString).format('DD-MMM-YY , hh:mm')
            return dateWithOutString;
        } else {
            return '-';
        }
    }

    const onHoldPatientsTableContainerRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        const resizeHandler = () => {
            const container = onHoldPatientsTableContainerRef.current
            if (container) {
            const availableHeight: any =
                window.innerHeight - container.getBoundingClientRect().top
            container.style.maxHeight = `${availableHeight - 90}px`
            }
        }

        window.addEventListener('resize', resizeHandler)
        resizeHandler()

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        boxShadow: 'none'
    }));

    const handleVisitTypeChange = (event: SelectChangeEvent) => {
        setVisitType(event.target.value);
        // setAdmittedOptions(false);
        setRefLostOptions(false);
        setPatientStatus('');
    };

    const handleClickDialogOpen = () => {
        setOpenDialog(true);
        setPatientUHIDValue('');
        setPatientStatus("");
        setAdmittedOptions(false);
        setRefLostOptions(false);
        setVisitType(selectedVisitType);
    };

    const handleDialogClose = () => {
        setPatientUHIDValue('');
        setOpenDialog(false);
        setReasonValue('');
    };

    const handleDialogSubmit = async () => {
        if(patientStatus == ""){
            setMessage('Please select any status for the patient');
            setOpen(true);
            return false;
          }
      
          let intentStr = "";
          let patientStatusStr = "";
          let resourceStr = "";
          let selDateVal = "";
      
          let payloadObj = {};
          if(patientStatus === ActiveTabTypes.Admitted || patientStatus === ActiveTabTypes.Arrived) {
            resourceStr = visitType;
            let dateKeyStr = "";
            const isValidInput = /^MU[A-Za-z]\d{8}$/;
            if (patientUHIDValue === "") {
            setMessage("Please enter Patient UHID");
            setOpen(true);
            return false;
            } else if (!isValidInput.test(patientUHIDValue)) {
            setMessage("Entered UHID Number is invalid");
            setOpen(true);
            return false;
            }
            if(visitType === ""){
                setMessage('Please select any Visit Type');
                setOpen(true);
                return false;
            }      
            if(visitType == "ipd"){
              intentStr = "ipAdmitApi";
              patientStatusStr = ActiveTabTypes.Admitted;
              dateKeyStr = "admittedOn";
              if(admittedDate == null){
                setMessage('Please select Admitted Date for the patient');
                setOpen(true);
                return false;
              } else {
                selDateVal = admittedDate ? admittedDate.toISOString() : '';
              }
            } else if(visitType == "opd"){
              intentStr = "createPatientOpArrivedRecord";
              patientStatusStr = ActiveTabTypes.Arrived;
              dateKeyStr = "arrivedOn";
              if(arrivedDate == null){
                setMessage('Please select Arrived Date for the patient');
                setOpen(true);
                return false;
              } else {
                selDateVal = arrivedDate ? arrivedDate.toISOString() : '';
              }
            }
      
            if(visitType == "ipd"){
      
              payloadObj = {
                intent: intentStr,
                "state":{
                  "updatedBy": localStorage.getItem("userName"),
                  "patientUhid": patientUHIDValue,
                  "visitType": visitType,
                  "patientStatus": patientStatusStr,
                  "admittedOn" : selDateVal,
                  "patientId": selPatient,
                  "resource": resourceStr,
                  "userRole": currentUser.userRole
                }
              };
            } else if(visitType == "opd"){
              payloadObj = {
                intent: intentStr,
                "state":{
                  "updatedBy": localStorage.getItem("userName"),
                  "patientUhid": patientUHIDValue,
                  "visitType": visitType,
                  "patientStatus": patientStatusStr,
                  "arrivedOn" : selDateVal,
                  "patientId": selPatient,
                  "resource": resourceStr,
                  "userRole": currentUser.userRole
                }
              };
            }
      
          } else if(patientStatus === "referralLost"){
            if (reasonValue == ""){
              setMessage('Please enter the reason');
              setOpen(true);
              return false;
            }
            intentStr = "updatePatientReferralLostStatus";
            
            payloadObj = {
              intent: intentStr,
              "state":{
                "patientId": selPatient,
                "updatedBy": localStorage.getItem("userName"),
                "userRole": currentUser.userRole,
                "patientStatus": patientStatus,
                "remarks": reasonValue,
              }
            };
          }
      
          const onHoldStatusChangeResp = await _axios.post(
            `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
            payloadObj,
            {
              headers: {
                version: VERSION,
              },
            }
          );

        if(onHoldStatusChangeResp.status == 200 && onHoldStatusChangeResp.data.update_referred_patient_details_by_pk.length != 0){
            getInitialPaitentList();
            setMessage('Patient status for '+dialogTitleStr+' has been updated successfully');
            setOpen(true);
            setOpenDialog(false);
        } else {
            setMessage('Unable to update the patient '+dialogTitleStr+' status.');
            setOpen(true);
            return false;
        }

    };    

    const [showPhoneNumber, setShowPhoneNumber] = useState(false);
    const [clickedPhoneNumber, setClickedPhoneNumber] = useState<string | null>(null);
      const maskPhoneNumber = (phoneNumber: string) => {
          const lastThreeDigits = phoneNumber.slice(-3);
          const maskedNumber = '*'.repeat(Math.max(0, phoneNumber.length - 3)) + lastThreeDigits;
          return maskedNumber;
        };
        const handleTogglePhoneNumber = (phoneNumber: string) => {
          setClickedPhoneNumber(showPhoneNumber ? null : phoneNumber);
        };

    return (
        <>
            <div className='tableWithHadle' ref={onHoldPatientsTableContainerRef}>
                <Snackbar
                    ContentProps={{
                    "aria-describedby": "message-id",
                    className: classes.snackbarStyleViaContentProps
                    }}
                    open={open}
                    autoHideDuration={3000}
                    message={message}
                    onClose={() => setOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                />

                <Dialog open={openDialog} onClose={handleDialogClose}>
                    <DialogTitle>{dialogTitleStr + " - Status"}</DialogTitle>
                    <DialogContent>

                    <Grid container className="mt2" spacing={3}>
                        <Grid item xs={5} className="text-right">
                            <div className="onhold_dialog_item">Visit Type</div>
                        </Grid>
                        <Grid item xs={7} className="text-left">
                            <FormControl>
                                <Select
                                className="onhold_dialog_item_text_left_visit_type"
                                size="small"
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={visitType}
                                displayEmpty
                                onChange={handleVisitTypeChange}
                                >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                <MenuItem value="ipd">IPD</MenuItem>
                                <MenuItem value="opd">OPD</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className="mt2" spacing={3}>
                        <Grid item xs={5} className="text-right">
                            <div className="onhold_dialog_item">Patient Status</div>
                        </Grid>
                        <Grid item xs={7} className="text-left">
                        <FormControl>
                            <Select
                            className="onhold_dialog_item_text_left_patient_status"
                            size="small"
                            displayEmpty
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={patientStatus}
                            onChange={setPatientStatusChange}
                            >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                {visitType === "ipd" && <MenuItem value={ActiveTabTypes.Admitted}>Admitted</MenuItem>}
                                {visitType === "opd" && <MenuItem value={ActiveTabTypes.Arrived}>Arrived</MenuItem>}                                
                                <MenuItem value="referralLost">Referral Lost</MenuItem>
                            </Select>
                        </FormControl>
                        </Grid>
                    </Grid>

                    {admittedOptions ? 

                    <>
                    <Grid container spacing={3} className="mb10">
                        <Grid item xs={5} className="text-right">
                            <div className="onhold_dialog_item">Patient UHID</div>
                        </Grid>
                        <Grid item xs={7} className="text-left onhold_dialog_patientuhid">
                            <TextField
                            autoFocus
                            margin="dense"
                            label="Patient UHID"
                            type="text"
                            variant="standard"
                            value={patientUHIDValue}
                            onChange={handlePatientUHIDChange}
                            />
                        </Grid>
                    </Grid>
                        
                    {visitType == "ipd" ?
                        <Grid container className="mt2" spacing={3}>
                            <Grid item xs={5} className="text-right">
                                <Item id="onhold_dialog_item_text-right_date">Admitted Date</Item>
                            </Grid>
                            <Grid item xs={7} className="text-left">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                    minDate={new Date(selectDate)}
                                    label="Choose a Date"
                                    value={admittedDate}
                                    disableFuture
                                    onChange={async (newValue) => {
                                        setAdmittedDate(newValue);
                                    } }
                                    renderInput={(params) => <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />} />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>


                        : 
                        
                        <Grid container className="mt2" spacing={3}>
                            <Grid item xs={5} className="text-right">
                            <Item id="onhold_dialog_item_text-right_date">Arrived Date</Item>
                            </Grid>
                            <Grid item xs={7} className="text-left">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                minDate={new Date(selectDate)}
                                label="Choose a Date"
                                value={arrivedDate}
                                disableFuture
                                onChange={async (newValue) => {
                                    setArrivedDate(newValue);
                                } }
                                renderInput={(params) => <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />} />
                            </LocalizationProvider>
                            </Grid>
                        </Grid>
                        }
                        
                        </>

                    : '' }
                    
                    {refLostOptions ?
                    <Grid container className="mt2" spacing={5}>
                        <Grid item xs={5} className="text-right">
                        <Item>Reason</Item>
                        </Grid>
                        <Grid item xs={7} className="text-left">
                        <TextField
                            label="Reason"
                            multiline
                            rows={4}
                            value={reasonValue}
                            onChange={handleReasonChange}
                            />
                    </Grid>
                    </Grid>
                    
                    : '' }

                    </DialogContent>
                    <DialogActions>
                        <div className="cancel-submit-btn">
                        <Button onClick={handleDialogClose}>Cancel</Button>
                        <Button onClick={handleDialogSubmit}>Submit</Button>
                        </div>
                    </DialogActions>
                </Dialog>

                <table className='data-grid-table'>
                    <thead className='tableList_stickyHeader'>
                        <tr> {PATIENT_DATA_HEADER_ONHOLD.map((d, i) => <th key={i}><span></span>{d}</th>)} </tr>
                    </thead>
                    <tbody>
                        {patientArrayM.length > 0 ?
                            patientArrayM.map((d, i) => (
                                <tr key={i}>
                                    <td>{startingIndex +i }</td>
                                    <td>
                                    {d?.dre_trade_partner_map?.medunited_user?.first_name +
                                        " " +
                                        d?.dre_trade_partner_map?.medunited_user?.last_name}
                                    </td>
                                    <td>{d.dre_trade_partner_map?.medunited_user?.mobile_number}</td>
                                    <td>{d.patient_name}</td>
                                    <td>
                                        <span onClick={() => handleTogglePhoneNumber(d?.mobile_number)}>
                                        {clickedPhoneNumber === d?.mobile_number? d.mobile_number: maskPhoneNumber(d?.mobile_number)}
                                        </span>
                                    </td>
                                    <td>{d.patient_status}&nbsp;
                                        {currentUser.userRole === 'doctorReferralExecutive' ?
                                            <>
                                                <img className="" src={editIcon} style={{cursor: "pointer"}}
                                                    onClick={() => {
                                                        handleClickDialogOpen();
                                                        setSelectDate(d?.patient_admission_date);
                                                        setSelPatient(d?.id.toString());
                                                        setDialogTitleStr(d?.patient_name);
                                                }} />
                                            </>
                                        : ''}
                                    </td>
                                    <td>{setDateFormat(d.patient_admission_date)}</td>
                                    <td>{d.patient_reason}</td>
                                </tr>
                            )) :
                            <tr>
                                <td className='text-center' colSpan={9}>No Patient Details available</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <Pagination
                pageNo={page}
                totalPages={totalPages}
                previousPage={previousPage}
                nextPage={nextPage}
                rowPageSize={rowPageSize}
                totalDataCount={totalDataCount}
                rowPerPageChange={rowPerPageChange}
            />
        </>
    );
}
export default PatientInfoOnHold;