export const MID_URL_FORM_RUNTIME_DATA = 'form-runtime/v1/form-data';

export const GRADIENTIMAGECOLOR = 'linear-gradient(to bottom right, #DA4B48, #EB9554)';

export const INSTACONFLICTSTABLEHEADDATA = [
    { field: 'PATIENT_PHONE', headerName: 'Mobile', flex: 1, headerClassName: 'aaa' },
    { field: 'PATIENT_NAME', headerName: 'Patient Name', flex: 1 },
    { field: 'MR_NO', headerName: 'MR Number', flex: 1 },
    { field: 'RATE_PLAN_ID', headerName: 'Rate Plan ID', flex: 1 },
    { field: 'REG_DATE_TIME', headerName: 'Registration Date TIme', flex: 1 }
];

export const REFERRALCONFLICTSTABLEHEADDATA = [
    { field: 'mobileNumber', headerName: 'Mobile', flex: 1, headerClassName: 'aaa' },
    { field: 'name', headerName: 'Patient Name', flex: 1 },
    { field: 'specialityOfTreatment', headerName: 'Speciality Of Treatment', flex: 1 },
    { field: 'referralName', headerName: 'Referral Name', flex: 1 }
];

export const PAYMENTADVISETABLEHEADDATA = [
    { field: 'type', headerName: 'Type', width: 70 },
    { field: 'hospitalAccNo', headerName: 'Hospital Acc No', width: 160 },
    { field: 'amount', headerName: 'Referrer Amount', width: 130 },
    { field: 'currency', headerName: 'Currency', width: 130 },
    { field: 'referrerAccNo', headerName: 'Referrer Account No', width: 130 },
    { field: 'ifsc', headerName: 'IFSC Code', width: 130 },
    { field: 'name', headerName: 'Referrer Name', width: 130 }
];

export const MENULISTONROLEDATA = [
    {
        executive: ['Procedure Update', 'Referrer Patient Conflicts'],
        managingDirector: ['Payment Approval By Director'],

        finance:['Payment Details for Finance'],
        manager: ['Patients Info','Payment Details'],
        doctorReferralExecutive: ['Patients Info','Payment Details'],
        clusterManager: ['Patients Info','Payment Details'],
        assistantGeneralManager: ['Patients Info','Payment Details'],
        salesHead: ['Patients Info','Payment Details','User Screen'],
    }
];

export const PATIENT_DATA_HEADER = ['Patient UHID','Referrer Name','Referrer Mob','Patient Name','Patient.Num','Status','View','Comments'];

export const PATIENT_DATA_HEADER_ALL = ['Patient UHID','Referrer Name','Referrer Mob','Patient Name','Patient.Num','Status','Executive Name'];
export const PATIENT_DATA_HEADER_REFFERED = ['Patient UHID','Referrer Name','Referrer Mob','Patient Name','Patient.Num','Status','Executive Name', 'Referred Dt'];
export const PATIENT_DATA_HEADER_ADMITTED = ['Patient UHID','Referrer Name','Referrer Mob','Patient Name','Patient.Num','Status', 'Referred.Dt', 'Admitted On', 'Comments'];
export const PATIENT_DATA_HEADER_DISCHARGED = ['Patient UHID','Referrer Name','Referrer Mob','Patient Name','Patient.Num','Status','Admitted.Dt', 'Reg.Dat', 'Discharged.Dt'];
export const PATIENT_DATA_HEADER_ONHOLD = ['Sl No','Referrer Name','Referrer Mob','Patient Name','Patient.Num','Status','Patient Admission Ack.Dt','Remarks'];
export const PATIENT_DATA_HEADER_REFERRALLOST = ['Sl No','Referrer Name','Referrer Mob','Patient Name','Patient.Num','Status','Referred On','Updated On','Remarks'];

export const PROCEDUREUPDATETABS = ['Patient Details', 'Referrer Details'];

export const PATIENT_INFO_TABS = [ 'Referred', 'Admitted', 'Discharged', 'Onhold', 'Referral lost'];

export const FORMID = {
    paymentAcceptanceTranscationId : '1030055364952023040',
    instaReferalPatientId:'960789210929950720',
    commentsId:'1030420482906873856',
    procedureReferId:'1033281457263697920',
    procedureRefereInstaDataId:'1029306794296696832'
}

export const DRE_PATIENT_DATA_HEADER_REFFERED = ['Sl No','Referrer Name','Referrer Mob','Patient Name','Patient Mob', 'Status', 'Referred.Dt'];

export const ActiveTabTypes: any  = {
    Referred : 'referred',
    Admitted : 'admitted',
    Arrived : 'arrived',
    Discharged : 'discharged',
    Consulted : 'consulted',
    Consultation_Completed : 'completed',
    Onhold : 'onhold',
    Referral_Lost : 'referralLost',
    Referral_lost : 'referrallost',
}

export const RED_COLOR = 'red';
export const tableHead = ["S.No","Username","Name","Mob.Num","User Type","Village","Mandal/District","State","Pincode","Bank Name","Bank A/C No","IFSC Code","A/C Holder.Name","Reset.Pwd","Edit"];
export const tableHeadForDownloads = ["Username","Name","Mob.Num","User Type","Village","Mandal/District","State","Pincode","Bank Name","Bank A/C No","IFSC Code","A/C Holder.Name"];
export const errorMessage = "Error fetching data. Please try again."
export const productName = "Product Name";
export const quantity ="Quantity";
export const price="Price";
export const total="Total";
export const view ="View";
export const product ="Products";
export const emptyInvoice = "No Invoices available";
export const password = "Please enter password";
export const confirmPasswordMSg ="Please enter confirm password";
export const passwordMismatch ="Password and confirm password do not match."
export const userDataNull ="useraccessData is undefined or null"
export const userScreen_Head = "Users Details";
export const success = "Successfully Added"
export const select = "Please select";
export const selectDate ="Please select Date";
export const finance ="finance";
export const salesHead = "salesHead";
export const downlaod = "Download";
export const exportData = "Export";
export const date = "Date";
export const submit ="Submit";
export const noData ="No Details available";
export const userExists ="user already exists ";
export const addRole =['User Type','Reporting Manager','User Name','First Name','Last Name','Email','Mobile Number','Operating Unit Location','Operating Unit Type','Doctor Type','Mandal','District','Village','State','Pincode','bankName','bankAccountNumber','bankIfscCode','accountHolderName','hospitalName']
export const allFields ="Please enter values for all fields.";
export const userTypeList =["Sales Head","Assistant General Manager","Manager","Cluster Manager","Doctor Referral Executive","Finance","TradePartner"];
export const tabsName = ['Payments to be Approved', 'Payments Approved', 'Processed Payments', 'Rejected Payments', 'Payments onHold'];
export const tableHead_paymentDetails = ['Patient UHID', 'Referrer Name', 'Referrer.Mob', 'Patient Name','Visit Type', 'Admission.Dt','Discharge.Dt','Updated.Dt','Finalised.Amt','Referrer.Amt', 'Status'];
export const tabsName_Finance = ['Payments Approved', 'Processed Payments'];
export const tableHead_Finance = ['Patient UHID', 'Referrer Name', 'Referrer.Mob', 'Patient Name','Visit Type','Adm.Dt','Discharge.Dt','Updated.Dt', 'Finalised Amount', 'Referrer Amount','Status'];
export const rupee="Rs.";
export const cancel="Cancel";
export const submitted ="Details submitted successfully";
export const dataNotDefined ="editUserData is not defined.";