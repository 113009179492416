import React, { useState } from "react";
import axios from "axios";
import { Button, Snackbar } from "@mui/material";
import * as XLSX from "xlsx";
import HttpService from "../../services/HttpService";
import "./UserProfileForm.css";
import { GRADIENTIMAGECOLOR, noData, errorMessage, success } from "../../utils/constants";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store";


const useStyles = makeStyles(() => ({
  snackbarStyleViaContentProps: {
    backgroundImage: GRADIENTIMAGECOLOR,
  },
}));
const Upload: React.FC<{}> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [excelFile, setExcelFile] = useState<File | null>(null);
  const [message, setMessage] = useState("");
  const currentUser = useSelector((state: RootState) => {
    return state.user;
  });
  const [open, setOpen] = React.useState(false);
  const headers = [
    "userName",
    "firstName",
    "lastName",
    "mobileNumber",
    "email",
    "userType",
    "doctorType",
    "state",
    "district",
    "mandal",
    "village",
    "pincode",
    "hospitalName",
    "bankName",
    "bankAccountNumber",
    "accountHolderName",
    "bankIfscCode",
    "reportingManagerUserName",
    "operatingUnitLocation",
    "operatingUnitType",
  ];

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      setExcelFile(file);
    }
  };
  const classes = useStyles();

  const _axios = HttpService.getAxiosClient();
  const token = sessionStorage.getItem("react-token");
  const handleDownload = () => {
    const worksheet = XLSX.utils.aoa_to_sheet([headers]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "excel.xlsx");
  };
  const handleSubmit = async () => {
    console.log("testsubmit");

    try {
      const formData = new FormData();
      formData.append("file", excelFile as Blob);

      const response = await _axios.post(
        "https://dev.medunited-rms.techsophy.com/medunited-rms/upload/trade-partners",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            version: "WORKING",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    if(response.status == 200){
      setMessage(success);
      setOpen(true);
    }
    } catch (error:any) {
      setMessage(error.message + ": " + error.response.data.message);
      setOpen(true);
      console.error("Error details:", error);
    }
  };

  return (
    <>
      <div className="conflicts_tabs"></div>
      <Snackbar
                    ContentProps={{
                    "aria-describedby": "message-id",
                    className: classes.snackbarStyleViaContentProps
                    }}
                    open={open}
                    autoHideDuration={3000}
                    message={message}
                    onClose={() => setOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                />
                      {currentUser.userRole !== "salesHead" ? (
        <td className="text-center"  colSpan={7}>
           {noData}
        </td>
        ):(
      <div className="addBody_content">
        <input type="file" onChange={handleFileUpload} />
        <div className="downloadTemplate">
          <Button onClick={handleDownload}>Download Template</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!excelFile}
          >
            Submit
          </Button>
        </div>
      </div>
        )}
    </>
  );
};

export default Upload;
