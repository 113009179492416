import React, { ReactElement, FC, useRef, useEffect, useState } from "react";
import Sidemenu from '../components/Sidemenu';
import { Snackbar, Grid, TextField, Button } from "@mui/material";
import Pagination from "../components/pagination";
import HttpService from "../services/HttpService";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { FORMID, GRADIENTIMAGECOLOR, MID_URL_FORM_RUNTIME_DATA, date, downlaod, rupee, selectDate, tableHead_paymentDetails, tabsName } from "../utils/constants";
import './conflicts/conflicts.css'
import { VERSION } from "../constants";
import KeycloakService from "../services/KeycloakService";
import editIcon from '../assets/images/edit.svg';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Paper from '@mui/material/Paper';

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { docsData, paymentStatusSubmitPayload, paymentDetailsPayload, paymentDetailsPayloadFilter } from "../types/Module_Type";
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import FilterIcon from ".././assets/images/filter.svg";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/lab";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import closeIcon from './../assets/images/close.svg';
import 'jspdf-autotable';
import { setDate } from "date-fns";

const _axios = HttpService.getAxiosClient();

const useStyles = makeStyles(() => ({
  snackbarStyleViaContentProps: {
    backgroundImage: GRADIENTIMAGECOLOR,
  },
}));



const PaymentDetails: FC<any> = (): ReactElement => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [activeTab, setActiveTab] = React.useState(0);
  const [activeTabName, setActiveTabName] = React.useState('payments to be Approved');
  const [docArray, setDocArray] = useState<docsData[]>([]);
  const [page, setPage] = React.useState(1);
  const [rowPageSize, setRowPageSize] = React.useState(30);
  const [totalPages, setTotalPages] = React.useState(Number);
  const [offsetVal, setOffsetVal] = React.useState(0);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [paymentStatus, setPaymentStatus] = React.useState('');
  const [selPatient, setSelPatient] = React.useState(Number);
  const [selPatientVisitId, setSelPatientVisitId] = React.useState('');
  const [reasonValue, setReasonValue] = useState<string>('');
  const [paymentRejectedOptions, setPaymentRejectedOptions] = useState(false);
  const [filterApplied,setFilterApplied]=useState(false);
  const PaymentsApprovedTableContainerRef = useRef<HTMLDivElement | null>(null);
  const [totalDataCount,setTotalDataCount]=React.useState(0);
  const startingIndex = page === 1 ? 1 : (page - 1) * rowPageSize +1;
  const [isFilterBarVisible, setFilterBarVisibility] = useState(false);
  const [selectedStartDate, setselectedStartDate] = React.useState<Date | null |undefined>(null)
  const [selectedEndDate, setselectedEndDate] = React.useState<Date| null | undefined >(null);

  const currentUser = useSelector((state: RootState) => {
    return state.user;
  });

  useEffect(() => {
    if (currentUser?.userRole) {
      getInitialPaymentDetails(activeTab);
    }
  }, [offsetVal]);
  useEffect(() => {
    if(filterApplied){
    getFilterPaymentDetails(activeTab);
    }
  }, [rowPageSize]);


  useEffect(() => {
    const resizeHandler = () => {
      const container = PaymentsApprovedTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 90}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    boxShadow: 'none'
  }));

  const previousPage = () => {
    let previousPageNo = page - 1;
    setPage(previousPageNo);
    setOffsetVal(offsetVal - rowPageSize);
  };
  const nextPage = () => {
    let nextPageNo = page + 1;
    setPage(nextPageNo);
    setOffsetVal(offsetVal + rowPageSize);
  };
  const rowPerPageChange = (e: any) => {
    let val = e.target.value;
    setRowPageSize(val);
    setPage(1);
    // reset to 0 when rows per page changes if page no might be more than 1
    setOffsetVal(0);
  };

  const commonParams = {
    limit: rowPageSize,
    offset: offsetVal,
  };

  function generatePaymentDetailsPayload<T extends { [key: string]: string | number | Date }>(
    intent: string,
    params: T
  ): paymentDetailsPayload {
    return {
      intent,
      state: {
        ...commonParams,
        ...params,
      },
    };
  }

  const handleTabClick = async (index: number) => {
    setselectedStartDate(null);
    setselectedEndDate(null);
    setFilterBarVisibility(false);
    setOperationCompleted(false);
    setFilterApplied(false); 
    setRowPageSize(30);
    if(tabsName[index].toLowerCase() !== activeTabName){
      setActiveTab(index);
      setDocArray([]);
      setPage(1);
      setOffsetVal(0);
      // setRowPageSize(10);
      setFilterParams({
        index,
        startDate: DEFAULT_FILTER_DATE.startDate,
        endDate: DEFAULT_FILTER_DATE.endDate,
      });
        getInitialPaymentDetails(index);
    }
  };  
  const getInitialPaymentDetails = async (index: number) => {
    
    try {
      let paymentDetailsPayload: paymentDetailsPayload = {
        intent: "",
        state: {
          ...commonParams,
        },
      };

      let params: { [key: string]: string | number | Date } = {};
      
      let intent = currentUser.userRole === 'doctorReferralExecutive' ? 'getPaymentDetailsForDre'
                  : currentUser.userRole === 'clusterManager' ? 'getPaymentDetailsForClusterManager'
                  : currentUser.userRole === 'manager' ? 'getPaymentDetailsForManager'
                  : currentUser.userRole === 'assistantGeneralManager' ? 'getPaymentDetailsForAssistantGeneralManagerByFilters'
                  : currentUser.userRole === 'salesHead' ? 'getPaymentDetailsForSalesHead'
                  : currentUser.userRole === 'finance' ? 'getPaymentDetailsForSalesHead'
                  : '';
      const today = new Date(); 
      const formattedToday = today.toISOString().split('T')[0];
      /* Payments set to be approved */
      if (index === 0) {
        params = {
          ...commonParams,
          managerApproval: 'assigned',
          // salesHeadApproval: 'assigned',
          transactionStatus: 'assigned',
        }
      } else if(index == 1){
        /* Payments approved */
        params = {
          ...commonParams,
          managerApproval: "approved",
          // salesHeadApproval: "approved",
          transactionStatus: "assigned",
        }
      } else if(index == 2){
        /* Processed Payments */
        params = {
          ...commonParams,
          managerApproval: "approved",
          // salesHeadApproval: "approved",
          transactionStatus: "completed",
        }
      } else if(index == 3){
        /* Rejected Payments */
        params = {
          ...commonParams,
          managerApproval: "rejected", // approved
          // salesHeadApproval: "assigned", // rejected
          transactionStatus: "assigned",
        }
      } else if(index == 4){
        /* Payments onHold */
        params = {
          ...commonParams,
          managerApproval: "onhold",
          // salesHeadApproval: "assigned",

          // managerApproval: "approved", // onhold
          // salesHeadApproval: "onhold", // assigned

          transactionStatus: "assigned",

        }
      }

      paymentDetailsPayload = generatePaymentDetailsPayload(intent, params);

      if (currentUser.userRole === 'doctorReferralExecutive') {
        paymentDetailsPayload.state.dreId = KeycloakService.getSub();
      } else if (currentUser.userRole === 'clusterManager') {
        paymentDetailsPayload.state.clusterManagerId = KeycloakService.getSub();
      } else if (currentUser.userRole === 'manager') {
        paymentDetailsPayload.state.managerId = KeycloakService.getSub();
      } else if (currentUser.userRole === 'assistantGeneralManager') {
        paymentDetailsPayload.state.agmId = KeycloakService.getSub();
      } else if (currentUser.userRole === 'salesHead') {
        paymentDetailsPayload.state.salesHeadId = KeycloakService.getSub();
      } else if (currentUser.userRole === 'finance') {
        paymentDetailsPayload.state.financeId = KeycloakService.getSub();
      }

      const paymentDetailsResp = await _axios.post(
          `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
          paymentDetailsPayload,
          {
              headers: {
                  version: VERSION,
          },
          }
      );

        if (paymentDetailsResp.status === 200) {
            const paymentDetailsRespData = paymentDetailsResp.data;

            if (
                paymentDetailsRespData &&
                paymentDetailsRespData.trade_partner_payment_details.length != 0
            ) {
                setDocArray(paymentDetailsRespData.trade_partner_payment_details);
                setTotalPages(
                Math.ceil(paymentDetailsRespData.trade_partner_payment_details_aggregate.aggregate
                .count / rowPageSize)
                );
                setTotalDataCount(paymentDetailsRespData?.trade_partner_payment_details_aggregate?.aggregate?.count);
                setOffsetVal((rowPageSize * page) - rowPageSize);
            } else {
                setDocArray([]);
                setTotalPages(1);
            }
        }
    } catch (error) {
      setMessage('getInitialPaymentDetails' + error);
    }
  }  
  const commonFilterParams = {
    limit: totalDataCount,
    offset: offsetVal,
    startDate: selectedStartDate?.toISOString(), 
    endDate: selectedEndDate?.toISOString(),
  };
  
  const getFilterPaymentDetails = async (index: number) => {
    
    try {
      let paymentFilterDetailsPayload: paymentDetailsPayload = {
        intent: "",
        state: {
          ...commonFilterParams,
        },
      };

      let params: { [key: string]: string | number | Date } = {};
      
      let intent = currentUser.userRole === 'doctorReferralExecutive' ? 'getPaymentDetailsForDre'
                  : currentUser.userRole === 'manager' ? 'getLatestUpdatedPaymentDetailsForManager'
                  : currentUser.userRole === 'salesHead' ? 'getUpdatedPaymentDetailsForSalesHeadOrFinance'
                  : '';
      const today = new Date(); 
      const formattedToday = today.toISOString().split('T')[0];
      if (index === 0) {
        params = {
          ...commonParams,
          managerApproval: 'assigned',
          // salesHeadApproval: 'assigned',
          transactionStatus: 'assigned',
          startDate:selectedStartDate || "2023-01-01",
          endDate: selectedEndDate ||  formattedToday,
        }
      } else if(index == 1){
        /* Payments approved */
        params = {
          ...commonFilterParams,
          managerApproval: "approved",
          // salesHeadApproval: "approved",
          transactionStatus: "assigned",
          startDate:selectedStartDate || "2023-01-01",
          endDate: selectedEndDate ||  formattedToday,
        }
      } else if(index == 2){
        /* Processed Payments */
        params = {
          ...commonFilterParams,
          managerApproval: "approved",
          // salesHeadApproval: "approved",
          transactionStatus: "completed",
          startDate:selectedStartDate || "2023-01-01",
          endDate: selectedEndDate ||  formattedToday,
        }
      } else if(index == 3){
        /* Rejected Payments */
        params = {
          ...commonFilterParams,
          managerApproval: "rejected", // approved
          // salesHeadApproval: "assigned", // rejected
          transactionStatus: "assigned",
          startDate:selectedStartDate || "2023-01-01",
          endDate: selectedEndDate ||  formattedToday,
        }
      } else if(index == 4){
        /* Payments onHold */
        params = {
          ...commonFilterParams,
          managerApproval: "onhold",
          // salesHeadApproval: "assigned",

          // managerApproval: "approved", // onhold
          // salesHeadApproval: "onhold", // assigned

          transactionStatus: "assigned",
          startDate:selectedStartDate || "2023-01-01",
          endDate: selectedEndDate ||  formattedToday,
        }
      }

      paymentFilterDetailsPayload = generatePaymentDetailsPayload(intent, params);

     if (currentUser.userRole === 'manager') {
        paymentFilterDetailsPayload.state.managerId = KeycloakService.getSub();
      } 

      const paymentDetailsResp = await _axios.post(
          `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
          paymentFilterDetailsPayload,
          {
              headers: {
                  version: VERSION,
          },
          }
      );

        if (paymentDetailsResp.status === 200) {
            const paymentDetailsRespData = paymentDetailsResp.data;

            if (
                paymentDetailsRespData &&
                paymentDetailsRespData.trade_partner_payment_details.length != 0
            ) {
                setDocArray(paymentDetailsRespData.trade_partner_payment_details);
                setTotalPages(
                Math.ceil(paymentDetailsRespData.trade_partner_payment_details_aggregate.aggregate
                .count / rowPageSize)
                );
                setTotalDataCount(paymentDetailsRespData?.trade_partner_payment_details_aggregate?.aggregate?.count);
                setOffsetVal((rowPageSize * page) - rowPageSize);
                setRowPageSize(totalDataCount);          
                setFilterApplied(true);                      
            } else {
                setDocArray([]);
                setTotalPages(1);
            }
        }
    } catch (error) {
      setMessage('getInitialPaymentDetails' + error);
    }
  }  
  
  const setDateFormat = (date: String) => {
      if(date != null && date != ""){
          let dateWithOutString = date.replace(/"/gi, "");
          dateWithOutString = moment(dateWithOutString).format('DD-MMM-YY , hh:mm')
          return dateWithOutString;
      } else {
          return '-';
      }    
  }

  const handleClickDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const setPatientStatusChange = (event: SelectChangeEvent<string>) => {
    setPaymentStatus(event.target.value);
    if(event.target.value == "rejected" || event.target.value == "onhold"){
      setReasonValue("");
      setPaymentRejectedOptions(true);
    } else {
      setPaymentRejectedOptions(false);
    }
  };

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReasonValue(event.target.value);
  };

  const setPaymentStatusValue = (activeTabIndex: number) => {
    let statusStr = '';
    if(activeTabIndex == 0 && activeTabName == 'payments to be Approved'){
      statusStr = 'Assigned';
    } else if(activeTabIndex == 1 && activeTabName == 'payments approved'){
      statusStr = 'Approved';
    } else if(activeTabIndex == 2 && activeTabName == 'processed payments'){
      statusStr = 'Completed';
    } else if(activeTabIndex == 3 && activeTabName == 'rejected payments'){
      statusStr = 'Rejected';
    } else if(activeTabIndex == 4 && activeTabName == 'payments onhold'){
      statusStr = 'onHold';
    }
    return statusStr;
  };

  const handlePaymentStatusDialogSubmit = async () => {
    if(paymentStatus == ""){
      setMessage('Please select any payment status');
      setOpen(true);
      return false;
    }
    if(paymentStatus == "rejected"|| paymentStatus == "onhold"){
      if (reasonValue == ""){
        setMessage('Reason is required ');
        setOpen(true);
        return false;
      }
    }

    let submitPaymentStatuspayloadObj:paymentStatusSubmitPayload = {
      intent: "updatePaymentDetailsByVisitId",
      state: {
          userType: currentUser.userRole,
          id: selPatient,
      }
    }

    if (currentUser.userRole === 'manager') {
      submitPaymentStatuspayloadObj.state.managerApproval = paymentStatus;
      submitPaymentStatuspayloadObj.state.managerActionOn = moment().format('YYYY-MM-DD');
      submitPaymentStatuspayloadObj.state.managerName = currentUser.userFullName;
      if(paymentStatus == "rejected"|| paymentStatus == "onhold"){
        submitPaymentStatuspayloadObj.state.managerRejectionReason = reasonValue;
      }
    } else if (currentUser.userRole === 'assistantGeneralManager') {
      submitPaymentStatuspayloadObj.state.assistantGeneralManagerApproval = paymentStatus;
      submitPaymentStatuspayloadObj.state.assistantGeneralManagerActionOn = moment().format('YYYY-MM-DD');
      submitPaymentStatuspayloadObj.state.assistantGeneralManagerName = localStorage.getItem("userName");
      submitPaymentStatuspayloadObj.state.higherApprovalRole = currentUser.userRole;
      if(paymentStatus == "rejected"|| paymentStatus == "onhold"){
        submitPaymentStatuspayloadObj.state.assistantGeneralManagerRejectionReason = reasonValue;
      }
    } else if (currentUser.userRole === 'salesHead') {
      submitPaymentStatuspayloadObj.state.salesHeadApproval = paymentStatus;
      submitPaymentStatuspayloadObj.state.salesHeadActionOn = moment().format('YYYY-MM-DD');
      submitPaymentStatuspayloadObj.state.salesHeadName = localStorage.getItem("userName");
      submitPaymentStatuspayloadObj.state.higherApprovalRole = currentUser.userRole;
      if(paymentStatus == "rejected"|| paymentStatus == "onhold"){
        submitPaymentStatuspayloadObj.state.salesHeadRejectionReason = reasonValue;
      }
    } else if (currentUser.userRole === 'finance') {
      submitPaymentStatuspayloadObj.state.financeApproval = paymentStatus;
      submitPaymentStatuspayloadObj.state.financeActionOn = moment().format('YYYY-MM-DD');
      submitPaymentStatuspayloadObj.state.financeUserName = localStorage.getItem("userName");
    }

    // Define a mapping object for userRole to properties
    // const roleToPropertiesMap: { [key: string]: string } = {
    //   manager: "manager",
    //   assistantGeneralManager: "assistantGeneralManager",
    //   salesHead: "salesHead",
    //   finance: "finance",
    // };

    // // Check if userRoleVal is in the mapping
    // if (roleToPropertiesMap.hasOwnProperty(userRoleVal)) {
    //   const roleProperty = roleToPropertiesMap[userRoleVal];
    //   submitPaymentStatuspayloadObj.state[`${roleProperty}Approval`] = paymentStatus;
    //   submitPaymentStatuspayloadObj.state[`${roleProperty}ActionOn`] = moment().format('YYYY-MM-DD');
    //   submitPaymentStatuspayloadObj.state[`${roleProperty}Name`] = localStorage.getItem("userName");
    // }
    try {
      const submitPaymentStatusResp = await _axios.post(
        `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
        submitPaymentStatuspayloadObj,
        {
          headers: {
            version: VERSION,
          },
        }
      );

      if(submitPaymentStatusResp?.status == 200 && submitPaymentStatusResp?.data?.update_trade_partner_payment_details?.returning?.length != 0){
        getInitialPaymentDetails(activeTab);
        setMessage('Payment status has been updated successfully');
        setOpen(true);
        setOpenDialog(false);
      } else {
        setMessage('Unable to update the Payment status.');
        setOpen(true);
        return false;
      }
    }
    catch (error:any) {
      setMessage('Unable to update the Payment status. Reason: ' + error?.response?.data?.error);
      setOpen(true);
      return false;
    }    
  };

  const tabMenus = [];
  if (activeTab === 0) {
    tabMenus.push(
    [
      { value: "approved", label: "Approve" },
      { value: "rejected", label: "Reject" },
      { value: "onhold", label: "OnHold" },
    ]
    );
  } else if (activeTab === 1) {
    tabMenus.push(
    [
      // { value: "inprocess", label: "InProcess" },
      { value: "completed", label: "Completed" },
    ]
    );
  } else if (activeTab === 2 || activeTab === 3) {
    tabMenus.push([]);
  } else if (activeTab === 4) {
    tabMenus.push(
    [
      { value: "approved", label: "Approve" },
      { value: "rejected", label: "Reject" },
    ]
    );
  }
  const activeTabMenus = tabMenus[0];
  const formatNumber = (number :number) => {
    return number.toLocaleString();
  };

  const excelArray = docArray.map(
    ({
      total_amount,
      net_amount,
      updated_at,
      manager_name,
      manager_rejection_reason ,
      patient_thop_map: {
        visit_type,
        patient_uhid,
        admitted_on,
        arrived_on,
        discharged_on,
        consultation_completed_on,
        referred_patient_detail: {
          patient_name,
          dre_trade_partner_map: { medunited_user },
        },
      },
    }) => {
      const trade_partner_bank_account_details =
        medunited_user?.trade_partner_bank_account_details || [];
      const firstBankDetails = trade_partner_bank_account_details[0] || {};
      const {
        account_holder_name = "",
        bank_account_number = "",
        bank_ifsc_code = "",
        bank_name = "",
      } = firstBankDetails;
      const formattedTotalAmount = total_amount ?? 0;
      const formattedNetAmount = net_amount ?? 0;
      const formattedUpdatedDate = updated_at
        ? new Date(updated_at).toISOString().split("T")[0]
        : "";
      const formattedBankDetails =
        trade_partner_bank_account_details.length > 0
          ? {
              account_holder_name,
              bank_account_number,
              bank_ifsc_code,
              bank_name,
            }
          : {
              account_holder_name: "-",
              bank_account_number: "-",
              bank_ifsc_code: "-",
              bank_name: "-",
            };
      const status = setPaymentStatusValue(activeTab);
      const dateForVisitType = visit_type === "ipd" ? (admitted_on || arrived_on) : arrived_on;
      const dateForDischarge = visit_type === "ipd" ? (discharged_on || consultation_completed_on) : consultation_completed_on;
  
      const concatenatedAdmittedDates = dateForVisitType ? new Date(dateForVisitType).toISOString().split("T")[0] : "";
      const concatenatedDischargedDates = dateForDischarge ? new Date(dateForDischarge).toISOString().split("T")[0] : "";
  
      return {
        patient_uhid,
        first_name: medunited_user?.first_name || "",
        mobile_number: medunited_user?.mobile_number || "",
        patient_name,
        visit_type,
        AdmittedDate_Column: concatenatedAdmittedDates,
        DischargedDate_Column: concatenatedDischargedDates,
        updated_at: formattedUpdatedDate,
        total_amount: formattedTotalAmount,
        net_amount: formattedNetAmount,
        status,
       ...(activeTab === 1 || activeTab === 2 ? formattedBankDetails : {}),
        ...(activeTab === 4 || activeTab === 3 ? { manager_name, manager_rejection_reason } : {}),
      };
    }
  );
  const dateHeader = ["Approved_Date", "Processed_Date", "Rejected_Date", "OnHold_Date"][activeTab - 1] || "Approved_Date";
  let additionalHeaders: string[] = [];
if (activeTab === 3) {
    additionalHeaders = ["onHold", "comments"];
} else if (activeTab === 4) {
    additionalHeaders = ["rejected By", "comments"];
}
let requiredbankDetailsOnlyfor :string[]=[];
if(activeTab === 1 || activeTab === 2){
  requiredbankDetailsOnlyfor = ["Account Holder Name", "Bank Account Number","Bank IFSC Code","Bank Name"];
}
  const headers = [
    "Patient ID",
    "Referrer Name",
    "Referrer Mobile",
    "Patient Name",
    "Visit Type",
    "Admission Date",
    "Date of Discharge",
    dateHeader,
    "Total Amount",
    "Net Amount",
    "Status",
    ...requiredbankDetailsOnlyfor,
    ...additionalHeaders
  ];
function handleDownload() {
  const csvContent = [headers.join(",")].concat(excelArray.map(item => Object.values(item).join(','))).join('\n');

  const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8," });
  const blobURL = window.URL.createObjectURL(blob);
  const anchor = document.createElement("a");
  anchor.download = `${tabsName[activeTab]}.csv`;
  anchor.href = blobURL;
  anchor.click();
  setTimeout(() => {
    URL.revokeObjectURL(blobURL);
  }, 100);
}
// need
// function handleDownload() {
//   const doc = new jsPDF();
//   const tableData = excelArray.map(item => Object.values(item));
//   const tableColumns = headers.map(header => ([ header ]));
//   const totalColumns = headers.length;
//   const columnWidth = 100 / totalColumns; // Each column occupies 100/totalColumns percent of the width

//   const columnWidths = Array(totalColumns).fill(columnWidth.toString() + "%");
//   doc.autoTable({
//     head: [tableColumns],
//     body: tableData,
//     columnWidth: columnWidths,
//     styles: {
//       fontSize: 10, // Adjust the font size as needed
//     },
//   });
//   doc.save(`${tabsName[activeTab]}.pdf`);
// }
const CheckhandleDownload = () => {
  if (!selectedStartDate || !selectedEndDate) {
    setOpen(true);
    setMessage(selectDate);
  } else {
    handleDownload(); 
  }
};

  
  const toggleFilterBar = () => {
    setFilterBarVisibility(!isFilterBarVisible);
  };
  const handleStartDateChange = (newValue: Date | null | undefined) => {
    const newDate = newValue !== null ? newValue : undefined;
    setselectedStartDate(newDate);
  };
  const handleEndDateChange = (newValue: Date | null | undefined) => {
    if (newValue !== undefined && newValue !== null) {
      let selectedDate: Date = new Date(newValue);
  
      const isToday = moment(selectedDate).isSame(moment(), 'day');
  
      if (isToday) {
        selectedDate.setHours(new Date().getHours(), new Date().getMinutes(), new Date().getSeconds());
      } else {
        selectedDate.setHours(23, 59, 0, 0);
      }
  
      setselectedEndDate(selectedDate);
    } else {
    }
  };
  const [filterParams, setFilterParams] = useState({
    index: 0,
    startDate:selectedStartDate,
    endDate:selectedEndDate
  });
  const [operationCompleted, setOperationCompleted] = useState(false);
  const [clearfilterCall,setClearFilterCall]=useState(false);

  const handleSubmitFilter = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
  
    if (!selectedStartDate || !selectedEndDate) {
      setMessage("Please select both start and end dates");
      setOpen(true);
      return; 
    }
  
    try {
      
      await getFilterPaymentDetails(filterParams.index);
      setOperationCompleted(true);
    } catch (error) {
      console.error("Error fetching initial payment details:", error);
    }
  
    setFilterBarVisibility(false);
  }
  
  const handleFilterCancel = () => {      
    setFilterBarVisibility(false);
  };  
  const DEFAULT_FILTER_DATE = {
    startDate: null,
    endDate: null,
  };
  useEffect(() => {
    if (clearfilterCall) {
        getInitialPaymentDetails(filterParams.index);
    }
}, [filterParams, clearfilterCall]);

  const clearAllFilters = async () => {
    try {
      setselectedEndDate(DEFAULT_FILTER_DATE.endDate);
      setselectedStartDate(DEFAULT_FILTER_DATE.startDate);
      setFilterParams({
        index: activeTab,
        startDate:null,
        endDate:null,
      });
      setOperationCompleted(false);
      setClearFilterCall(true);
      setFilterApplied(false); 
      setRowPageSize(30)
    } catch (error) {
      console.error("Error clearing filters:", error);
    }
  };
  return (
    <>
      <Sidemenu />
      <div className="layoutParent">
        <p className="layoutParentP">Payment Details</p>
        <Snackbar
          ContentProps={{
            "aria-describedby": "message-id",
            className: classes.snackbarStyleViaContentProps
          }}
          open={open}
          autoHideDuration={3000}
          message={message}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
        
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Status</DialogTitle>
          <DialogContent>

            <Grid container className="mt2" spacing={3}>
                <Grid item xs={5} className="text-right">
                  <Item style={{ paddingTop: '1.1rem' }}>Payment Status</Item>
                </Grid>
                <Grid item xs={7} className="text-left">
                  <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={paymentStatus}
                      displayEmpty
                      onChange={setPatientStatusChange}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {activeTabMenus.map((menuitem)=>(
                        <MenuItem key={menuitem.value} value={menuitem.value}>{menuitem.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              
            {/* {activeTab === 0 && paymentStatus == "reject" ? ( */}
            {paymentRejectedOptions ? (
                <Grid container className="mt2" spacing={3}>
                  <Grid item xs={5} className="text-right">
                    <Item style={{ paddingTop: '1.1rem' }}>Reason</Item>
                  </Grid>
                  <Grid item xs={7} className="text-left">
                    <TextField
                        label="Reason"
                        multiline
                        rows={4}
                        value={reasonValue}
                        onChange={handleReasonChange}
                      />
                  </Grid>
                </Grid>
              ) : null}  
          </DialogContent>
          <DialogActions>
            <div className="cancel-submit-btn">
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handlePaymentStatusDialogSubmit}>Submit</Button>
            </div>
          </DialogActions>
        </Dialog>

        <div
          className="layoutContent pagelayoutPadding">
          <div className='conflicts_tabs'>
            <ul>
              {tabsName.map((d, i) => (
                <li
                  key={i}
                  className={`${activeTab === i ? 'activeTab' : ''}`}
                  onClick={() => {
                    handleTabClick(i);
                    setActiveTabName(tabsName[i].toLowerCase());
                  }}
                >
                  {d}
                </li>
                ))
              }
            </ul>
              <div className="filter_download" >
                {(activeTab >= 1 && activeTab <= 4) && (['manager','assistantGeneralManager','salesHead'].includes(currentUser.userRole)) && (
                 <div className="filter_download_buttons">
                 <div className="filter_icon_block">
                  < div onClick={toggleFilterBar}>
                      <img
                        className="filterIcon"
                        src={FilterIcon}
                        alt="Filter Icon"
                      />
                      <label className="filter_label"  >{date}</label>
                      </div>
                      <div className="filter_list">
                      {isFilterBarVisible && (
                            <>
                <div className="filter_status_block" >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                          label="Start Date"
                          value={selectedStartDate || null}
                          disableFuture
                          inputFormat="dd/MM/yyyy"
                          onChange={(newValue) => {
                              handleStartDateChange(newValue);
                          }}
                          renderInput={(params) => (
                              <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />
                          )}
                      />
                  </LocalizationProvider>
              </div>

                              <div className="filter_status_block" >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DatePicker
                                    label="To Date"
                                    value={selectedEndDate || null}
                                    disableFuture
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(newValue) => {
                                      handleEndDateChange(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />
                                    )}
                                  />
                                </LocalizationProvider>
                              </div>
                              <div className="filter_submit_cancel_buttons">
                              <Button className="download_button" style={{ marginRight: '10px' }}  onClick={handleSubmitFilter}>Submit</Button>
                              <Button  className="download_button" onClick={handleFilterCancel}>Cancel</Button>
                              </div>
                            </>
                          )}
                    </div>
                    </div>
                  </div>
                )}
                
                <div className="downloadButtondiv" style={{left:"9rem"}}>
                  {(activeTab >= 1 && activeTab <= 4) && (['manager','assistantGeneralManager','salesHead'].includes(currentUser.userRole)) && (
                    <Button
                      className="download_button"
                      onClick={CheckhandleDownload}
                    >
                      {downlaod}
                    </Button>
                  )}
                </div>

                </div>
                   
        </div>
        {operationCompleted && selectedStartDate && (
        <div className="applied_filter_item" style={{ backgroundColor: "white" }}>
          <div>
            <button className="applied_filter_item" onClick={clearAllFilters}>
              <img className="close_svg_icon" src={closeIcon} style={{ cursor: "pointer" }} />
              Start Date: {selectedStartDate?.toLocaleDateString() ?? 'N/A'},
              End Date: {selectedEndDate ? selectedEndDate.toLocaleDateString() : new Date().toLocaleDateString()}
            </button>
          </div>
        </div>
      )}
        <div className="tableWithHadle" ref={PaymentsApprovedTableContainerRef}>
            <table className="data-grid-table">
              <thead style={{ position: 'sticky', top: '0px' }}>
                  <tr>
                      <th>Sl No</th>
                      {tableHead_paymentDetails.map((d, i) => <th key={i}>{d}</th>)}
                      {activeTab == 1 ? <><th>Approved by</th></> : null}
                      {activeTab == 3 ? <><th>Rejected by</th></> : null}
                      {activeTab == 4 ? <><th>OnHold by</th></> : null}
                      {(activeTab == 3 || activeTab == 4 )? <th className='text-center-notimportant'>Comments</th> : null}
                  </tr>
                </thead>
                <tbody>
                  {docArray.length > 0 ? 
                    docArray.map((d, i) => (
                      <tr key={i}>
                        <td>{startingIndex +i }</td>
                        <td>{d?.patient_thop_map?.patient_uhid ? d?.patient_thop_map?.patient_uhid : "-"}</td>
                        <td>
                          {d?.patient_thop_map?.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.first_name +
                            " " +
                            d?.patient_thop_map?.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.last_name}
                        </td>
                        <td>
                          {d?.patient_thop_map?.referred_patient_detail?.dre_trade_partner_map?.medunited_user?.mobile_number}
                        </td>                       
                        <td>{d?.patient_thop_map?.referred_patient_detail?.patient_name}</td>
                        <td>{d?.patient_thop_map?.visit_type}</td>
                        <td> {setDateFormat(d?.patient_thop_map?.visit_type === 'ipd'? d?.patient_thop_map?.admitted_on: d?.patient_thop_map?.arrived_on)}</td>
                        <td> {setDateFormat(d?.patient_thop_map?.visit_type === 'ipd'? d?.patient_thop_map?.discharged_on: d?.patient_thop_map?.consultation_completed_on)}</td>
                        <td>{setDateFormat(d?.updated_at)}</td>
                        <td>{rupee}&nbsp;{d?.total_amount ? formatNumber(d?.total_amount ): "0"}</td>
                        <td>{rupee}&nbsp;{d?.net_amount ? formatNumber(d?.net_amount) : "0"}</td>
                        <td>
                          {setPaymentStatusValue(activeTab)}&nbsp;
                          {/* only manager should have the access to change the payment status */}
                          {((currentUser.userRole === 'manager'  && (activeTab === 0 || activeTab === 4)) ?
                            <>
                              <img className="" src={editIcon} style={{cursor: "pointer"}}
                                  onClick={() => {
                                  setSelPatient(+d?.id)
                                  setSelPatientVisitId(d?.fk_visit_id)
                                  handleClickDialogOpen();
                                  
                                  setPaymentStatus(""); // Set the payment status to an empty string
                                  setReasonValue(""); // Optionally reset the reason value as well
                                  setPaymentRejectedOptions(false);
                                }} />
                            </>
                          : null)}
                          
                        </td>

                        {activeTab == 1 ?
                          <>
                            <td>{d?.manager_name ? d?.manager_name : "-"}</td>
                          </>
                        : null
                        }

                        {(activeTab == 3 || activeTab == 4) ?
                          <>
                            <td>{d.manager_name ? d.manager_name : "-"}</td>
                            <td className='text-center'>{d.manager_rejection_reason ? d.manager_rejection_reason : "-"}</td>
                          </>
                        : null
                        }
                    </tr>
                  )):
                <tr>
                  <td className="text-center" colSpan={9}>No Data</td>
                </tr>
            }
            </tbody>
            </table>
          </div>
          <Pagination
            pageNo={page}
            totalPages={totalPages}
            previousPage={previousPage}
            nextPage={nextPage}
            rowPageSize={rowPageSize}
            totalDataCount={totalDataCount}
            rowPerPageChange={rowPerPageChange} />
        </div>
      </div>
    </>
  );
}

export default PaymentDetails;
