import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import Pagination from "../../components/pagination";
import HttpService from "../../services/HttpService";
import { GRADIENTIMAGECOLOR, PATIENT_DATA_HEADER_REFERRALLOST } from "../../utils/constants";
import KeycloakService from "../../services/KeycloakService";
import { VERSION } from "../../constants";
import { PatientDataManager, patientsInfoPayload } from "../../types/Module_Type";
import { Snackbar } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const _axios = HttpService.getAxiosClient();

const useStyles = makeStyles(() => ({
    snackbarStyleViaContentProps: {
      backgroundImage: GRADIENTIMAGECOLOR,
    },
}));

const PatientInfoReferralLost = ({
    selectedVisitType,
    filteredData,
    filterApplied
  } : {
    selectedVisitType: string;
    filteredData: PatientDataManager;
    filterApplied: boolean
  }) => {
    const classes = useStyles();
    const [patientArrayM, setPatientArrayM] = React.useState<PatientDataManager[]>([]);
    const [page, setPage] = React.useState(1);
    const [rowPageSize, setRowPageSize] = React.useState(30);
    const [totalPages, setTotalPages] = React.useState(Number);
    const [offsetVal, setOffsetVal] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [totalDataCount,setTotalDataCount]=React.useState(0);

    const startingIndex = page === 1 ? 1 : (page - 1) * rowPageSize +1;

    const currentUser = useSelector((state: RootState) => {
        return state.user;
    });

    useEffect(() => {
        getInitialPaitentList();
    }, [page, rowPageSize, selectedVisitType, filteredData, filterApplied]);

    const getInitialPaitentList = async () => {
        try {
            setPatientArrayM([]);
            if(filterApplied){
                if(filteredData && filteredData?.referred_patient_details?.length > 0){
                    setPatientArrayM(filteredData?.referred_patient_details);
                    setTotalPages(Math.ceil(filteredData?.referred_patient_details_aggregate?.aggregate?.count / rowPageSize));
                    setTotalDataCount(filteredData?.referred_patient_details_aggregate?.aggregate?.count);
                    setOffsetVal((rowPageSize * page) - rowPageSize);
                } else {
                    // Render message indicating no data found for the applied filter
                    setPatientArrayM([]);
                    setTotalPages(1);
                    setTotalDataCount(0);
                }
            } else {
                let intentStr = currentUser.userRole === 'doctorReferralExecutive' ? 'getRefferedAndReferralLostPatientsForDreByDate'
                  : currentUser.userRole === 'clusterManager' ? 'getAllReferredAndReferralLostPatientForClusterManager'
                  : currentUser.userRole === 'manager' ? 'getAllReferredAndReferralLostPatientsForManager'
                  : currentUser.userRole === 'assistantGeneralManager' ? 'getAllReferredAndReferralLostPatientsForAssistantGeneralManager'
                  : currentUser.userRole === 'salesHead' ? 'getAllReferredAndReferralLostPatientForSalesHead'
                  : '';

                const referralLostPatientsPayload:patientsInfoPayload = {
                    intent: intentStr,
                    state: {
                        patientStatus: "referralLost",
                        limit: rowPageSize,
                        offset: offsetVal,
                        visitType: selectedVisitType,
                        // startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                        /* displaying start date as 1st january of current year i.e. example 2023-01-01 */
                        startDate: moment().startOf('year').format('YYYY-MM-DD')+"T00:00:00.0000",
                        endDate: moment().format('YYYY-MM-DD')+"T23:59:59.9999",
                    },
                }

                if (currentUser.userRole === 'doctorReferralExecutive') {
                    referralLostPatientsPayload.state.dreId = KeycloakService.getSub();
                } else if (currentUser.userRole === 'clusterManager') {
                    referralLostPatientsPayload.state.clusterManagerId = KeycloakService.getSub();
                } else if (currentUser.userRole === 'manager') {
                    referralLostPatientsPayload.state.managerId = KeycloakService.getSub();
                } else if (currentUser.userRole === 'assistantGeneralManager') {
                    referralLostPatientsPayload.state.agmId = KeycloakService.getSub();
                } else if (currentUser.userRole === 'salesHead') {
                    referralLostPatientsPayload.state.salesHeadId = KeycloakService.getSub();
                }

                const referralLostPatientsResp = await _axios.post(
                `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,referralLostPatientsPayload,
                    {
                        headers: {
                        version: VERSION,
                        },
                    }
                );
                
                const referralLostPatientsRespData = referralLostPatientsResp.data;

                if (
                    referralLostPatientsRespData &&
                    referralLostPatientsRespData?.referred_patient_details?.length != 0
                ) {
                    const sortedRefLostPatientsArray = referralLostPatientsRespData?.referred_patient_details?.sort((a: { updated_at: string }, b: { updated_at: string }) => {
                        const dateA = new Date(a.updated_at);
                        const dateB = new Date(b.updated_at);
                      
                        return dateB.getTime() - dateA.getTime();
                    });

                    setPatientArrayM(sortedRefLostPatientsArray);
                    setTotalPages(Math.ceil(referralLostPatientsRespData?.referred_patient_details_aggregate?.aggregate?.count / rowPageSize));
                    setTotalDataCount(referralLostPatientsRespData?.referred_patient_details_aggregate?.aggregate?.count);
                    setOffsetVal((rowPageSize * page) - rowPageSize);
                } else {
                    setPatientArrayM([]);
                    setTotalPages(1);
                }
                
                if (referralLostPatientsRespData?.referred_patient_details_aggregate?.aggregate?.count === 0) {
                    setPatientArrayM([]);
                }
            }
        }
        catch (e: any) {
            setMessage('getInitialPaitentList' + e.message);
            setOpen(true);
        }
    }

    const previousPage = () => {
        let previousPageNo = page - 1;
        setPage(previousPageNo);
        setOffsetVal(offsetVal - rowPageSize);
    }
    const nextPage = () => {
        let nextPageNo = page + 1;
        setPage(nextPageNo);
        setOffsetVal(offsetVal + rowPageSize);
    }
    const rowPerPageChange = (e: any) => {
        let val = e.target.value;
        setRowPageSize(val);
        setPage(1);
        // reset to 0 when rows per page changes if page no might be more than 1
        setOffsetVal(0);
    }

    const setDateFormat = (date:String) => {
        if(date != null && date != ""){
            let dateWithOutString = date.replace(/"/gi, "");
            dateWithOutString = moment(dateWithOutString).format('DD-MMM-YYYY / hh:mm')
            return dateWithOutString;
        } else {
            return '-';
        }
    }

    const referralLostPatientsTableContainerRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        const resizeHandler = () => {
            const container = referralLostPatientsTableContainerRef.current
            if (container) {
            const availableHeight: any =
                window.innerHeight - container.getBoundingClientRect().top
            container.style.maxHeight = `${availableHeight - 90}px`
            }
        }

        window.addEventListener('resize', resizeHandler)
        resizeHandler()

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    const [showPhoneNumber, setShowPhoneNumber] = React.useState(false);
    const [clickedPhoneNumber, setClickedPhoneNumber] = React.useState<string | null>(null);
      const maskPhoneNumber = (phoneNumber: string) => {
          const lastThreeDigits = phoneNumber.slice(-3);
          const maskedNumber = '*'.repeat(Math.max(0, phoneNumber.length - 3)) + lastThreeDigits;
          return maskedNumber;
        };
        const handleTogglePhoneNumber = (phoneNumber: string) => {
          setClickedPhoneNumber(showPhoneNumber ? null : phoneNumber);
        };

    return (
        <>
            <div className='tableWithHadle' ref={referralLostPatientsTableContainerRef}>
                <Snackbar
                    ContentProps={{
                    "aria-describedby": "message-id",
                    className: classes.snackbarStyleViaContentProps
                    }}
                    open={open}
                    autoHideDuration={3000}
                    message={message}
                    onClose={() => setOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                />
                <table className='data-grid-table'>
                    <thead className='tableList_stickyHeader'>
                        <tr> {PATIENT_DATA_HEADER_REFERRALLOST.map((d, i) => <th key={i}><span></span>{d}</th>)} </tr>
                    </thead>
                    <tbody>
                        {patientArrayM.length > 0 ?
                            patientArrayM.map((d, i) => (
                                <tr key={i}>
                                    <td>{startingIndex +i }</td>
                                    <td>
                                    {d?.dre_trade_partner_map?.medunited_user?.first_name +
                                        " " +
                                        d?.dre_trade_partner_map?.medunited_user?.last_name}
                                    </td>
                                    <td>{d.dre_trade_partner_map?.medunited_user?.mobile_number}</td>
                                    <td>{d.patient_name}</td>
                                    <td>
                                        <span onClick={() => handleTogglePhoneNumber(d?.mobile_number)}>
                                        {clickedPhoneNumber === d?.mobile_number? d.mobile_number: maskPhoneNumber(d?.mobile_number)}
                                        </span>
                                    </td>                                    
                                    <td>{d.patient_status}</td>
                                    <td>{setDateFormat(d.created_at)}</td>
                                    <td>{setDateFormat(d.updated_at)}</td>
                                    <td>{d.patient_reason}</td>
                                </tr>
                            )) :
                            <tr>
                                <td className='text-center' colSpan={9}>No Patient Details available</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <Pagination
                pageNo={page}
                totalPages={totalPages}
                previousPage={previousPage}
                nextPage={nextPage}
                rowPageSize={rowPageSize}
                totalDataCount={totalDataCount}
                rowPerPageChange={rowPerPageChange}
            />
        </>
    );
}
export default PatientInfoReferralLost;