import React, { useState } from 'react';
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import UserProfileForm from "./addOthers";
import Upload from "./uploadUsers";
import "./UserProfileForm.css";

const AddUser = () => {
  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="AddUserContainer">
      <Select
        value={selectedOption}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Select option' }}
        style={{ height: "30px" }}
      >
        <MenuItem value="" disabled>
          Select Type
        </MenuItem>
        <MenuItem value="UserProfileForm">Add Other</MenuItem>
        <MenuItem value="Upload">Add TradePartner</MenuItem>
      </Select>

      <div className="scrollable-container">
        {selectedOption === "UserProfileForm" && <UserProfileForm />}
        {selectedOption === "Upload" && <Upload />}
      </div>
    </div>
  );
};

export default AddUser;
