import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import Sidemenu from "../../components/Sidemenu";
import AddUser from "./addUsers";
import HttpService from "../../services/HttpService";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import { errorMessage, GRADIENTIMAGECOLOR, passwordMismatch, userDataNull, tableHead, tableHeadForDownloads, userScreen_Head, noData, exportData, success, submit, cancel, addRole, submitted, dataNotDefined } from "../../utils/constants";
import editIcon from "../../assets/images/edit.svg";
import Pagination from "../../components/pagination";
import FilterIcon from "../../assets/images/filter.svg";
import {
  MedunitedUser,
  MedunitedUsersResponse,
  UpdateUserPayload,
  UserData,
} from "../../types/Module_Type";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { VERSION } from "../../constants";
import closeIcon from '../../assets/images/close.svg';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    borderCollapse: "collapse",
  },
  cell: {
    border: "1px solid #ddd",
    padding: "6px",
    textAlign: "left",
    fontSize: "0.675rem !important ",
  },
  celllable: {
    color: "black",
  },
  cellheader: {
    paddingLeft:"3px",
    textAlign: "left",
    position: "sticky",
    top: 0,
    backgroundColor: "#f2f2f2",
    zIndex: 1,
    paddingRight:"3px",
  },
  cellWithBorder: {
    borderRight: "1px solid #ddd",
  },
  snackbarStyleViaContentProps: {
    backgroundImage: GRADIENTIMAGECOLOR,
  },
  centerSubmitButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  editcell: {
    cursor: "pointer",
  },
  mobileNumberCell: {
    width: "150px !important",
  },
  mobileNumberInput: {
    width: "100%",
  },
  accessTime: {
    fontSize: "0.7rem",
  },
  cellwidth:{
    whiteSpace:"nowrap",
  },
  table_Tr:{
    height:"6vh",
  },
  headerCell:{
    fontSize: "12px",
    color: "black",
    textAlign: "center",
    whiteSpace:"nowrap",
  }
});
const UserScreen = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [rowPageSize, setRowPageSize] = React.useState(30);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [offsetVal, setOffsetVal] = React.useState(0);
  const [activeTab, setActiveTab] = React.useState(0);
  const [activeTabName, setActiveTabName] = React.useState("Users Data");
  const tabnames = ["Users Data", "Add Users"];
  const [selectedUserType, setSelectedUserType] = React.useState(" ");
  const [userdataArray, setUserDataArray] =useState<MedunitedUsersResponse | null>(null);
  const [filteredDataNew, setFilteredDataNew] = useState<MedunitedUser[]>([]);
  const [totalDataCount, setTotalDataCount] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(Number);
  const [message, setMessage] = React.useState("");
  const [OpenDialog, setOpenDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [editUserData, setEditUserData] = React.useState<MedunitedUser | null>(null);
  const [loadingSubmit, setLoadingSubmit] =React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [userIdToReset, setUserIdToReset] = React.useState("");
  const [firstName,setFristName]=React.useState("");
  const [lastUserName,setLastName]=React.useState("");
  const [mobileNumberData,setMobileNumberData]=React.useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] =React.useState("");
  const [useraccessData, setUseraccessData] = React.useState<UserData[]>([]);
  const [editedFields, setEditedFields] =React. useState<Record<string, any>>({});
  const startingIndex = page === 1 ? 1 : (page - 1) * rowPageSize + 1;
  const _axios = HttpService.getAxiosClient();
  const [isFilterBarVisible, setFilterBarVisibility] = React.useState(false);
  const [selectUserName,setSelectedUserName]=React.useState("");
  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<any>(null);
  const [operationCompleted, setOperationCompleted] = useState(false);
  const [userdataAfterEdit,setUserdataAfterEdit]=useState(false);
  const currentUser = useSelector((state: RootState) => {
    return state.user;
  });

  const previousPage = () => {
    let previousPageNo = page - 1;
    setPage(previousPageNo);
    const newOffset = Math.max(0, offsetVal - rowPageSize);
    setOffsetVal(newOffset);
  };

  const nextPage = () => {
    let nextPageNo = page + 1;
    setPage(nextPageNo);
    setOffsetVal(offsetVal + rowPageSize);
  };

  const rowPerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = Number(e.target.value);
    setRowPageSize(val);
    setPage(1);
    setOffsetVal(0);
  };

  const handleClickOpen = (userId: string,FristName:string,LastName:string,MobileNumber:string) => {
    setUserIdToReset(userId);
    setFristName(FristName);
    setLastName(LastName);
    setMobileNumberData(MobileNumber);
    setOpenDialog(true);
    setPassword("");
    setConfirmPassword("");
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const fetchData = async () => {
    const intentStr = "getAllMedunitedUsersData";
    const payloadObj = {
      intent: intentStr,
      offset: offsetVal,
      limit: rowPageSize,
    };

    try {
      setLoading(true);
      const response = await _axios.post<MedunitedUsersResponse>(
        `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
        payloadObj,
        {
          headers: {
            "Content-Type": "application/json",
            version: "WORKING",
          },
        }
      );

      setUserDataArray(response.data);
      setFilteredDataNew(response.data.medunited_users);
      const totalCount =response.data?.medunited_users_aggregate?.aggregate?.count ?? 0;
      setTotalDataCount(totalCount);
      setTotalPages(Math.ceil(totalCount / rowPageSize));
    } catch (error: any) {
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectUserName,selectedUserType,activeTab,userdataAfterEdit]);
// filterUserType
type Payload = {
  intent: string;
  state: {
    userName?: string;
    userType?: string;
    [key: string]: string | number | Date | undefined; 
  };
};
function generatePayload(intent: string, params: { [key: string]: string | number | Date }): Payload {
  return {
    intent,
    state: params,
  };
}
  const toggleFilterBar = () => {
    setFilterBarVisibility(!isFilterBarVisible);
  };
  function handleFilterCancel(event: any): void {
    console.log("filterclear")
    setFilterBarVisibility(!isFilterBarVisible);
    setSelectedUserType("");
    setSelectedUserName("");
    setFilteredData(null);
    setOperationCompleted(false);
    console.log(selectUserName,"selelectUSerNAme",selectedUserType,"selecetedUserType");
  }
  const clearAllfilters =(e:any)=>{
    setFilterBarVisibility(false);
    setOperationCompleted(false);
    setSelectedUserType("");
    setSelectedUserName("");
    setFilteredData(null);
    setRowPageSize(30);
    console.log(selectUserName,"selelectUSerNAme",selectedUserType,"selecetedUserType");

  }
    const handleFilterSubmit = async () => {
      try {
        let intent = "";
        let params: { [key: string]: string | number | Date } = {};
        if (selectUserName.trim() !== "" && selectedUserType.trim() === "") {
          intent = "getMeduniterUsersByUserNameFilter";
          params = {
            userName: selectUserName.trim(),
          };
        } else if (
          selectUserName.trim() !== "" &&
          selectedUserType.trim() !== ""
        ) {
          intent = "getMedunitedUsersByUserNameAndUserTypeFilter";
          params = {
            userName: selectUserName.trim(),
            userType: selectedUserType.trim(),
          };
        } else if (
          selectedUserType.trim() !== "" && selectUserName.trim() === ""
        ) {
          intent = "getMedunitedUsersByUserTypeFilter";
          params = { userType: selectedUserType.trim() };
        } else {
          setMessage("Please Enter at least one input to apply filter");
          setOpen(true);
          return false;
        }

        const filterSubmitPayload = generatePayload(intent, params);
        const filterValuesResp = await _axios.post(
          `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
          filterSubmitPayload,
          {
            headers: {
              version: VERSION,
            },
          }
        );
        setOperationCompleted(true);
        if (filterValuesResp.status === 200) {
          const responseData = filterValuesResp.data;
          setFilterApplied(true);
          setFilteredDataNew(responseData?.medunited_users);
          setUserDataArray(responseData);
          const totalCount =responseData?.medunited_users_aggregate?.aggregate?.count ?? 0;
          setTotalDataCount(totalCount);
          setTotalPages(Math.ceil(totalCount / rowPageSize));
          setRowPageSize(totalDataCount);
          setFilterBarVisibility(false);
        } else {
          setFilterApplied(false);
          setMessage(
            "No results for applied filter. Reason: " +
              filterValuesResp.statusText
          );
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

  //password
  const handleResetPassword = async () => {
    try {
      setLoading(true);
      if (!password) {
        setMessage(password);
        setOpen(true);
        return;
      }
      if (!confirmPassword) {
        setMessage(confirmPassword);
        setOpen(true);
        return;
      }
      if (password !== confirmPassword) {
        setMessage(passwordMismatch);
        setOpen(true);
        return;
      }

    const userId = userIdToReset;
    const intentStr = "resetPassword";
    const payloadObj = {
      intent: intentStr,
      state: {
        userId: userId,
        temporary: false,
        type: "password",
        value: password,
        firstName : firstName,
        lastName :lastUserName,
        mobileNumber :mobileNumberData
      }
    };
    try {
      const response = await _axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`, payloadObj, {
        headers: {
          version: VERSION,
        }
      });
      if (response.status === 204 || response.status === 200) {
        setMessage(success);   
        const totalCount =response.data?.medunited_users_aggregate?.aggregate?.count ?? 0;
        setTotalDataCount(totalCount);
        setTotalPages(Math.ceil(totalCount / rowPageSize));
        setOpenDialog(false);
        setOpen(true);
      }
    } catch (error) {
      if (error instanceof Error) {
        setMessage(error.message);
        setOpen(true);
      }
    }
  } catch (error) {
    console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditIconClick = (userData: MedunitedUser) => {
    setEditUserData(userData);
    setOpenEditDialog(true);
  };
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    resetEditedFields();
  };
  const handleCancelEdit = () => {
    resetEditedFields();
    handleCloseEditDialog();
  };
  
  const handleEditFieldChange = (fieldName: string, value: any) => {
    setEditedFields((prev) => ({ ...prev, [fieldName]: value }));
  };

  const resetEditedFields = () => {
    setEditedFields({});
  };
  // edit
  const handleSubmitEditDetails = async () => {
    try {
      if (editUserData) {
        const updatedFields: Record<string, any> = { ...editedFields };

        Object.keys(editUserData).forEach((key) => {
          if (updatedFields[key as keyof MedunitedUser] === undefined) {
            updatedFields[key as keyof MedunitedUser] =
              editUserData[key as keyof MedunitedUser];
          }
        });

        const payload: UpdateUserPayload = {
          intent: "updateUserDetailsByUserId",
          state: {
            userId: editUserData?.user_id ?? "",
            firstName: updatedFields?.first_name ?? "",
            lastName: updatedFields?.last_name ?? "",
            operatingUnitType:
              updatedFields?.operating_unit_type ??
              editUserData.operating_unit_type,
            operatingUnitLocation:
              updatedFields.operating_unit_location ??
              editUserData.operating_unit_location,
            accountHolderName:
            editUserData?.user_type !== "tradePartner" ? "null" :
              updatedFields.account_holder_name ??
              editUserData.trade_partner_bank_account_details[0]
                .account_holder_name,
            bankAccountNumber:
            editUserData?.user_type !== "tradePartner" ? 0 :
              updatedFields.bank_account_number ??
              editUserData.trade_partner_bank_account_details[0]
                .bank_account_number,
            bankIfscCode:
            editUserData?.user_type !== "tradePartner" ? "null " :
              updatedFields.bank_ifsc_code ??
              editUserData.trade_partner_bank_account_details[0].bank_ifsc_code,
            bankName:
            editUserData?.user_type !== "tradePartner" ? "null " :
              updatedFields.bank_name ??
              editUserData.trade_partner_bank_account_details[0].bank_name,
            district:
            editUserData?.user_type !== "tradePartner" ? "null ":
              updatedFields.district ??
              editUserData.user_address_details[0].district,
            village:
            editUserData?.user_type !== "tradePartner" ? "null " :
              updatedFields.village ??
              editUserData.user_address_details[0].village,
            pincode:
            editUserData?.user_type !== "tradePartner" ? 0 :
              updatedFields.pincode ??
              editUserData.user_address_details[0].pincode,
            state:
            editUserData?.user_type !== "tradePartner" ? "null " :
              updatedFields.state ?? editUserData.user_address_details[0].state,
            mandal:
            editUserData?.user_type !== "tradePartner" ? "null " :
              updatedFields.mandal ??
              editUserData.user_address_details[0].mandal,
            hospitalName:
            editUserData?.user_type !== "tradePartner" ? "null" :
              updatedFields.hospital_name ??
              editUserData.user_address_details[0].hospital_name,
          },
        };

        const response = await _axios.post<MedunitedUsersResponse>(
          `${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              version: "WORKING",
            },
          }
        );

        if (response.status === 200) {
          resetEditedFields();
          setUserdataAfterEdit(true);
          handleCloseEditDialog();
          setMessage(submitted);
          setFilterBarVisibility(false);
          setOperationCompleted(false);   
          setSelectedUserType("");
          setSelectedUserName("");
          setFilteredData(null);
          setRowPageSize(30);      
          setOpen(true);
        } else {
          setMessage(`Unexpected response status:", ${response.status}`);
          setOpen(true);
        }
      } else {
        setMessage(dataNotDefined);
        setOpen(true);
      }
    } catch (error) {
      setMessage(errorMessage);
      setOpen(true);
    }
  };

  const handleTabClick = async (index: number) => {
    if (tabnames[index].toLowerCase() != activeTabName) {
      setActiveTab(index);
      setPage(1);
      setOffsetVal(0);
      setRowPageSize(30);
      setSelectedUserType(" ");
      setOperationCompleted(false);
    }
  };

  const handleUserTypeChange = (event: { target: { value: string } }) => {
    setSelectedUserType(event.target.value);
  };

  // Dowload
  let excelArray: any[] = [];

  if (userdataArray?.medunited_users && useraccessData) {
    excelArray = userdataArray.medunited_users.map((user, index) => {
      const addressDetails = user.user_address_details[0] || {};
      const bankDetails = user.trade_partner_bank_account_details[0] || {};
      return {
        Username: user.user_name,
        FullName: `${user.first_name} ${user.last_name}`,
        "Mobile Number": user.mobile_number,
        "User Type": user.user_type,
        "Mandal/District": `${addressDetails?.mandal}/${addressDetails?.district}`,
        Village: addressDetails.village,
        State: addressDetails.state,
        Pincode: addressDetails.pincode,
        "Bank Name": bankDetails.bank_name,
        "Bank AccountNumber": bankDetails.bank_account_number,
        "Bank IFSC Code": bankDetails.bank_ifsc_code,
        AccountHolderName: bankDetails.account_holder_name,
      };
    });
  } else {
    console.error(userDataNull );
  }

  function handleDownload() {
    const csvContent = [tableHeadForDownloads.join(",")]
      .concat(excelArray.map((item) => Object.values(item).join(",")))
      .join("\n");

    const blob = new Blob([csvContent], {
      type: "data:text/csv;charset=utf-8,",
    });

    const blobURL = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.download = "UsersData.csv";
    anchor.href = blobURL;
    anchor.click();
    setTimeout(() => {
      URL.revokeObjectURL(blobURL);
    }, 100);
  }

  const referredPatientsTableContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const resizeHandler = () => {
      const container = referredPatientsTableContainerRef.current;
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top;
        container.style.maxHeight = `${availableHeight - 90}px`;
      }
    };

    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [activeTab]);

  const [clickedAccountNumber, setClickedAccountNumber] = useState<string | null>(null);
  const maskAccountNumber = (accountNumber: number | undefined) => {
    if (typeof accountNumber === 'undefined') return '';
    const accountNumberString = accountNumber.toString();
    const visibleDigits = accountNumberString.slice(0, 3);
    const maskedDigits = '*'.repeat(Math.max(0, accountNumberString.length - 3));
    return visibleDigits + maskedDigits;
};
  const handleToggleAccountNumber = (accountNumber: string) => {
    setClickedAccountNumber(clickedAccountNumber === accountNumber ? null : accountNumber);
  };
  const[clickedIFSCCode,setClickedIFSCCode]=useState<string | null>(null);
  const maskIFSC = (IFSCCode: string | undefined) => {
    if (typeof IFSCCode === 'undefined') return '';
    const IFSCCodeString = IFSCCode.toString();
    const visibleDigits = IFSCCodeString.slice(0, 3);
    const maskedDigits = '*'.repeat(Math.max(0, IFSCCodeString.length - 3));
    return visibleDigits + maskedDigits;
};
  const handleToggleIFSCCode = (IFSCCode: string) => {
    setClickedIFSCCode(clickedIFSCCode === IFSCCode ? null : IFSCCode);
  };
  return (
    <>
      <Sidemenu />
      <div className="layoutParent">
        <div className="layoutParentPs">
          <p>{userScreen_Head}</p>
          <Snackbar
            ContentProps={{
              "aria-describedby": "message-id",
              className: classes.snackbarStyleViaContentProps,
            }}
            open={open}
            autoHideDuration={3000}
            message={message}
            onClose={() => setOpen(false)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          />
          <div className="layoutContent pagelayoutPadding">
            <div className="conflicts_tabs">
              <ul>
                {tabnames.map((data, i) => (
                  <li
                    key={i}
                    className={`${activeTab === i ? "activeTab" : ""}`}
                    onClick={() => {
                      handleTabClick(i);
                      setActiveTabName(tabnames[i].toLowerCase());
                    }}
                  >
                    {data}
                  </li>
                ))}
              </ul>
              <div className="referred_options_block_userScreen" >
                {activeTab === 0 && (
                  <>
                    <div className="filter_icon_block_UserScreen">
                      <img
                        className="filterIcon"
                        src={FilterIcon}
                        alt="Filter Icon"
                        onClick={toggleFilterBar}
                      />
                      <label className="filter_label" onClick={toggleFilterBar}>Filter</label>
                      <div className="filter_list_userScreen">
                        {isFilterBarVisible && (
                          <div className="filter_options_container_userScreen">
                            <div className="visit_type_block_userScreen">
                              <FormControl className="formcontrol_styles"
                                size="small"
                              >
                                <Select
                                   labelId="userType"
                                  value={selectedUserType}
                                  onChange={handleUserTypeChange}
                                  className="select_change"
                                >
                                  <MenuItem value=" ">
                                    <em>Select UserType</em>
                                  </MenuItem>
                                  <MenuItem value="salesHead">
                                    SALES HEAD
                                  </MenuItem>
                                  <MenuItem value="assistantGeneralManager">
                                    ASSISTANT GENERAL MANAGER
                                  </MenuItem>
                                  <MenuItem value="manager">MANAGER</MenuItem>
                                  <MenuItem value="clusterManager">
                                    CLUSTER MANAGER
                                  </MenuItem>
                                  <MenuItem value="doctorReferralExecutive">
                                    DOCTOR REFERRAL EXECUTIVE
                                  </MenuItem>
                                  <MenuItem value="finance">FINANCE</MenuItem>
                                  <MenuItem value="tradePartner">
                                    TRADE PARTNER
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                            <div className="filter_patient_block">
                            <TextField
                              autoFocus
                              margin="dense"
                              label="Enter User Name"
                              type="text"
                              variant="standard"
                              value={selectUserName}
                              onChange={(event) => setSelectedUserName(event.target.value)}
                            />
                            </div>
                            <div className="filter_submit_cancel_buttonss">
                              <Button
                                className="download_button"
                                style={{ marginRight: "10px" }}
                                onClick={handleFilterSubmit}
                              >
                                {submit}
                              </Button>
                              <Button
                                className="download_button"
                                onClick={handleFilterCancel}
                              >
                                {cancel}
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className="downloadButtondiv"
                      style={{ marginLeft: "2rem" }}
                    >
                      <Button
                        className="download_button"
                        onClick={handleDownload}
                      >
                        {exportData}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
            {(selectUserName || selectedUserType) && operationCompleted ? (
                    <div className="applied_filter_item" style={{ backgroundColor: "white" }}>
                      <div>
                        <button className="applied_filter_item" onClick={clearAllfilters}>
                          <img className="close_svg_icon" src={closeIcon} style={{ cursor: "pointer" }} alt="Close icon" />
                          {addRole[0]}: {selectedUserType ? selectedUserType : 'N/A'},
                          {addRole[2]}: {selectUserName ? selectUserName : 'N/A'}
                        </button>
                      </div>
                    </div>
                  ) : null}
            {activeTab === 0 && (
              <>
              {currentUser.userRole !== "salesHead" ? (
              <td className="text-center" colSpan={7}>
                {noData}
              </td>
                ) :(
                <div
                  className="tableWithHadle"
                  ref={referredPatientsTableContainerRef}
                >
                  <table className={classes.table} aria-label="sticky table">
                    <thead className="tableList_stickyHeader">
                      <tr className={classes.table_Tr}>
                        {tableHead.map((header, index) => (
                          <td
                            key={index}
                            className={`${classes.cellheader} ${classes.cellWithBorder}`}
                          >
                            <b  className={classes.headerCell}>
                              {header}
                            </b>
                          </td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {loading && <p>Loading...</p>}
                      { filteredDataNew?.slice(offsetVal, offsetVal + rowPageSize)
                      .map((row: MedunitedUser, index) => (
                          <tr
                            key={startingIndex + index}
                            style={{ color: "black" }}
                          >
                            <td className={classes.cell}>
                              {startingIndex + index}
                            </td>
                            <td className={classes.cell}>
                              {row.user_name.toUpperCase()}
                            </td>
                            <td className={classes.cell}>
                              {row.first_name.toUpperCase()}{" "}
                              {row.last_name.toUpperCase()}
                            </td>
                            <td
                              className={`${classes.cell} ${classes.mobileNumberCell}`}
                            >
                              {row.mobile_number}
                            </td>
                            <td className={classes.cell}>
                              {row.user_type.toUpperCase()}
                            </td>
                            <td className={classes.cell}>
                              {row.user_address_details[0]
                                ? `${row.user_address_details[0]?.village.toUpperCase()}`
                                : "-"}
                            </td>
                            <td className={classes.cell}>
                              {row.user_address_details[0]
                                ? `${row.user_address_details[0]?.mandal.toUpperCase()}/${row.user_address_details[0]?.district.toUpperCase()}`
                                : "-"}
                            </td>
                            <td className={classes.cell}>
                              {row.user_address_details[0]?.state.toUpperCase()}
                            </td>
                            <td className={classes.cell}>
                              {row.user_address_details[0]?.pincode}
                            </td>
                            <td className={`${classes.cell} ${classes.cellwidth}`}>
                              {row.trade_partner_bank_account_details[0]?.bank_name.toUpperCase()}
                            </td>
                            <td className={classes.cell} onClick={() => handleToggleAccountNumber(row.trade_partner_bank_account_details[0]?.bank_account_number)}>
                              {clickedAccountNumber === row.trade_partner_bank_account_details[0]?.bank_account_number ?
                                row.trade_partner_bank_account_details[0]?.bank_account_number :
                                maskAccountNumber(row.trade_partner_bank_account_details[0]?.bank_account_number)}
                            </td>
                            <td className={classes.cell} onClick={() => handleToggleIFSCCode(row.trade_partner_bank_account_details[0]?.bank_ifsc_code.toUpperCase())}>
                              {clickedIFSCCode === row.trade_partner_bank_account_details[0]?.bank_ifsc_code.toUpperCase() ?
                                row.trade_partner_bank_account_details[0]?.bank_ifsc_code.toUpperCase() :
                                maskIFSC(row.trade_partner_bank_account_details[0]?.bank_ifsc_code.toUpperCase())}
                            </td>
                            <td className={classes.cell}>
                              {row.trade_partner_bank_account_details[0]?.account_holder_name.toUpperCase()}
                            </td>
                            <td className={classes.cell}>
                              <button
                                onClick={() => handleClickOpen(row.user_id,row.first_name,row.last_name,row.mobile_number)}
                                disabled={loading}
                                className={classes.editcell}
                              >
                                Reset
                              </button>
                            </td>
                            {/* <td className={classes.cell}>
                              <button
                                onClick={() => handleOpenResend(row.user_id)}
                                disabled={loading}
                                className={classes.editcell}
                              >
                                Resend
                              </button>
                            </td> */}
                            <td
                              className={`${classes.cell} ${classes.editcell}`}
                            >
                              <img
                                className=""
                                src={editIcon}
                                alt="Edit Icon"
                                onClick={() => handleEditIconClick(row)}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <Dialog open={OpenDialog} onClose={handleClose}>
                    <DialogTitle>Password Reset</DialogTitle>
                    <DialogContent>
                      <TextField
                        label="User ID"
                        value={userIdToReset}
                        disabled
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        label="Confirm Password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        fullWidth
                        margin="normal"
                      />
                      <div className="filter_submit_cancel_buttons">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleResetPassword}
                        >
                          Reset Password
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </div>
                    </DialogContent>
                  </Dialog>
                  <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
                    <DialogTitle>Edit User</DialogTitle>
                    {editUserData?.user_type === "tradePartner" ? (
                    <DialogContent>
                      {editUserData &&
                        [
                          "user_id",
                          "first_name",
                          "last_name",
                          "operating_unit_type",
                          "operating_unit_location",
                          "account_holder_name",
                          "bank_account_number",
                          "bank_name",
                          "bank_ifsc_code",
                          "district",
                          "village",
                          "pincode",
                          "state",
                          "mandal",
                          "hospital_name",
                        ].map((key) => (
                          <TextField
                            key={key}
                            label={key.replace(/_/g, " ")}
                            value={
                              editedFields[key as keyof MedunitedUser] !==
                              undefined
                                ? editedFields[key as keyof MedunitedUser]
                                : key === "bank_account_number"
                                ? editUserData
                                    .trade_partner_bank_account_details[0]
                                    ?.bank_account_number ?? ""
                                : key === "bank_name"
                                ? editUserData
                                    .trade_partner_bank_account_details[0]
                                    ?.bank_name ?? ""
                                : key === "bank_ifsc_code"
                                ? editUserData
                                    .trade_partner_bank_account_details[0]
                                    ?.bank_ifsc_code ?? ""
                                : key === "account_holder_name"
                                ? editUserData
                                    .trade_partner_bank_account_details[0]
                                    ?.account_holder_name ?? ""
                                : key === "district"
                                ? editUserData.user_address_details[0]
                                    ?.district ?? ""
                                : key === "village"
                                ? editUserData.user_address_details[0]
                                    ?.village ?? ""
                                : key === "pincode"
                                ? editUserData.user_address_details[0]
                                    ?.pincode ?? ""
                                : key === "state"
                                ? editUserData.user_address_details[0]?.state ??
                                  ""
                                : key === "mandal"
                                ? editUserData.user_address_details[0]
                                    ?.mandal ?? ""
                                : key === "hospital_name"
                                ? editUserData.user_address_details[0]
                                    ?.hospital_name ?? ""
                                : editUserData[key as keyof MedunitedUser] ?? ""
                            }
                            fullWidth
                            margin="normal"
                            type={
                              key === "bank_account_number" ? "number" : "text"
                            }
                            onChange={(e) =>
                              handleEditFieldChange(key, e.target.value)
                            }
                            disabled={key === "user_id"}
                          />
                        ))}

                      <div className="filter_submit_cancel_buttons">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmitEditDetails}
                          disabled={loadingSubmit}
                        >
                          Submit
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleCancelEdit}
                          disabled={loadingSubmit}
                        >
                          Cancel
                        </Button>
                      </div>
                    </DialogContent>
                    ):(
                    <DialogContent>
                      {editUserData &&
                        [
                          "user_id",
                          "first_name",
                          "last_name",
                          "operating_unit_type",
                          "operating_unit_location",
                          // "email",
                        ].map((key) => (
                          <TextField
                            key={key}
                            label={key.replace(/_/g, " ")}
                            value={
                              editedFields[key as keyof MedunitedUser] !==
                              undefined
                                ? editedFields[key as keyof MedunitedUser]
                                : key === "district"
                                ? editUserData.user_address_details[0]
                                    ?.district ?? ""
                                // :key === "email"
                                // ? editUserData.user_address_details[0]?.email ?? ""
                                : key === "village"
                                ? editUserData.user_address_details[0]
                                    ?.village ?? ""
                                : key === "pincode"
                                ? editUserData.user_address_details[0]
                                    ?.pincode ?? ""
                                : key === "state"
                                ? editUserData.user_address_details[0]?.state ??
                                  ""
                                : key === "mandal"
                                ? editUserData.user_address_details[0]
                                    ?.mandal ?? ""
                                : key === "hospital_name"
                                ? editUserData.user_address_details[0]
                                    ?.hospital_name ?? ""
                                : editUserData[key as keyof MedunitedUser] ?? ""
                            }
                            fullWidth
                            margin="normal"
                            type={
                              key === "bank_account_number" ? "number" : "text"
                            }
                            onChange={(e) =>
                              handleEditFieldChange(key, e.target.value)
                            }
                            disabled={key === "user_id"}
                          />
                        ))}

                      <div className="filter_submit_cancel_buttons">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmitEditDetails}
                          disabled={loadingSubmit}
                        >
                          Submit
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleCancelEdit}
                          disabled={loadingSubmit}
                        >
                          Cancel
                        </Button>
                      </div>
                    </DialogContent>
                    )}
                  </Dialog>
                </div>
      )}
                <Pagination
                  totalPages={totalPages}
                  pageNo={page}
                  rowPageSize={rowPageSize}
                  totalDataCount={totalDataCount}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  rowPerPageChange={rowPerPageChange}
                />
              </>
            )}
            {activeTab === 1 && (
              <div>
                <AddUser />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserScreen;